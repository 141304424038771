<template>
  <div class="tariffList" >

    <div class="" style="position: fixed; top:62px; right:14px; z-index: 2000" v-if="orderIdForExport.length">
      <button class="btn btn-secondary ml-1" @click="getInvoices">Накладная</button>
      <button class="btn btn-secondary ml-1" @click="getManifests">Манифест</button>
      <button class="btn btn-secondary ml-1" @click="itemStatus.order_id = orderIdForExport; visibleEditStatus=true">Массовое обновление заказов</button>
    </div>

    <mini-loader v-if="load" />
    <div class="card">
      <div class="pl-1 pr-1 pb-4 d-flex justify-content-between align-items-center">
        <!-- <h5 class="text-dark">Международные заказы</h5> -->
        <div class="d-flex align-items-center">
          <div class="mr-1">
            <a-select
                placeholder="Тип"
                style="width: 100%"
                optionLabelProp="label"
                optionFilterProp="label"
                @change="applyFilter"
                v-model="dataFilter.statusParents"
              >
              <a-select-option v-for="status in statusParents" :key="status.value" :value="status.value" :label="status.title">
                <span v-if="status.value == 1" style="color: blue">{{status.title}}</span>
                <span v-else-if="status.value == 2" style="color: red">{{status.title}}</span>
                <span v-else-if="status.value == 3" style="color: green">{{status.title}}</span>
                <span v-else>{{status.title}}</span>
              </a-select-option>
            </a-select>
          </div>
          <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'all'}" @click="dataFilter.status_id = []; dataFilter.active = 'all'; applyFilter()">
            Все <span>{{count.all}}</span>
          </div>

          <div class="d-flex" v-if="dataFilter.statusParents == null">
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_sender'}" @click="dataFilter.status_id = [4,10,15]; dataFilter.active = 'at_the_sender'; applyFilter()">
              У отправителя <span>{{count.at_the_sender}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'need_to_get'}" @click="dataFilter.status_id = [5,16]; dataFilter.active = 'need_to_get'; applyFilter()">
              Надо получить <span>{{count.need_to_get}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_driver'}" @click="dataFilter.status_id = [6,11,17,20]; dataFilter.active = 'at_the_driver'; applyFilter()">
              У водителя <span>{{count.at_the_driver}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_office'}" @click="dataFilter.status_id = [7,12,18,21]; dataFilter.active = 'at_the_office'; applyFilter()">
              В офисе <span>{{count.at_the_office}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'need_to_delivery'}" @click="dataFilter.status_id = [8,13,19,22]; dataFilter.active = 'need_to_delivery'; applyFilter()">
              Надо доставить <span>{{count.need_to_delivery}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'delivered'}" @click="dataFilter.status_id = [9,14,23]; dataFilter.active = 'delivered'; applyFilter()">
              Доставлено <span>{{count.delivered}}</span>
            </div>
          </div>

          <div class="d-flex" v-else-if="dataFilter.statusParents == 1">
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_sender'}" @click="dataFilter.status_id = [4]; dataFilter.active = 'at_the_sender'; applyFilter()">
              У отправителя <span>{{count.at_the_sender}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'need_to_get'}" @click="dataFilter.status_id = [5]; dataFilter.active = 'need_to_get'; applyFilter()">
              Надо получить <span>{{count.need_to_get}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_driver'}" @click="dataFilter.status_id = [6]; dataFilter.active = 'at_the_driver'; applyFilter()">
              У водителя <span>{{count.at_the_driver}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_office'}" @click="dataFilter.status_id = [7]; dataFilter.active = 'at_the_office'; applyFilter()">
              В офисе <span>{{count.at_the_office}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'need_to_delivery'}" @click="dataFilter.status_id = [8]; dataFilter.active = 'need_to_delivery'; applyFilter()">
              Надо доставить <span>{{count.need_to_delivery}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'delivered'}" @click="dataFilter.status_id = [9]; dataFilter.active = 'delivered'; applyFilter()">
              Доставлено <span>{{count.delivered}}</span>
            </div>
          </div>

          <div class="d-flex" v-else-if="dataFilter.statusParents == 2">
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_sender'}" @click="dataFilter.status_id = [10]; dataFilter.active = 'at_the_sender'; applyFilter()">
              У отправителя <span>{{count.at_the_sender}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_driver'}" @click="dataFilter.status_id = [11]; dataFilter.active = 'at_the_driver'; applyFilter()">
              У водителя <span>{{count.at_the_driver}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_office'}" @click="dataFilter.status_id = [12]; dataFilter.active = 'at_the_office'; applyFilter()">
              В офисе <span>{{count.at_the_office}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'need_to_delivery'}" @click="dataFilter.status_id = [13]; dataFilter.active = 'need_to_delivery'; applyFilter()">
              Надо возвращать <span>{{count.need_to_delivery}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'delivered'}" @click="dataFilter.status_id = [14]; dataFilter.active = 'delivered'; applyFilter()">
              Возвращено <span>{{count.delivered}}</span>
            </div>
          </div>

          <div class="d-flex" v-else-if="dataFilter.statusParents == 3">
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_sender'}" @click="dataFilter.status_id = [15]; dataFilter.active = 'at_the_sender'; applyFilter()">
              У отправителя <span>{{count.at_the_sender}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'need_to_get_1'}" @click="dataFilter.status_id = [16]; dataFilter.active = 'need_to_get_1'; applyFilter()">
              Шаг.1 Надо получить <span>{{count.need_to_get_1}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_driver_1'}" @click="dataFilter.status_id = [17]; dataFilter.active = 'at_the_driver_1'; applyFilter()">
              Шаг.1 У водителя <span>{{count.at_the_driver_1}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_office_1'}" @click="dataFilter.status_id = [18]; dataFilter.active = 'at_the_office_1'; applyFilter()">
              Шаг.1 В офисе <span>{{count.at_the_office_1}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'need_to_delivery_1'}" @click="dataFilter.status_id = [19]; dataFilter.active = 'need_to_delivery_1'; applyFilter()">
              Шаг.1 Надо доставить <span>{{count.need_to_delivery_1}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_driver_2'}" @click="dataFilter.status_id = [20]; dataFilter.active = 'at_the_driver_2'; applyFilter()">
              Шаг.2 У водителя <span>{{count.at_the_driver_2}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'at_the_office_2'}" @click="dataFilter.status_id = [21]; dataFilter.active = 'at_the_office_2'; applyFilter()">
              Шаг.2 В офисе <span>{{count.at_the_office_2}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'need_to_delivery_2'}" @click="dataFilter.status_id = [22]; dataFilter.active = 'need_to_delivery_2'; applyFilter()">
              Шаг.2 Надо доставить <span>{{count.need_to_delivery_2}}</span>
            </div>
            <div class="mr-1 status-for-filter" :class="{'active': dataFilter.active == 'delivered'}" @click="dataFilter.status_id = [23]; dataFilter.active = 'delivered'; applyFilter()">
              Доставлено <span>{{count.delivered}}</span>
            </div>
          </div>

          <div class="mr-1">
            <a-select
              v-if="me.roles[0].name == 'admin' || me.roles[0].name == 'moderator'"
              mode="multiple"
              placeholder="Заказ какого офиса"
              style="width: 200px"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.branchoffice_id"
              @change="applyFilter"
              >
              <a-select-option v-for="branch in branches" :key="branch.id" :label="branch.title">
                {{ branch.title }}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.order_id"> {{value}} </div>
          </div>

        </div>
        <div class="d-flex align-items-center">
          <div class="mr-2 order-menu">
            <template>
              <a-dropdown :trigger="['click']">
                 <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                   <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                     <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                   </svg>
                 </a>
                 <a-menu slot="overlay" class="order-menu">
                   <a-menu-item key="0" @click="visibleExportToExcell = true">
                     <a href="#">
                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
                         <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                       </svg>
                       Динамическая выгрузка  на csv
                     </a>
                   </a-menu-item>
                   <a-menu-item key="1" @click="visibleFilterOrders = true">
                     <a href="#">
                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filter" viewBox="0 0 16 16">
                         <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                       </svg>
                       Фильтрация заказов
                     </a>
                   </a-menu-item>
                   <!-- <a-menu-divider /> -->
                   <a-menu-item key="3">
                     <a href="#" @click="itemStatus.order_id = orderIdForExport; visibleEditStatus=true">
                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                          <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                       </svg>
                       Массовое обновление статуса
                     </a>
                   </a-menu-item>
                   <!-- <a-menu-item key="3">
                     <a href="#" @click="itemStatus.order_id = orderIdForExport; visibleEditStatus=true">
                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                         <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"/>
                         <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"/>
                       </svg>
                       Манифест
                     </a>
                   </a-menu-item> -->
                 </a-menu>
               </a-dropdown>
            </template>

          </div>
          <a @click="createItem">
            <span class="p-1">
              <button type="button" class="btn btn-success">Добавить
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
                  <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
                </svg>
              </button>
            </span>
          </a>
        </div>
      </div>
      <div class="pl-1 pr-1 pb-1 pt-1 d-flex justify-content-between align-items-center" style="border-top: 1px solid #eee">
        <div style="width: 150px">
          <template>
            <a-select  style="width: 100%" :token-separators="[',']" v-model="dataFilter.searchBy">
              <a-select-option :key="'id'">Поиск по ID</a-select-option>
              <a-select-option :key="'search'">Поиск по всему</a-select-option>
            </a-select>
          </template>
        </div>
        <div class="pl-1" style="width: 100%" v-if="dataFilter.searchBy == 'id'" >
          <template>
            <a-select mode="tags" style="width: 100%" :token-separators="[',']" v-model="dataFilter.id" @change="applyFilter">

            </a-select>
          </template>
        </div>
        <div class="pl-1" style="width: 100%" v-else>
          <template>
              <a-input placeholder="Запрос" @pressEnter="applyFilter"  v-model="dataFilter.search" :class="{'is-invalid': validateErrors.link_id}"
              />
          </template>
        </div>
        <div class="pl-1" style="width: 150px">
          <template>
            <a-select :default-value="''"  style="width: 100%" :token-separators="[',']" v-model="dataFilter.whose" @change="applyFilter">
              <a-select-option :key="''">Все заказы</a-select-option>
              <a-select-option :key="'from_me'">Мои заказы</a-select-option>
              <a-select-option :key="'to_me'">Заказы мне</a-select-option>
            </a-select>
          </template>
        </div>
      </div>
      <div class="table-with-scroll">
        <table class="table table-striped" id="ordersList" >
          <thead>
            <tr>
              <th>
                <input type="checkbox" @click="selectAll" v-model="selectedAll">
              </th>
              <th>ID</th>
              <th>Клиент</th>
              <th>Создатель</th>
              <th>От офиса(филиал)</th>
              <th>К офису(филиал)</th>
              <th>Адрес отправки</th>
              <th>Адрес доставки</th>
              <th>Статус</th>
              <th>Услуга</th>
              <th>Время создания</th>
              <th>Статус нал.платежа</th>
              <th>
                <div class="prices">Цена услуги,</div>
                <div class="prices">Цена посылки,</div>
                <div class="prices">Наложенный платеж</div>
              </th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) in data">
              <td>
                <input type="checkbox" v-model="orderIdForExport" :value="value.id" @change="selectSingle()">
              </td>
              <td>
                   <div class="order-id" @click="showItem(value, index)">{{value.id}}</div>
                   <div v-if="value.status_id != 9 && value.status_id != 14 && value.status_id != 23">
                     <button class="btn btn-info" style="font-size: 11px !important" v-if="expiredAt(value.created_at).type == 'hour' || expiredAt(value.created_at).type == 'minute' || expiredAt(value.created_at).value < 4"> <span v-if="expiredAt(value.created_at).type == 'minute'">{{expiredAt(value.created_at).value}} - минуты назад</span> <span v-else-if="expiredAt(value.created_at).type == 'hour'">{{expiredAt(value.created_at).value}} - час назад</span> <span v-else>{{expiredAt(value.created_at).value}} - день назад </span></button>
                     <button class="btn btn-warning" style="font-size: 11px !important" v-else-if="expiredAt(value.created_at).value >3 && expiredAt(value.created_at).value < 7"> {{expiredAt(value.created_at).value}} - дня назад</button>
                     <button class="btn btn-danger" style="font-size: 11px !important" v-else>{{expiredAt(value.created_at).value}} - дня назад</button>
                   </div>
              </td>
              <td v-if="value.client" style="position: relative"> <div class="about-client"><div v-if="value.client.image" class="client-image" @click="showImage(value.client.image)"><img :src="$store.state.urlBackend + '/' + value.client.image"></div> <div v-else class="client-name-letter" :style="'background: ' + clientColor(value.client.name)">{{clientName(value.client.name)}}</div><div>{{value.client.name}}</div></div> <span v-if="value.change_history.length" style="position: absolute; bottom: 0; right: 10px; color: #FF7720">Изменено</span>   </td> <td v-else></td>
              <td >
                <div class="about-client" v-if="value.who_create == 'employee' && value.creator">
                  <div v-if="value.creator.image" class="client-image" @click="showImage(value.creator.image)">
                    <img :src="$store.state.urlBackend + '/' + value.creator.image">
                  </div>
                  <div v-else class="client-name-letter" :style="'background: ' + clientColor(value.client.name)">{{clientName(value.creator.name)}}</div>
                  <div >{{value.creator.name}}</div>
                </div>
                <div class="about-client" v-else>
                  <div style="color: #00A06B; width: 150px; font-weight: 600">Клиент создал</div>
                </div>
                <div class="ml-auto" v-if="value.driver" style="font-size: 12px; color: #3313C2; text-align: end;">Водитель: {{value.driver.name}}</div>
              </td>
              <td v-if="value.branch_from"> <div class="about-client"><div class="client-name-letter" :style="'background: ' + clientColor(value.branch_from.title)">{{clientName(value.branch_from.title)}}</div><div>{{value.branch_from.title}}</div></div>  </td> <td v-else></td>
              <td v-if="value.branch_to"> <div class="about-client"><div class="client-name-letter" :style="'background: ' + clientColor(value.branch_to.title)">{{clientName(value.branch_to.title)}}</div><div>{{value.branch_to.title}}</div></div>  </td> <td v-else></td>
              <td>
                <div class="about-client">
                  <div class="client-name-letter" v-if="value.sender_city" :style="'background: ' + clientColor(value.client.name)">{{clientName(value.sender_city.title)}}</div>
                  <div class="client-name-letter" v-else :style="'background: ' + clientColor(value.client.name)">{{clientName("Ташкент")}}</div>

                  <div class="delivery-address" style="max-width: 200px; overflow: hidden">
                    <div class="delivery-address-1">{{value.sender_name}}/{{value.sender_phone}}</div>
                    <div class="delivery-address-2"  v-if="value.sender_region">{{value.sender_region.title}}</div>
                    <div class="delivery-address-3" v-if="value.sender_city">{{value.sender_city.title}}, {{value.sender_address}}</div>
                    <div class="delivery-address-3" v-else>{{value.sender_address}}</div>
                  </div>

                </div>
              </td>
              <td>
                <div class="about-client">

                  <div class="client-name-letter" v-if="value.recipient_city" :style="'background: ' + clientColor(value.client.name)">{{clientName(value.recipient_city.title)}}</div>
                  <div class="client-name-letter" v-else :style="'background: ' + clientColor(value.client.name)">{{clientName("Ташкент")}}</div>

                  <div class="delivery-address" style="max-width: 200px; overflow: hidden">
                    <div class="delivery-address-1">{{value.recipient_name}} / {{value.recipient_phone}}</div>
                    <div class="delivery-address-2"  v-if="value.recipient_region">{{value.recipient_region.title}}</div>
                    <div class="delivery-address-3" v-if="value.recipient_city">{{value.recipient_city.title}}, {{value.recipient_address}}</div>
                    <div class="delivery-address-3" v-else>{{value.recipient_address}}</div>
                  </div>

                </div>
              </td>
              <td> <div @click="changeStatus(value)" v-if="value.status" class="status-old" style="font-weight: 600; cursor: pointer; white-space: nowrap" :style="{color: value.status.color}">{{value.status.title}} ({{value.status_parent.title}})</div> </td>
              <td>
                <div class="about-client">
                  <div class="client-name-letter for-tariff">
                    <div class="tariff">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightning" viewBox="0 0 16 16">
                        <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5zM6.374 1 4.168 8.5H7.5a.5.5 0 0 1 .478.647L6.78 13.04 11.478 7H8a.5.5 0 0 1-.474-.658L9.306 1H6.374z"/>
                      </svg>
                    </div>
                  </div>
                  <div v-if="value.tariff">{{value.tariff.title}}</div>
                </div>
              </td>
              <td style="text-align: center">{{createdAt(value.created_at)}}</td>
              <td> <div v-if="value.cod_status=='paid'" style="color: green">Оплачено</div> <div v-else-if="value.cod_status=='not_paid'" style="color: red"> Не оплачено</div> <div v-else> - </div></td>
              <td>
                <div class="about-client">
                  <div class="client-name-letter for-tariff">
                    <div class="payment_method" v-if="value.payment_method == 'cash'">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
                        <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                        <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
                      </svg>
                    </div>
                    <div class="payment_method" v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card-2-back-fill" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z"/>
                      </svg>
                    </div>
                  </div>
                  <div class="prices">{{value.cost_of_service ? value.cost_of_service : 0.00}} uzs,</div>
                  <div class="prices">{{value.cost_of_things ? value.cost_of_things : 0.00}} uzs,</div>
                  <div class="prices">{{value.cod ? value.cod : 0.00}} uzs</div>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
    </div>

    <!-- Show Modal -->
    <a-modal v-model="visibleShowModal" class="order-info" :footer="false">

      <div class="order-content" v-if="item.id">
    		<div class="order-content-header">
    			<div class="order-content-header-start">
    				<img src="logo_white.png" alt="" >
    				<div class="order-content-header-item">
    					<div class="round">
    						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" class="bi bi-info" viewBox="0 0 16 16">
    						  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
    						</svg>
    					</div>
    					<div class="header-info">
    						ЗАКАЗ № - {{item.id}}
                <br>
                <span v-if="item.tracking_code">
                  Трекинг код: {{ item.tracking_code }}
                </span>
                <span v-if="item.cse_phone">
                  Номер CSE: {{ item.cse_phone }}
                </span>
    					</div>
    				</div>
    				<div class="order-content-header-item">
    					<div class="round">
    						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" class="bi bi-plus-lg" viewBox="0 0 16 16">
    						  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
    						</svg>
    					</div>
    					<div class="header-info">
    						СОЗДАНО: <br>	{{createdAt(item.created_at)}}
    					</div>
    				</div>
    				<div class="order-content-header-item">
    					<div class="round">
    						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" class="bi bi-check2" viewBox="0 0 16 16">
    						  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
    						</svg>
    					</div>
    					<div class="header-info">
    						СТАТУС: <br>	{{item.status ? item.status.title : 'Не определено'}}
    					</div>
    				</div>
    			</div>
    			<div class="order-content-header-end">
    				<div class="order-content-header-item">
    					<div class="header-info" @click="editItem" style="cursor: pointer">
    						<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-pencil-fill" viewBox="0 0 16 16">
    						  <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
    						</svg>
    						<span style="font-size: 14px; margin-left: 3px">ИЗМЕНИТЬ</span>
    					</div>
    				</div>
    			</div>
    		</div>

    		<div class="order-content-body" style="background-image: url(background.png); background-repeat: no-repeat; background-size: 100%;">
    			<div class="order-content-body-top">
    				<div class="order-content-body-top-content">
    					<div class="order-content-body-top-content-item">
    						<div class="order-top-round" :style="'background: ' + clientColor(item.client.name)">
                  {{clientName(item.client.name)}}
    						</div>
    						<div class="order-content-body-top-content-info">
    							<span class="order-content-body-top-content-info-title">Клиент</span>
    							<span class="order-content-body-top-content-info-value">{{item.client ? item.client.name : 'Не определено'}}</span>
    						</div>
    					</div>
    					<div class="order-content-body-top-content-item">
    						<div class="order-content-body-top-content-info">
    							<span class="order-content-body-top-content-info-title right" >Офис</span>
    							<span class="order-content-body-top-content-info-value right">{{item.branch_from.title}}</span>
    						</div>
    						<div class="order-top-round" :style="'background: ' + clientColor(item.branch_from.title)">
    							{{clientName(item.branch_from.title)}}
    						</div>
    					</div>
    				</div>
    				<div class="order-content-body-top-navbar">
    					<div class="order-content-body-top-navbar-start">
    						<span :class="info == 'header' ? 'active' : ''" @click="()=>{info = 'header'}">Основная</span>
    						<span :class="info == 'client' ? 'active' : ''" @click="()=>{info = 'client'}">Клиент</span>
    						<span :class="info == 'history' ? 'active' : ''" @click="()=>{info = 'history'}">История</span>
    						<span :class="info == 'signature' ? 'active' : ''" @click="()=>{info = 'signature'}">Подтверждение доставки</span>
    						<span :class="info == 'images' ? 'active' : ''" @click="()=>{info = 'images'}">Фото посылки</span>
                <span :class="info == 'confirm' ? 'active' : ''" @click="()=>{info = 'confirm'}">Подтверждение оплаты</span>
    						<span :class="info == 'notifications' ? 'active' : ''" @click="()=>{info = 'notifications'}">Смс уведомление</span>
    						<span @click="drawingRoad">Карта</span>
    						<span v-if="item.change_history" class="order-details" :class="info == 'change_history' ? 'active' : ''" @click="()=>{info = 'change_history'}">История изменений</span>
    						<span class="order-details" :class="info == 'provider_info' ? 'active' : ''" @click="()=>{info = 'provider_info'}">Поставщик</span>
    					</div>
    					<div class="order-content-body-top-navbar-end">
    						<span class="btn btn-success" @click="getInvoice(item.id)">Накладная</span>
    					</div>
    				</div>
    			</div>

    			<div class="order-content-head" v-if="item.id && info == 'header'">
    				<div class="order-content-head-block">
    					<div class="order-content-head-block-top">
    						<div class="order-content-head-block-img" :style="'background: ' + clientColor(item.sender_name)">
    							{{clientName(item.sender_name)}}
    						</div>
    						<div class="order-content-head-block-content">
    							<div class="order-content-head-block-content-title">
    								отправитель
    							</div>
    							<div class="order-content-head-block-content-value">
    								{{item.sender_name}}
    							</div>
    							<div class="order-content-head-block-content-phone">
    								{{item.sender_phone}} {{item.sender_phone_1}} {{item.sender_phone_2}}
    							</div>
    							<div class="order-content-head-block-content-address">
    								<div class="round">
    									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-building" viewBox="0 0 16 16">
    									  <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
    									  <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
    									</svg>
    								</div>
    								<span style="width: 340px; margin-left: 5px">
                      {{item.sender_country ? item.sender_country.title : '' }},
                      {{item.sender_region ? item.sender_region.title : '' }},
                      {{item.sender_city ? item.sender_city.title : '' }},
                      {{item.sender_address}},
                    </span>
    							</div>
    						</div>
    					</div>
    					<div class="order-content-head-block-bottom">
    						<span class="cargo-details">Детали посылки</span>
    						<div class="order-content-head-block-bottom-block" style="margin-top: 10px">
    							<div class="order-content-head-block-bottom-block-item">
    								<div class="round-big">
    									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" class="bi bi-cart4" viewBox="0 0 16 16">
    										<path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
    									</svg>
    								</div>
    								<div class="order-content-head-block-bottom-block-item-second">
    									<div class="order-content-head-block-bottom-block-item-title">Оплачиваемый вес</div>
    									<div class="order-content-head-block-bottom-block-item-value">{{item.weight > item.volume_weight ? item.weight : item.volume_weight}} кг</div>
    								</div>
    							</div>
    							<div class="order-content-head-block-bottom-block-item">
    								<div class="round-big">
    									КВ
    								</div>
    								<div class="order-content-head-block-bottom-block-item-second">
    									<div class="order-content-head-block-bottom-block-item-title">Количество вещей</div>
    									<div class="order-content-head-block-bottom-block-item-value"> 1/{{item.count_of_parts ? item.count_of_parts : 1}} </div>
    								</div>
    							</div>
    							<div class="order-content-head-block-bottom-block-item">
    								<div class="round-big">
    									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" class="bi bi-lightning" viewBox="0 0 16 16">
    									  <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5zM6.374 1 4.168 8.5H7.5a.5.5 0 0 1 .478.647L6.78 13.04 11.478 7H8a.5.5 0 0 1-.474-.658L9.306 1H6.374z"/>
    									</svg>
    								</div>
    								<div class="order-content-head-block-bottom-block-item-second">
    									<div class="order-content-head-block-bottom-block-item-title">Тип услуги</div>
    									<div class="order-content-head-block-bottom-block-item-value" style="color: #54AF3A">
                        <span v-if="item.tariff"> <span v-if="item.tariff.type_of_service"> {{item.tariff.type_of_service.title}} </span> </span>
                      </div>
    								</div>
    							</div>
    						</div>
    						<div class="order-content-head-block-bottom-block" style="margin-top: 10px">
    							<div class="order-content-head-block-bottom-block-item">
    								<div class="round-big">
    									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-pc-display-horizontal" viewBox="0 0 16 16">
    									  <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0h-13Zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5ZM12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1ZM1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25Z"/>
    									</svg>
    								</div>
    								<div class="order-content-head-block-bottom-block-item-second">
    									<div class="order-content-head-block-bottom-block-item-title">Получен через</div>
    									<div class="order-content-head-block-bottom-block-item-value">
                        <span style="color: #318CE7;" v-if="item.who_create == 'employee'">Сотрудник создал</span>
                        <span style="color: #00A06B;" v-else>Клиент создал</span>
                      </div>
    								</div>
    							</div>
    							<div class="order-content-head-block-bottom-block-item">
    								<div class="round-big">
    									uzs
    								</div>
    								<div class="order-content-head-block-bottom-block-item-second">
    									<div class="order-content-head-block-bottom-block-item-title">Стоимость вещей</div>
    									<div class="order-content-head-block-bottom-block-item-value" style="color: red">{{item.cost_of_things ? item.cost_of_things + ' uzs' : 'Не оценено'}} </div>
    								</div>
    							</div>
    							<div class="order-content-head-block-bottom-block-item">
    								<div class="round-big">
    									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-infinity" viewBox="0 0 16 16">
    									  <path d="M5.68 5.792 7.345 7.75 5.681 9.708a2.75 2.75 0 1 1 0-3.916ZM8 6.978 6.416 5.113l-.014-.015a3.75 3.75 0 1 0 0 5.304l.014-.015L8 8.522l1.584 1.865.014.015a3.75 3.75 0 1 0 0-5.304l-.014.015L8 6.978Zm.656.772 1.663-1.958a2.75 2.75 0 1 1 0 3.916L8.656 7.75Z"/>
    									</svg>
    								</div>
    								<div class="order-content-head-block-bottom-block-item-second">
    									<div class="order-content-head-block-bottom-block-item-title">Тариф</div>
    									<div class="order-content-head-block-bottom-block-item-value" style="color: #F07427">
                        {{item.tariff ? item.tariff.title : 'Не определено'}}
                      </div>
    								</div>
    							</div>
    						</div>
    						<div class="order-content-head-block-bottom-block" style="margin-top: 20px">
    							<div class="order-content-head-block-bottom-block-item" style="display: block; width: 196px">
    								<div>Груз:</div>
    								<span class="btn btn-info">{{item.title}}</span>
    							</div>
    							<div class="order-content-head-block-bottom-block-item" style="display: block; width: 196px">
    								<div>Водитель:</div>
    								<span class="btn btn-primary" v-if="item.driver">{{item.driver.name}}</span>
    							</div>
    							<div class="order-content-head-block-bottom-block-item" style="display: block; width: 196px">
    								<div>Заметки:</div>
    								<span style="color: #1C27F0">{{item.notes}}</span>
    							</div>
    						</div>
    					</div>
    				</div>
    				<div class="order-content-head-block">
    					<div class="order-content-head-block-top">
    						<div class="order-content-head-block-img" :style="'background: ' + clientColor(item.recipient_name)">
    							{{clientName(item.recipient_name)}}
    						</div>
    						<div class="order-content-head-block-content">
    							<div class="order-content-head-block-content-title">
    								Получатель
    							</div>
    							<div class="order-content-head-block-content-value">
    								{{item.recipient_name}}
    							</div>
    							<div class="order-content-head-block-content-phone">
    								{{item.recipient_phone}} {{item.recipient_phone_1}} {{item.recipient_phone_2}}
    							</div>
    							<div class="order-content-head-block-content-address">
    								<div class="round">
    									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-building" viewBox="0 0 16 16">
    									  <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
    									  <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
    									</svg>
    								</div>
    								<span style="width: 340px; margin-left: 5px">
                      {{item.recipient_country ? item.recipient_country.title : '' }},
                      {{item.recipient_region ? item.recipient_region.title : '' }},
                      {{item.recipient_city ? item.recipient_city.title : '' }},
                      {{item.recipient_address}},
                    </span>
    							</div>
    						</div>
    					</div>
    					<div class="order-content-head-block-bottom">
    						<span class="cargo-details">Информация об оплате</span>
    						<div class="order-content-head-block-bottom-block" style="margin-top: 10px">
    							<div class="order-content-head-block-bottom-block-item">
    								<div class="round-big">
    									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" class="bi bi-cash-coin" viewBox="0 0 16 16">
    									  <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
    									  <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
    									  <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
    									  <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
    									</svg>
    								</div>
    								<div class="order-content-head-block-bottom-block-item-second">
    									<div class="order-content-head-block-bottom-block-item-title">Способ оплаты</div>
    									<div class="order-content-head-block-bottom-block-item-value text-uppercase" style="color: #54AF3A">
                        <span v-if="item.payment_method == 'cash'">Наличные</span>
                        <span v-else-if="item.payment_method == 'wallet'">Пластик карта</span>
                        <span v-else-if="item.payment_method == 'credit_balance'">Перечисление</span>
                        <span v-else-if="item.payment_method == 'payme'">Payme</span>
                        <span v-else-if="item.payment_method == 'uzumbank'">Uzum Bank</span>
                      </div>
    								</div>
    							</div>
    							<div class="order-content-head-block-bottom-block-item">
    								<div class="round-big">
    									УО
    								</div>
    								<div class="order-content-head-block-bottom-block-item-second">
    									<div class="order-content-head-block-bottom-block-item-title">Услугу оплачивает</div>
    									<div class="order-content-head-block-bottom-block-item-value text-uppercase" style="color: #54AF3A">
                        <span v-if="item.who_pays == 'sender'">Отправитель</span>
                        <span v-else-if="item.who_pays == 'recipient'">Получатель</span>
                      </div>
    								</div>
    							</div>
    						</div>
    						<div class="order-content-head-block-bottom-block" style="margin-top: 20px; align-items: center">
    							<table class="price-table">
    								<tbody>
    									<tr>
    										<td> <div class="td-1">Цена услуги по тарифу	</div>  </td>
    										<td>{{item.tariff_price}} UZS</td>
    									</tr>
    									<tr v-if="item.code">
    										<td> <div class="td-1">Промокод	</div>  </td>
    										<td style="color: #F07427">{{item.code}}</td>
    									</tr>
    									<tr v-if="item.code">
    										<td> <div class="td-1">Cидка	</div>  </td>
    										<td  style="color: #F07427">{{item.discount}} UZS
                          <span v-if="item.promocode">
                            <span v-if="item.promocode.type == 'free_order'"> {{item.promocode.code}} Бесплатный заказ</span>
                            <span v-else-if="item.promocode.type == 'percentage_discount'"> {{item.promocode.code}} Скидка на процентах</span>
                            <span v-else-if="item.promocode.type == 'absolute_discount'"> {{item.promocode.code}} Фиксированная цена</span>
                          </span>
                        </td>
    									</tr>
    									<tr>
    										<td> <div class="td-1" style="color: #54AF3A">Фактическая цена (со скидкой)	</div>   </td>
    										<td style="color: #54AF3A">{{item.cost_of_service}} UZS</td>
    									</tr>
    									<tr>
    										<td> <div class="td-1">НДС	</div>   </td>
    										<td style="color: red">
                          <span v-if="item.vat == 'vat_15'">15%</span>
                          <span v-else-if="item.vat == 'vat_12'">12%</span>
                          <span v-else-if="item.vat == 'vat_0'">0%</span>
                          <span v-else>Без НДС</span>
                        </td>
    									</tr>
    									<tr>
    										<td> <div class="td-1">Цена НДС	</div>   </td>
    										<td style="color: red">{{item.cost_of_service_vat ? item.cost_of_service_vat : 0}} UZS</td>
    									</tr>
    									<tr>
    										<td> <div class="td-1">Цена услуги с НДС	</div>   </td>
    										<td>{{item.cost_of_service_with_vat ? item.cost_of_service_with_vat : item.cost_of_service}} UZS</td>
    									</tr>
                      <tr v-if="item.cashback">
    										<td> <div class="td-1">Кешбек	</div>  </td>
    										<td style="color: #F07427">{{item.cashback}} uzs</td>
    									</tr>
    									<tr>
    										<td style="padding-top: 25px"> <div class="td-1">Наложенный платеж</div>   </td>
    										<td style="padding-top: 25px">{{item.cod}} uzs</td>
    									</tr>
                      <tr>
    										<td> <div class="td-1">Статус наложенного платежа</div>   </td>
    										<td>
                          <span v-if="item.status_of_payment == 'paid'" style="color: green">Оплачено</span>
                          <span v-else style="color: red"> Не оплачено</span>
                        </td>
    									</tr>
    								</tbody>
    							</table>
    							<div class="price-summ">
    								Всего:
    								<div class="price-summ-value">
                      <span v-if="item.cashback"> {{item.cost_of_service_with_vat ? (item.cost_of_service_with_vat - item.cashback) : (item.cost_of_service + item.cost_of_service_vat - item.cashback)}} uzs</span>
                      <span v-else>{{item.cost_of_service_with_vat ? item.cost_of_service_with_vat : (item.cost_of_service + item.cost_of_service_vat)}} uzs</span>

    								</div>
    							</div>
    						</div>
    					</div>
    				</div>
    			</div>

          <div class="order-client-info" v-if="item.client && info == 'client'">
            <div class="order-content-client-block">
              <table>
                <tbody>
                  <tr>
                    <td>Имя </td>
                    <td class="value">{{item.client.name}} </td>
                  </tr>
                  <tr>
                    <td>Логин </td>
                    <td class="value">{{item.client.email}} </td>
                  </tr>
                  <tr>
                    <td>Email </td>
                    <td class="value">{{item.client.email}} </td>
                  </tr>
                  <tr>
                    <td>Телефон </td>
                    <td class="value">{{item.client.phone}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="order-content-client-block">
              <table>
                <tbody>
                  <tr>
                    <td>Статус </td>
                    <td class="value">{{item.client.status == 'active' ? 'Активный' : 'Не активный'}} </td>
                  </tr>
                  <tr>
                    <td>Активированный получатель </td>
                    <td class="value">{{item.client.status == 'active' ? 'Включено' : 'Отключено'}} </td>
                  </tr>
                  <tr>
                    <td>SMS-уведомления </td>
                    <td class="value">{{item.client.status == 'active' ? 'Включено' : 'Отключено'}} </td>
                  </tr>
                  <tr>
                    <td>Всплывающие уведомления </td>
                    <td class="value">{{item.client.status == 'active' ? 'Включено' : 'Отключено'}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="order-content-client-block">
              <table>
                <tbody>
                  <tr>
                    <td>Компания </td>
                    <td class="value">{{item.client.name}} </td>
                  </tr>
                  <tr>
                    <td>Кредитный баланс </td>
                    <td class="value">0.0 uzs </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="order-history-info" v-if="info == 'history'">
            <div v-if="item.history.length">
              <div class="order-content-history-block" v-for="history in item.history">
                <div class="title-0-1068">{{createdAt(history.created_at)}}</div>
                <div style="width: 100%;" v-if="history.creator"> <span style="width: 300px !important; color: #F7721E">Кем обновлен:</span>  <span style="color: #0A9D0C">{{history.creator.name}}</span> </div>
                <div style="width: 100%;" v-if="history.status"> <span style="width: 300px !important; color: #F7721E">Статус:</span>  <span style="color: #0A9D0C">{{history.status.title}}</span> </div>
                <div style="width: 100%;" v-if="history.driver"> <span style="width: 300px !important; color: #F7721E">Водитель:</span>  <span style="color: #0A9D0C">{{history.driver.name}}</span> </div>
                <div style="width: 100%;" v-if="history.branch"> <span style="width: 300px !important; color: #F7721E">Офис:</span>  <span style="color: #0A9D0C">{{history.branch.title}}</span> </div>
                <div style="width: 100%;" v-if="history.reason"> <span style="width: 300px !important; color: #F7721E">Причина:</span>  <span style="color: #0A9D0C">{{history.reason}}</span> </div>
              </div>
            </div>
            <div v-else>
              <h4 style="text-align: center; color: red">Пока нет историй</h4>
            </div>
          </div>

          <div class="order-signature-info" v-if="item.id && info == 'signature'">
            <div class="order-photos">
              <template>
                <div class="clearfix">
                  <a-upload

                    list-type="picture-card"
                    :file-list="confirmListOrder"
                    @preview="handlePreviewOrder"
                    @change="handleChangeSignature"
                    :remove="removeSignature"
                  >
                  </a-upload>
                  <a-modal :visible="previewVisibleOrder" :footer="null" @cancel="handleCancelOrder" class="img-preview">
                    <img alt="example" style="width: 100%" :src="previewImageOrder" />
                  </a-modal>
                </div>
              </template>
            </div>
          </div>

          <div class="order-images-info" v-if="item.id && info == 'images'">
            <div class="order-photos">
              <template>
                <div class="clearfix">
                  <a-upload
                    :action="$store.state.urlBackend + '/api/employee/orders/image/order/' + item.id"
                    :headers='{"Authorization": `Bearer ` + access_token}'
                    list-type="picture-card"
                    :file-list="fileListOrder"
                    @preview="handlePreviewOrder"
                    @change="handleChangeOrder"
                    :remove="deleteImageOrder"
                  >
                    <div v-if="fileList.length < 8">
                      <a-icon type="plus" />
                      <div class="ant-upload-text">
                        Загрузить
                      </div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisibleOrder" :footer="null" @cancel="handleCancelOrder" class="img-preview">
                    <img alt="example" style="width: 100%" :src="previewImageOrder" />
                  </a-modal>
                </div>
              </template>
            </div>
          </div>

          <div class="order-change_history-info" v-if="info == 'change_history'">
            <div v-if="item.change_history.length" class="" style="background: #fff; width: 100% !important; padding: 10px">
              <div style="">
                <div>
                  <div>

                    <div style="margin-bottom: 12px" v-for="history in item.change_history">
                      <div class="title-0-1068">{{createdAt(history.created_at)}}</div>
                      <div style="width: 100%;" class="" v-if="history.user"> <span style="width: 300px !important; color: #F7721E">Кем изменен:</span>  <span style="color: #0A9D0C">{{history.user.name}}</span> </div>
                      <div style="width: 100%;" class=""> <span style="width: 300px !important; color: #F7721E">Что изменен:</span>  <span style="color: #0A9D0C">{{history.title}}</span> </div>
                      <div style="width: 100%;" class=""> <span style="width: 300px !important; color: #F7721E">Старое значение:</span>  <span style="color: #0A9D0C">{{history.old_value}}</span> </div>
                      <div style="width: 100%;" class=""> <span style="width: 300px !important; color: #F7721E">Новое значение:</span>  <span style="color: #0A9D0C">{{history.new_value}}</span> </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <h4 style="color: red; text-align: center">Нет изменений</h4>
            </div>
          </div>

          <div class="order-change_history-info" v-if="info == 'notifications'">
            <div v-if="item.notifications.length" class="" style="background: #fff; width: 100% !important; padding: 10px">
              <div style="">
                <div>
                  <div>

                    <div style="margin-bottom: 12px" v-for="notification in item.notifications">
                      <div class="title-0-1068">{{createdAt(notification.created_at)}}</div>

                      <div style="width: 100%;" class=""> <span style="width: 300px !important; color: #F7721E">Кто отправил:</span>  <span style="color: #222">{{notification.creator ? notification.creator.name : '--'}}</span> </div>

                      <div style="width: 100%;" class="" v-if="notification.type == 'about_delivery'"> <span style="width: 300px !important; color: green">Уведомление о доставке</span> </div>
                      <div style="width: 100%;" class="" v-else-if="notification.type == 'about_receiving'"> <span style="width: 300px !important; color: blue">Уведомление о получении</span> </div>
                      <div style="width: 100%;" class="" v-else-if="notification.type == 'about_delivery_boomerang'"> <span style="width: 300px !important; color: green">Уведомление о доставке (бумеранг)</span> </div>
                      <div style="width: 100%;" class="" v-else-if="notification.type == 'about_cancel'"> <span style="width: 300px !important; color: red">Уведомление об отказе</span> </div>

                      <div style="width: 100%;" class=""> <span style="width: 300px !important; color: #F7721E">Текст сообщения:</span>  <span style="color: #222">{{notification.text}}</span> </div>

                      <div style="width: 100%;" class="" v-if="notification.status == 'pending'"> <span style="width: 300px !important; color: #F7721E">Статус:</span>  <span style="color: blue">В ожидании</span> </div>
                      <div style="width: 100%;" class="" v-else-if="notification.status == 'error'"> <span style="width: 300px !important; color: #F7721E">Статус:</span>  <span style="color: red">Ошибка</span> </div>
                      <div style="width: 100%;" class="" v-else-if="notification.status == 'success'"> <span style="width: 300px !important; color: #F7721E">Статус:</span>  <span style="color: green">Успешно</span> </div>

                      <div style="width: 100%;" class="" v-if="notification.status == 'error'"> <span style="width: 300px !important; color: #F7721E">Текст ошибки:</span>  <span style="color: red">{{notification.error_content}}</span> </div>

                      <div style="width: 100%;" class=""> <span style="width: 300px !important; color: #F7721E">Телефон:</span>  <span style="color: #222">{{notification.phone}}</span> </div>
                      <div style="width: 100%;" class=""> <span style="width: 300px !important; color: #F7721E">Количество:</span>  <span style="color: #222">{{notification.countable}}</span> </div>
                      <div style="width: 100%;" class=""> <span style="width: 300px !important; color: #F7721E">Страна:</span>  <span style="color: #222">{{notification.country}}</span> </div>
                      <hr>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <h4 style="color: red; text-align: center">Уведомлений не отправлено</h4>
            </div>
          </div>

          <div class="order-client-info" v-if="item.provider && info == 'provider_info'">
            <div class="order-content-client-block">
              <table>
                <tbody>
                  <tr>
                    <td>ФИО поставщика </td>
                    <td class="value">{{item.provider.client_name}} </td>
                  </tr>
                  <tr>
                    <td>Дата договора </td>
                    <td class="value">{{item.provider.registration_date}} </td>
                  </tr>
                  <tr>
                    <td>Номер договора </td>
                    <td class="value">{{item.provider.contract_number}} </td>
                  </tr>
                  <tr>
                    <td>Адрес поставщика </td>
                    <td class="value">{{item.provider.address}} </td>
                  </tr>
                  <tr>
                    <td>Телефон </td>
                    <td class="value">{{item.provider.phone}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="order-content-client-block">
              <table>
                <tbody>
                  <tr>
                    <td>МФО </td>
                    <td class="value">{{item.provider.bank_code}} </td>
                  </tr>
                  <tr>
                    <td>Банк </td>
                    <td class="value">{{item.provider.bank}} </td>
                  </tr>
                  <tr>
                    <td>Расчетный счет </td>
                    <td class="value">{{item.provider.checking_account}} </td>
                  </tr>
                  <tr>
                    <td>ИНН поставщика </td>
                    <td class="value">{{item.provider.tin}} </td>
                  </tr>
                  <tr>
                    <td>ОКЕД </td>
                    <td class="value">{{item.provider.oked}} </td>
                  </tr>
                  <tr>
                    <td>Код НДС </td>
                    <td class="value">{{item.provider.vat_code}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="order-content-client-block">
              <table>
                <tbody>
                  <tr>
                    <td>ФИО директора </td>
                    <td class="value">{{item.provider.customer_director}} </td>
                  </tr>
                  <tr>
                    <td>Должность руководителя поставщика </td>
                    <td class="value">{{item.provider.customer_director_position}} </td>
                  </tr>
                  <tr>
                    <td>Баланс </td>
                    <td class="value">{{item.provider.balance}} </td>
                  </tr>
                  <tr style="border-top: 1px solid #444">
                    <td>Трекинг код </td>
                    <td class="value">{{item.tracking_code}} </td>
                  </tr>
                  <tr style="border-top: 1px solid #444">
                    <td>Телефонный номер CSE </td>
                    <td class="value">{{item.cse_phone}} </td>
                  </tr>
                  <tr v-if="item.provider_phone">
                    <td>Телефон поставщика </td>
                    <td class="value">{{item.provider_phone}} </td>
                  </tr>
                  <tr v-if="item.provider_phone">
                    <td>Цена заказа </td>
                    <td class="value">{{item.cost_of_service_with_vat ? item.cost_of_service_with_vat : item.cost_of_service}} uzs </td>
                  </tr>
                  <tr v-if="item.provider_phone">
                    <td>Цена поставщика </td>
                    <td class="value">{{item.provider_price}} uzs </td>
                  </tr>
                  <tr v-if="item.provider_phone">
                    <td>Маржа </td>
                    <td class="value">{{(item.cost_of_service_with_vat ? item.cost_of_service_with_vat : item.cost_of_service) - item.provider_price}} uzs </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
    		</div>
    	</div>

      <!-- <div class="d-flex justify-content-start aling-items-center header-order-info">
        <div class="mr-5 d-flex justify-content-start aling-items-center">
          <div class="m-1 icon-of-header">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
              <path fill="#8312FA" d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path fill="#8312FA" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.0002.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </div>
          <span class="m-1">ЗАКАЗ № - {{item.id}}</span>
        </div>
        <div class="mr-5 d-flex justify-content-center aling-items-center">
          <div class="m-1 icon-of-header">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
              <path fill="#8312FA" d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path fill="#8312FA" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
          </div>
          <span class="m-1">СОЗДАНО: <br> {{createdAt(item.created_at)}}</span>
        </div>
        <div class="mr-5 d-flex justify-content-center aling-items-center" v-if="item.status">
          <div class="m-1 icon-of-header">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
              <path :fill="item.status.color" d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
              <path :fill="item.status.color" d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
            </svg>
          </div>
          <span class="m-1">СТАТУС: <br> <span>{{item.status.title}}</span> </span>
        </div>

        <div class="ml-auto p-2" style="font-size: 18px; cursor: pointer" @click="editItem"  v-if="item.status_id != 9 && item.status_id != 14 && item.status_id != 23">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
          </svg>
          Изменить
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center body-order-info mb-3 mt-3">
        <div class="d-flex justify-content-between align-items-center">

          <div class="client-name-letter m-1" v-if="item.client" :style="'background: ' + clientColor(item.client.name)">
            <div v-if="item.client.image" class="client-image"><img  :src="$store.state.urlBackend + item.client.image"></div>
            <span v-else :style="'background: ' + clientColor(item.client.name)">{{clientName(item.client.name)}}</span>
          </div>
          <div class="client-name-letter m-1" v-else>
            ЕE
          </div>
          <div>
            <small class="bold">Клиент</small>
            <br>
            <span class="bold" v-if="item.client">{{item.client.name}}</span>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div style="text-align: right">
            <small class="bold">Офис</small>
            <br>
            <span class="bold" v-if="item.branch_from">{{item.branch_from.title}}</span>
          </div>
          <div class="client-name-letter m-1" v-if="item.branch_from" :style="'background: ' + clientColor(item.client.name)">
            {{clientName(item.branch_from.title)}}
          </div>
          <div class="client-name-letter m-1" v-else>
              EE
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between aling-items-center footer-order-info mb-3">
        <div class="d-flex justify-content-start aling-items-center">
          <div class="order-details" :class="info == 'header' ? 'active' : ''" @click="()=>{info = 'header'}">
            Основная
          </div>
          <div class="order-details" :class="info == 'client' ? 'active' : ''" @click="()=>{info = 'client'}">
            Клиент
          </div>
          <div class="order-details" :class="info == 'history' ? 'active' : ''" @click="()=>{info = 'history'}">
            История
          </div>
          <div class="order-details" :class="info == 'signature' ? 'active' : ''" @click="()=>{info = 'signature'}">
            Подтверждение доставки
          </div>
          <div class="order-details" :class="info == 'images' ? 'active' : ''" @click="()=>{info = 'images'}">
            Фото посылки
          </div>
          <div class="order-details" :class="info == 'confirm' ? 'active' : ''" @click="()=>{info = 'confirm'}">
            Подтверждение оплаты
          </div>
          <div class="order-details" :class="info == 'qrcode' ? 'active' : ''" @click="()=>{info = 'qrcode'}">
            QR code
          </div>
          <div class="order-details" @click="drawingRoad">
            Карта
          </div>
          <div v-if="item.change_history" class="order-details" :class="info == 'change_history' ? 'active' : ''" @click="()=>{info = 'change_history'}">
            История изменений
          </div>
        </div>
        <div class="d-flex justify-content-end aling-items-center" >
          <div class="order-details">
            <a @click="getInvoice(item.id)">
              Накладная
            </a>
          </div>
        </div>
      </div>

      <div v-if="item.id && info == 'header'" class="container-0-1497 container-0-951">
        <div class="row-0-1498 row-0-952">
          <div class="colMargin-0-1499 colMargin-0-953">
            <div class="contentCard-0-1509 contentCard-0-975" style="color: rgba(0, 0, 0, 0.87); background-color: rgb(255, 255, 255); transition: all 450ms cubic-bezier(0.23, 1, 0.1, 1) 0ms; box-sizing: border-box;  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; border-radius: 2px;">
              <div class="detailsCard-0-1510 detailsCard-0-976">
                <div size="130" style="color: rgb(255, 255, 255); user-select: none; display: inline-flex; align-items: center; justify-content: center; font-size: 65px; border-radius: 50%; height: 130px; width: 130px;" :style="'background: ' + clientColor(item.sender_name)">
                  {{clientName(item.sender_name)}}
                </div>
                <div class="contentCardText-0-1508 contentCardText-0-977">
                  <div class="cardTextTitle-0-1511 cardTextTitle-0-978">Отправитель</div>
                  <div class="cardTextName-0-1512 cardTextName-0-979">{{item.sender_name}} </div>
                  <div class="cardTextPhone-0-1513 cardTextPhone-0-980">{{item.sender_phone}}</div>
                  <div class="root-0-53 wrap-false-0-63 direction-row-0-90 root-0-1517 root-0-991">
                    <div class="leftAvatar-0-1516 leftAvatar-0-994">
                      <div size="40" style="color: rgb(255, 255, 255); background-color: rgb(121, 134, 203); user-select: none; display: inline-flex; align-items: center; justify-content: center; font-size: 20px; border-radius: 50%; height: 40px; width: 40px;">
                        <svg v-if="item.sender_type_of_building=='residential'" viewBox="0 0 24 24" style="display: inline-block; color: rgb(255, 255, 255); fill: rgb(255, 255, 255); height: 24px; width: 24px; user-select: none; transition: all 450ms cubic-bezier(0.23, 1, 0.1, 1) 0ms; font-size: 24px; margin: 8px;">
                          <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path>
                        </svg>
                        <svg v-else-if="item.sender_type_of_building=='commercial'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                          <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                        </svg>
                      </div>
                    </div>
                    <div class="root-0-53 wrap-false-0-63 direction-column-0-92 textBlock-0-1520 textBlock-0-995">
                      <span class="title-0-1518 title-0-992" v-if="item.sender_country && item.sender_region">{{item.sender_country.title}}, {{item.sender_region.title}}</span>
                      <span class="text-0-1519 text-0-993" v-if="item.sender_city">{{item.sender_city.title}}, {{item.sender_address}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="colMargin-0-1499 colMargin-0-953">
            <div class="contentCard-0-1522 contentCard-0-975" style="color: rgba(0, 0, 0, 0.87); background-color: rgb(255, 255, 255); transition: all 450ms cubic-bezier(0.23, 1, 0.1, 1) 0ms; box-sizing: border-box;  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; border-radius: 2px;">
              <div class="detailsCard-0-1523 detailsCard-0-976">
                <div size="130" style="color: rgb(255, 255, 255); user-select: none; display: inline-flex; align-items: center; justify-content: center; font-size: 65px; border-radius: 50%; height: 130px; width: 130px;" :style="'background: ' + clientColor(item.recipient_name)">
                  {{clientName(item.recipient_name)}}
                </div>
                <div class="contentCardText-0-1521 contentCardText-0-977">
                <div class="cardTextTitle-0-1524 cardTextTitle-0-978">Получатель</div>
                <div class="cardTextName-0-1525 cardTextName-0-979">{{item.recipient_name}} </div>
                  <div class="cardTextPhone-0-1526 cardTextPhone-0-980">{{item.recipient_phone}}</div>
                  <div class="root-0-53 wrap-false-0-63 direction-row-0-90 root-0-1530 root-0-991">
                    <div class="leftAvatar-0-1529 leftAvatar-0-994">
                      <div size="40" style="color: rgb(255, 255, 255); background-color: rgb(121, 134, 203); user-select: none; display: inline-flex; align-items: center; justify-content: center; font-size: 20px; border-radius: 50%; height: 40px; width: 40px;">
                        <svg v-if="item.recipient_type_of_building=='residential'" viewBox="0 0 24 24" style="display: inline-block; color: rgb(255, 255, 255); fill: rgb(255, 255, 255); height: 24px; width: 24px; user-select: none; transition: all 450ms cubic-bezier(0.23, 1, 0.1, 1) 0ms; font-size: 24px; margin: 8px;">
                          <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path>
                        </svg>
                        <svg v-else-if="item.recipient_type_of_building=='commercial'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                          <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                        </svg>
                      </div>
                    </div>
                    <div class="root-0-53 wrap-false-0-63 direction-column-0-92 textBlock-0-1533 textBlock-0-995">
                      <span class="title-0-1531 title-0-992" v-if="item.recipient_country && item.recipient_region">{{item.recipient_country.title}}, {{item.recipient_region.title}}</span>
                      <span class="text-0-151 text-0-993" v-if="item.recipient_city">{{item.recipient_city.title}},  {{item.recipient_address}} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-0-1498 row-0-952">
          <div class="colMargin-0-1499 colMargin-0-953">
            <div class="contentCard-0-1500 contentCard-0-954" style="color: rgba(0, 0, 0, 0.87); background-color: rgb(255, 255, 255); transition: all 450ms cubic-bezier(0.23, 1, 0.1, 1) 0ms; box-sizing: border-box;  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; border-radius: 2px;">
              <span class="detailsTitle-0-1503 detailsTitle-0-957">Детали посылки</span>
              <div class="row-0-1498 row-0-952">
                <div>
                  <div class="root-0-53 wrap-false-0-63 direction-row-0-90 root-0-1535 root-0-991">
                    <div class="leftAvatar-0-1534 leftAvatar-0-994">
                      <div size="40" style="color: rgb(255, 255, 255); background-color: rgb(158, 158, 158); user-select: none; display: inline-flex; align-items: center; justify-content: center; font-size: 20px; border-radius: 50%; height: 40px; width: 40px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-cart4" viewBox="0 0 16 16">
                          <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
                        </svg>
                      </div>
                    </div>
                    <div class="root-0-53 wrap-false-0-63 direction-column-0-92 textBlock-0-1538 textBlock-0-995">
                      <span class="title-0-1536 title-0-992">Оплачиваемый вес</span>
                      <span class="text-0-1537 text-0-993" v-if="item.weight > item.volume_weight">{{item.weight}}</span>
                      <span class="text-0-1537 text-0-993" v-else>{{item.volume_weight}}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="root-0-53 wrap-false-0-63 direction-row-0-90 root-0-1540 root-0-991">
                    <div class="leftAvatar-0-1539 leftAvatar-0-994">
                      <div size="40" style="color: rgb(255, 255, 255); background-color: rgb(158, 158, 158); user-select: none; display: inline-flex; align-items: center; justify-content: center; font-size: 20px; border-radius: 50%; height: 40px; width: 40px;">
                      </div>
                    </div>
                    <div class="root-0-53 wrap-false-0-63 direction-column-0-92 textBlock-0-1543 textBlock-0-995">
                      <span class="title-0-1541 title-0-992">Количество коробок</span>
                      <span class="text-0-1542 text-0-993">
                        <span class="">
                          {{item.count_of_parts ? item.count_of_parts : 0}} / 1

                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="root-0-53 wrap-false-0-63 direction-row-0-90 root-0-1545 root-0-991">
                    <div class="leftAvatar-0-1544 leftAvatar-0-994">
                      <div class="client-name-letter for-tariff">
                        <div class="tariff">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightning" viewBox="0 0 16 16">
                            <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5zM6.374 1 4.168 8.5H7.5a.5.5 0 0 1 .478.647L6.78 13.04 11.478 7H8a.5.5 0 0 1-.474-.658L9.306 1H6.374z"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="root-0-53 wrap-false-0-63 direction-column-0-92 textBlock-0-1548 textBlock-0-995">
                      <span class="title-0-1546 title-0-992">Тип услуги</span>
                      <span class="text-0-1547 text-0-993">
                        <div class="serviceType-0-1506 serviceType-0-961" v-if="item.tariff"> <div v-if="item.tariff.type_of_service"> {{item.tariff.type_of_service.title}} </div> </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row-0-1498 row-0-952">
                <div>
                  <div class="root-0-53 wrap-false-0-63 direction-row-0-90 root-0-1550 root-0-991">
                    <div class="leftAvatar-0-1549 leftAvatar-0-994">
                      <div size="40" style="color: rgb(255, 255, 255); background-color: rgb(139, 195, 74); user-select: none; display: inline-flex; align-items: center; justify-content: center; font-size: 20px; border-radius: 50%; height: 40px; width: 40px;">
                        <svg viewBox="0 0 24 24" style="display: inline-block; color: rgb(255, 255, 255); fill: rgb(255, 255, 255); height: 24px; width: 24px; user-select: none; transition: all 450ms cubic-bezier(0.23, 1, 0.1, 1) 0ms; font-size: 24px; margin: 8px;">
                          <path d="M21 2H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h7l-2 3v1h8v-1l-2-3h7c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 12H3V4h18v10z"></path>
                        </svg>
                      </div>
                    </div>
                    <div class="root-0-53 wrap-false-0-63 direction-column-0-92 textBlock-0-1553 textBlock-0-995">
                      <span class="title-0-1551 title-0-992">Получен через :</span>
                      <span class="text-0-1552 text-0-993">
                        <div style="color: rgb(139, 195, 74);">Web</div>
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="row-0-1498 row-0-952">
                    <div>
                      <div class="root-0-53 wrap-false-0-63 direction-row-0-90 root-0-1555 root-0-991">
                        <div class="leftAvatar-0-1554 leftAvatar-0-994">
                          <div class="currencySymbol-0-1502 currencySymbol-0-956">uzs</div>
                        </div>
                        <div class="root-0-53 wrap-false-0-63 direction-column-0-92 textBlock-0-1558 textBlock-0-995">
                          <span class="title-0-1556 title-0-992">Стоимость вещи</span>
                          <span class="text-0-1557 text-0-993">
                            <span style="text-align: right;">
                              <span class="">{{item.cost_of_things}}</span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row-0-1498 row-0-952">
                  <div>
                    <div class="root-0-53 wrap-false-0-63 direction-row-0-90 root-0-1560 root-0-991">
                      <div class="leftAvatar-0-1559 leftAvatar-0-994">
                        <div size="40" style="text-transform: uppercase; color: rgb(255, 255, 255); user-select: none; display: inline-flex; align-items: center; justify-content: center; font-size: 20px; border-radius: 50%; height: 40px; width: 40px;" :style="'background: ' + clientColor(item.tariff ? item.tariff.title : 'Eastline Express')">
                          {{clientName(item.tariff ? item.tariff.title : 'Eastline Express')}}
                        </div>
                      </div>
                      <div class="root-0-53 wrap-false-0-63 direction-column-0-92 textBlock-0-1563 textBlock-0-995">
                        <span class="title-0-1561 title-0-992">Тариф</span>
                        <span class="text-0-1562 text-0-993">
                          <a data-cy="order_selected_package" href="/dashboard/settings/package-rules/item/973437809" v-if="item.tariff">
                            {{item.tariff.title}}
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row-0-1498 row-0-952">
                <div class="root-0-53 wrap-false-0-63 direction-row-0-90 root-0-1565 root-0-991">
                  <div class="root-0-53 wrap-false-0-63 direction-column-0-92 textBlock-0-1568 textBlock-0-995">
                    <span class="title-0-1566 title-0-992">Груз: </span>
                    <br>
                    <span class="text-0-1567 text-0-993">
                      <div style="max-width: 275px;" > <span class="btn btn-success" >{{item.title}}</span> </div>
                    </span>
                  </div>
                </div>
                <div class="root-0-53 wrap-false-0-63 direction-row-0-90 root-0-1570 root-0-991">
                  <div class="root-0-53 wrap-false-0-63 direction-column-0-92 textBlock-0-1573 textBlock-0-995">
                    <span class="title-0-1571 title-0-992">Заметки: </span>
                    <br>
                    <span class="text-0-1572 text-0-993">
                      <div class="root-0-53 wrap-false-0-63 direction-row-0-90">{{item.notes}}</div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row-0-1498 row-0-952">
                <div class="root-0-53 wrap-false-0-63 direction-row-0-90 root-0-1565 root-0-991">
                  <div class="root-0-53 wrap-false-0-63 direction-column-0-92 textBlock-0-1568 textBlock-0-995">
                    <span class="title-0-1566 title-0-992">Водитель: </span>
                    <br>
                    <span class="text-0-1567 text-0-993">
                      <div style="max-width: 275px;" > <span class="btn btn-primary"  v-if="item.driver">{{item.driver.name}}</span> <span v-else>----</span> </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="colMargin-0-1499 colMargin-0-953">
            <div class="contentCard-0-1500 contentCard-0-954" style="color: rgba(0, 0, 0, 0.87); background-color: rgb(255, 255, 255); transition: all 450ms cubic-bezier(0.23, 1, 0.1, 1) 0ms; box-sizing: border-box;  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; border-radius: 2px;">
              <div class="detailsTitle-0-1503 detailsTitle-0-957">Информация об оплате</div>
              <div class="root-0-53 wrap-false-0-63 direction-row-0-90">
                <div class="root-0-53 flex-true-0-83 wrap-false-0-63 direction-row-0-90">
                  <div class="root-0-53 wrap-false-0-63 direction-row-0-90 root-0-1575 root-0-991">
                    <div class="client-name-letter for-tariff">
                      <div class="payment_method" v-if="item.payment_method == 'cash'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
                          <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                          <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
                        </svg>
                      </div>
                      <div class="payment_method" v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card-2-back-fill" viewBox="0 0 16 16">
                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z"/>
                        </svg>
                      </div>
                    </div>
                    <div class="root-0-53 wrap-false-0-63 direction-column-0-92 textBlock-0-1578 textBlock-0-995">
                      <span class="title-0-1576 title-0-992">Способ оплаты</span>
                      <span class="text-0-1577 text-0-993">
                        <div style="color: rgb(33, 150, 243);" v-if="item.payment_method == 'cash'">Наличныe</div>
                        <div style="color: rgb(33, 150, 243);" v-else-if="item.payment_method == 'wallet'">Пластик карта</div>
                        <div style="color: rgb(33, 150, 243);" v-else-if="item.payment_method == 'credit_balance'">Перечисление</div>
                        <div style="color: rgb(33, 150, 243);" v-else-if="item.payment_method == 'payme'">Payme</div>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="root-0-53 flex-true-0-83 wrap-false-0-63 direction-row-0-90">
                  <div class="root-0-53 wrap-false-0-63 direction-row-0-90 root-0-1580 root-0-991">
                    <div class="leftAvatar-0-1579 leftAvatar-0-994">
                      <div size="40" style="color: rgb(255, 255, 255); background-color: rgb(156, 39, 176); user-select: none; display: inline-flex; align-items: center; justify-content: center; font-size: 20px; border-radius: 50%; height: 40px; width: 40px;">

                      </div>
                    </div>
                    <div class="root-0-53 wrap-false-0-63 direction-column-0-92 textBlock-0-1583 textBlock-0-995">
                      <span class="title-0-1581 title-0-992">Услугу оплачивает</span>
                      <span class="text-0-1582 text-0-993">
                        <div class="success-0-376" v-if="item.status_of_payment == 'paid'">Оплачено</div>
                        <span style="color: rgb(33, 150, 243);" v-else>{{item.who_pays == 'sender' ? item.sender_name : item.recipient_name}}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="root-0-53 wrap-false-0-63 justify-space-between-0-70 direction-row-0-90 paymentPrices-0-1505 paymentPrices-0-960">
                <div class="root-0-53 wrap-false-0-63 direction-row-0-90">
                  <div class="root-0-53 wrap-false-0-63 direction-column-0-92" style="padding-right: 10px; padding-left: 0px;">
                    <div>Цена услуги по тарифу</div>
                    <div style="color: #3C2CCB" v-if="">Фактическая цена (с скидкой)</div>
                    <div v-if="item.promocode"> Промокод </div>
                    <div v-if="item.promocode"> Скидка </div>
                    <div> НДС </div>
                    <div> Цена НДС </div>
                    <div> Цена услуги с НДС </div>
                    <hr>
                    <div>Наложенный платеж</div>
                  </div>
                  <div class="root-0-53 flex-true-0-83 wrap-false-0-63 direction-column-0-92" style="text-align: right;">
                    <span style="text-align: right;">
                      <span class="">{{item.tariff_price}} UZS</span>
                      <span>
                        <span style="color: #85C226" v-if="item.status_of_payment == 'paid'"> Оплачено</span>
                        <span class="danger-0-381" v-else>Не оплачено</span>
                      </span>
                    </span>
                    <span style="text-align: right;">
                      <span>{{item.cost_of_service}} UZS</span>
                      <span>
                        <span style="color: #85C226" v-if="item.status_of_payment == 'paid'"> Оплачено</span>
                        <span class="danger-0-381" v-else>Не оплачено</span>
                      </span>
                    </span>
                    <div v-if="item.promocode"> {{item.code}} </div>
                    <div v-if="item.promocode">
                      <span v-if="item.promocode.type == 'free_order'">Бесплатный заказ</span>
                      <span v-else-if="item.promocode.type == 'percentage_discount'">{{item.promocode.value}} % - {{item.discount}} uzs</span>
                      <span v-else-if="item.promocode.type == 'absolute_discount'">Фиксированная цена - {{item.discount}} uzs</span>
                    </div>
                    <div class="" style="color: red">
                      <span v-if="item.vat == 'vat_15'">15%</span><span v-else-if="item.vat == 'vat_12'">12%</span><span v-else-if="item.vat == 'vat_0'">0%</span><span v-else>Без НДС</span>
                    </div>
                    <div class="">
                      <span>{{item.cost_of_service_vat}} uzs</span>
                    </div>
                    <div class="" style="color: #3C2CCB">
                      <span>{{item.cost_of_service_with_vat}} uzs</span>
                    </div>
                    <hr>
                    <span style="text-align: right;">
                      <span class="">{{item.cod ? item.cod : 0}} UZS</span>
                    </span>
                  </div>
                </div>
                <div class="root-0-53 flex-true-0-83 wrap-false-0-63 align-center-0-65 justify-center-0-68 direction-column-0-92">
                  <span class="detailsTitle-0-1503 detailsTitle-0-957">

                  </span>
                  <span class="detailsTitle-0-1503 detailsTitle-0-957">
                    <span style="text-align: right;">
                      <span class="">{{item.cost_of_service_with_vat ? item.cost_of_service_with_vat : 0}} UZS</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="item.client && info == 'client'" class="container-0-1064">
        <div class="row-0-1065">
          <div style="color: rgba(0, 0, 0, 0.87); background-color: rgb(255, 255, 255); transition: all 450ms cubic-bezier(0.23, 1, 0.1, 1) 0ms; box-sizing: border-box; font-family: Roboto, sans-serif; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; border-radius: 2px; z-index: 1;">
            <div style="padding-bottom: 8px;">
              <div style="padding: 16px; font-size: 14px; color: rgba(0, 0, 0, 0.87);">
                <div class="root-0-1067">
                  <div class="title-0-1068">Имя</div>
                  <div class="primary-0-375 content-0-1069">{{item.client.name}}</div>
                </div>
                <div class="root-0-1067">
                  <div class="title-0-1068">Логин</div>
                  <div class="primary-0-375 content-0-1069">{{item.client.email}}</div>
                </div>
                <div class="root-0-1067">
                  <div class="title-0-1068">Эл. почта</div>
                  <div class="primary-0-375 content-0-1069">{{item.client.email}}</div>
                </div>
                <div class="root-0-1067">
                  <div class="title-0-1068">Телефон</div>
                  <div class="primary-0-375 content-0-1069">
                    <span class="">{{item.client.phone}}</span>
                    <span class="success-0-376" v-if="item.client.phone_verifified_at == 1">Потвержден</span>
                    <span class="danger-0-381" v-else> Не потвержден</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="color: rgba(0, 0, 0, 0.87); background-color: rgb(255, 255, 255); transition: all 450ms cubic-bezier(0.23, 1, 0.1, 1) 0ms; box-sizing: border-box; font-family: Roboto, sans-serif; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; border-radius: 2px; z-index: 1;">
            <div style="padding-bottom: 8px;">
              <div style="padding: 16px; font-size: 14px; color: rgba(0, 0, 0, 0.87);">
                <div class="root-0-1067">
                  <div class="title-0-1068">Статус</div>
                  <div class="primary-0-375 content-0-1069">
                    <span class="success-0-376" v-if="item.client.status == 'active'">Aктивный</span>
                    <span class="danger-0-381" v-else>Не активный</span>
                  </div>
                </div>
                <div class="root-0-1067">
                  <div class="title-0-1068">Активированный получатель</div>
                  <div class="primary-0-375 content-0-1069">
                    <span class="success-0-376">Включено</span>
                  </div>
                </div>
                <div class="root-0-1067">
                  <div class="title-0-1068">SMS-уведомления</div>
                  <div class="primary-0-375 content-0-1069">
                    <span class="success-0-376">Включено</span>
                  </div>
                </div>
                <div class="root-0-1067">
                  <div class="title-0-1068">Всплывающие уведомления</div>
                  <div class="primary-0-375 content-0-1069">
                    <span class="success-0-376">Включено</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="color: rgba(0, 0, 0, 0.87); background-color: rgb(255, 255, 255); transition: all 450ms cubic-bezier(0.23, 1, 0.1, 1) 0ms; box-sizing: border-box; font-family: Roboto, sans-serif; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; border-radius: 2px; z-index: 1;">
            <div style="padding-bottom: 8px;">
              <div style="padding: 16px; font-size: 14px; color: rgba(0, 0, 0, 0.87);">
                <div class="root-0-1067">
                  <div class="title-0-1068">Компания</div>
                  <div class="muted-0-374 content-0-1069">{{item.client.name}}</div>
                </div>
                <div class="root-0-1067">
                  <div class="title-0-1068">Кредитный баланс</div>
                  <div class="primary-0-375 content-0-1069">
                    <span style="text-align: right;">
                      <span class="">0.00 UZS</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="item.id && info == 'images'" style="background: #fff; width: 100% !important; padding: 10px">
        <div class="order-photos">
          <template>
            <div class="clearfix">
              <a-upload
                :action="$store.state.urlBackend + '/api/employee/orders/image/order/' + item.id"
                :headers='{"Authorization": `Bearer ` + access_token}'
                list-type="picture-card"
                :file-list="fileListOrder"
                @preview="handlePreviewOrder"
                @change="handleChangeOrder"
                :remove="deleteImageOrder"
              >
                <div v-if="fileList.length < 8">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    Загрузить
                  </div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisibleOrder" :footer="null" @cancel="handleCancelOrder" class="img-preview">
                <img alt="example" style="width: 100%" :src="previewImageOrder" />
              </a-modal>
            </div>
          </template>
        </div>
      </div>

      <div v-if="item.id && info == 'signature'" style="background: #fff; width: 100% !important; padding: 10px">
        <div class="order-photos">
          <template>
            <div class="clearfix">
              <a-upload
                :action="$store.state.urlBackend + '/api/employee/orders/image/order/confirm-delivery/' + item.id"
                :headers='{"Authorization": `Bearer ` + access_token}'
                list-type="picture-card"
                :file-list="confirmListOrder"
                @preview="handlePreviewOrder"
                @change="handleChangeOrderConfirm"
              >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  Загрузить
                </div>
              </div>
              </a-upload>
              <a-modal :visible="previewVisibleOrder" :footer="null" @cancel="handleCancelOrder" class="img-preview">
                <img alt="example" style="width: 100%" :src="previewImageOrder" />
              </a-modal>
            </div>
          </template>
        </div>
      </div>

      <div v-if="item.id && info == 'qrcode'" style="background: #fff; width: 100% !important; padding: 10px; display: flex; justify-content: center">
        <div class="qrcode">
          <template>
            <VueQrcode :value="item.id.toString()" />
          </template>
        </div>
      </div>

      <div v-if="item.history && info == 'history'" class="" style="background: #fff; width: 100% !important; padding: 10px">
        <div style="color: rgba(0, 0, 0, 0.87); background-color: rgb(255, 255, 255); transition: all 450ms cubic-bezier(0.23, 1, 0.1, 1) 0ms; box-sizing: border-box; font-family: Roboto, sans-serif; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; border-radius: 2px; z-index: 1;">
          <div style="padding-bottom: 8px;">
            <div style="padding: 16px; font-size: 14px; color: rgba(0, 0, 0, 0.87);">

              <div class="root-0-1067 mb-3" v-for="history in item.history">
                <div class="title-0-1068">{{createdAt(history.created_at)}}</div>
                <div style="width: 100%;" class="" v-if="history.creator"> <span style="width: 300px !important; color: #F7721E">Кем обновлен:</span>  <span style="color: #0A9D0C">{{history.creator.name}}</span> </div>
                <div style="width: 100%;" class="" v-if="history.status"> <span style="width: 300px !important; color: #F7721E">Статус:</span>  <span style="color: #0A9D0C">{{history.status.title}}</span> </div>
                <div style="width: 100%;" class="" v-if="history.driver"> <span style="width: 300px !important; color: #F7721E">Водитель:</span>  <span style="color: #0A9D0C">{{history.driver.name}}</span> </div>
                <div style="width: 100%;" class="" v-if="history.branch"> <span style="width: 300px !important; color: #F7721E">Офис:</span>  <span style="color: #0A9D0C">{{history.branch.title}}</span> </div>
                <div style="width: 100%;" class="" v-if="history.reason"> <span style="width: 300px !important; color: #F7721E">Офис:</span>  <span style="color: #0A9D0C">{{history.reason}}</span> </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div v-if="item.change_history && info == 'change_history'" class="" style="background: #fff; width: 100% !important; padding: 10px">
        <div style="color: rgba(0, 0, 0, 0.87); background-color: rgb(255, 255, 255); transition: all 450ms cubic-bezier(0.23, 1, 0.1, 1) 0ms; box-sizing: border-box; font-family: Roboto, sans-serif; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; border-radius: 2px; z-index: 1;">
          <div style="padding-bottom: 8px;">
            <div style="padding: 16px; font-size: 14px; color: rgba(0, 0, 0, 0.87);">

              <div class="root-0-1067 mb-3" v-for="history in item.change_history">
                <div class="title-0-1068">{{createdAt(history.created_at)}}</div>
                <div style="width: 100%;" class="" v-if="history.user"> <span style="width: 300px !important; color: #F7721E">Кем изменен:</span>  <span style="color: #0A9D0C">{{history.user.name}}</span> </div>
                <div style="width: 100%;" class=""> <span style="width: 300px !important; color: #F7721E">Что изменен:</span>  <span style="color: #0A9D0C">{{history.title}}</span> </div>
                <div style="width: 100%;" class=""> <span style="width: 300px !important; color: #F7721E">Старое значение:</span>  <span style="color: #0A9D0C">{{history.old_value}}</span> </div>
                <div style="width: 100%;" class=""> <span style="width: 300px !important; color: #F7721E">Новое значение:</span>  <span style="color: #0A9D0C">{{history.new_value}}</span> </div>
              </div>

            </div>
          </div>
        </div>
      </div> -->

      <a-modal v-model="visibleMap" title="Карта навигации" class="map__modal">
        <gmap-map
          :path="snappedPoints[0]"
          :center="snappedPoints[0]"
          :zoom="10"
          style="width:100%;  height: 450px;"
          >
          <gmap-marker
            :position="snappedPoints[0]"
            :center="snappedPoints[0]"
          ></gmap-marker>
          <gmap-marker
            :position="snappedPoints[1]"
            :center="snappedPoints[1]"
          ></gmap-marker>
          <DirectionsRenderer :travelMode="driving" :origin="snappedPoints[0]" :destination="snappedPoints[1]"/>
        </gmap-map>
      </a-modal>
    </a-modal>

    <!-- Create Modal -->
    <a-modal v-model="visibleCreateModal" title="Создать заказ"  footer="" class="order-create">

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row">
          <div class="col-lg-3">
            <label for="">Клиент <span style="color: #e55353">*</span></label>
            <!-- <a-select
              show-search
              placeholder="Выберите страну"
              option-filter-prop="children"
              style="width: 100%"
              v-model="newItem.sender_country"
              :allowClear="true"
              :filter-option="filterOption"
              @search="searchSenderCountries"
              @change="changeSenderCountries"
              :class="{'is-invalid': validateErrors.sender_country}"
            >
              <a-select-option v-for="country in senderCountries" :key="country.id" :value="country.id" :label="country.title">
                {{country.title}}
              </a-select-option>
            </a-select> -->
            <a-select
                show-search
                placeholder="Выберите клиента"
                style="width: 100%"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                v-model="newItem.client_id"
                :class="{'is-invalid': validateErrors.client_id}"
              >
              <a-select-option v-for="client in clients" :key="client.id" :value="client.id" :label="client.name">
                {{client.name}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.client_id"> {{value}} </div>
          </div>
          <div class="col-lg-3">
            <label for="">От офиса</label>

            <a-select
                :disabled="me.roles[0].name === 'operation'"
                placeholder="Выберите офиса"
                style="width: 100%"
                optionLabelProp="label"
                optionFilterProp="label"
                @change="selectBranchOffice"
                :allowClear="true"
                v-model="newItem.branchoffice_from"
                :class="{'is-invalid': validateErrors.branchoffice_from}"
              >
              <a-select-option v-for="branch in branches" :key="branch.id" :value="branch.id" :label="branch.title">
                <span v-if="branch.country_id!=192" style="color: green">{{branch.title}} (Международный)</span> <span v-else>{{branch.title}}</span>
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.branchoffice_from"> {{value}} </div>
          </div>
          <div class="col-lg-3">
            <label for="">К офису</label>
            <a-select
                placeholder="Выберите офиса"
                style="width: 100%"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                v-model="newItem.branchoffice_to"
                :class="{'is-invalid': validateErrors.branchoffice_to}"
              >
              <a-select-option v-for="branch in branches" :key="branch.id" :value="branch.id" :label="branch.title">
                <span v-if="branch.country_id!=192" style="color: green">{{branch.title}} (Международный)</span> <span v-else>{{branch.title}}</span>
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.branchoffice_to"> {{value}} </div>
          </div>
          <div class="col-lg-3">
            <label for="">Водитель</label>
            <a-select
                show-search
                placeholder="Выберите водителя"
                style="width: 100%"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                v-model="newItem.driver_id"
                :class="{'is-invalid': validateErrors.driver_id}"
              >
              <a-select-option v-for="driver in drivers" :key="driver.id" :value="driver.id" :label="driver.name">
                {{driver.name}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.driver_id"> {{value}} </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row">
          <div class="col-lg-6">
            <div class="row">
             <div class="col-lg-12 mt-4">
               <a-radio-group v-model="newItem.service_id" @change="changeServiceTypeOrder">
                 <a-radio :value="1">
                   Обычный
                 </a-radio>
                 <a-radio :value="2">
                   Отмененный
                 </a-radio>
                 <a-radio :value="3">
                   Бумеранг
                 </a-radio>
               </a-radio-group>
             </div>
           </div>
          </div>
          <div class="col-lg-6">
            <label for="">Статус</label>
            <a-select
                placeholder="Выберите статуса"
                style="width: 100%"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                v-model="newItem.status_id"
                :class="{'is-invalid': validateErrors.status_id}"
              >
              <a-select-option v-for="status in statuses" v-if="status.parent_id == newItem.service_id" :key="status.id" :value="status.id" :label="(status.parent.title)+ ' ' +status.title">
                <span v-if="status.parent.id == 1" style="color: blue">({{status.parent.title}})</span>
                <span v-else-if="status.parent.id == 2" style="color: red">({{status.parent.title}})</span>
                <span v-else="status.parent.id" style="color: green">({{status.parent.title}})</span>
                {{status.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.status_id"> {{value}} </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <label for="">Ссылочный идентификатор</label>
        <template>
          <a-input placeholder="Ссылочный идентификатор" v-model="newItem.link_id" :class="{'is-invalid': validateErrors.link_id}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.link_id"> {{value}} </div>
        </template>
      </div>

      <div class="col-lg-12 mb-2">
        <div class="row justify-content-between">
          <div class="block-shadow" style="width: 49%">
            <div class="mb-3 d-flex justify-content-between">
              <button class="btn btn-info" @click="function(){visibleSenderAddresses = !visibleSenderAddresses}">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
                  </svg>
                </div>
                <!-- Адресная книга -->
              </button>
              <button class="btn btn-info"  @click="function(){visibleSenderMap = !visibleSenderMap}">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-map" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"/>
                    <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
                  </svg>
                </div>
                 <!-- Карта -->
              </button>
            </div>
            <div class="mb-3">
              <label for="">Имя отправителя <span style="color: #e55353">*</span></label>
              <template>
                <a-input placeholder="Имя отправителя" v-model="newItem.sender_name" :class="{'is-invalid': validateErrors.sender_name}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_name"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Страна отправителя <span style="color: #e55353">*</span></label>
              <a-select
                show-search
                placeholder="Выберите страну"
                option-filter-prop="children"
                style="width: 100%"
                v-model="newItem.sender_country"
                :allowClear="true"
                :filter-option="filterOption"
                @search="searchSenderCountries"
                @change="changeSenderCountries"
                :class="{'is-invalid': validateErrors.sender_country}"
              >
                <a-select-option v-for="country in senderCountries" :key="country.id" :value="country.id" :label="country.title">
                  {{country.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.sender_country"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Область отправителя <span style="color: #e55353">*</span></label>
              <a-select
                show-search
                placeholder="Выберите область"
                option-filter-prop="children"
                style="width: 100%"
                v-model="newItem.sender_region"
                :allowClear="true"
                :filter-option="filterOption"
                @search="searchSenderRegions"
                @change="changeSenderRegions"
                :class="{'is-invalid': validateErrors.sender_region}"
              >
                <a-select-option v-for="region in senderRegions" :key="region.id" :value="region.id" :label="region.title">
                  {{region.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.sender_region"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Район отправителя <span style="color: #e55353">*</span></label>
              <a-select
                show-search
                placeholder="Выберите район"
                option-filter-prop="children"
                style="width: 100%"
                v-model="newItem.sender_city"
                :allowClear="true"
                :filter-option="filterOption"
                @search="searchSenderCities"
                @change="changeSenderCities"
                :class="{'is-invalid': validateErrors.sender_city}"
              >
                <a-select-option v-for="city in senderCities" :key="city.id" :value="city.id" :label="city.title">
                  {{city.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.sender_city"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Адрес отправителя <span style="color: #e55353">*</span></label>
              <template>
                <a-input v-model="newItem.sender_address" placeholder="Адрес отправителя" :class="{'is-invalid': validateErrors.sender_address}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_address"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Тип здания <span style="color: #e55353">*</span></label>
              <a-select
                  placeholder="Тип здания"
                  style="width: 100%"
                  v-model="newItem.sender_type_of_building"
                  optionLabelProp="label"
                  optionFilterProp="label"
                  :default-value="'residential'"
                  :allowClear="true"
                  :class="{'is-invalid': validateErrors.sender_type_of_building}"
                >
                <a-select-option v-for="type in typeOfAddress" :key="type.value" :value="type.value" :label="type.title">
                  {{type.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.sender_type_of_building"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Ближайший ориентир</label>
              <template>
                <a-input placeholder="Ближайший ориентир" v-model="newItem.sender_reference_point" :class="{'is-invalid': validateErrors.sender_reference_point}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_reference_point"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Главный телефонный номер <span style="color: #e55353">*</span></label>
              <template>
                <a-auto-complete
                  v-model="newItem.sender_phone"
                  :data-source="phoneSourceSenderAddress"
                  style="width: 100%"
                  placeholder="Главный телефонный номер"
                  @select="onSelectSenderAddressByPhone"
                  @search="onSearchSenderAddressByPhone"
                  :class="{'is-invalid': validateErrors.sender_phone}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_phone"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Альтернативный тел.номер - 1</label>
              <template>
                <a-input placeholder="Альтернативный тел.номер - 1" v-model="newItem.sender_phone_1" :class="{'is-invalid': validateErrors.sender_phone_1}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_phone_1"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Альтернативный тел.номер - 2</label>
              <template>
                <a-input placeholder="Альтернативный тел.номер - 2" v-model="newItem.sender_phone_2" :class="{'is-invalid': validateErrors.sender_phone_2}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_phone_2"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Электронная почта отправителя</label>
              <template>
                <a-input placeholder="Электронная почта отправителя" v-model="newItem.sender_email" :class="{'is-invalid': validateErrors.sender_email}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_email"> {{value}} </div>
              </template>
            </div>
            <button class="btn btn-info w-100" @click="addToAddressBookSender('sender')">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                Добавить в адресную книгу
              </div>
            </button>
          </div>

          <div class="block-shadow" style="width: 49%">
            <div class="mb-3 d-flex justify-content-between">
              <button class="btn btn-info" @click="function(){visibleRecipientAddresses = ! visibleRecipientAddresses}">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
                  </svg>
                </div>
                <!-- Адресная книга -->
              </button>
              <button class="btn btn-info"  @click="function(){visibleRecipientMap = !visibleRecipientMap}">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-map" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"/>
                    <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
                  </svg>
                </div>
                 <!-- Карта -->
              </button>
            </div>
            <div class="mb-3">
              <label for="">Имя получателя <span style="color: #e55353">*</span></label>
              <template>
                <a-input placeholder="Имя получателя" v-model="newItem.recipient_name" :class="{'is-invalid': validateErrors.recipient_name}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_name"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Страна  получателя <span style="color: #e55353">*</span></label>
              <a-select
                show-search
                placeholder="Выберите страну"
                option-filter-prop="children"
                style="width: 100%"
                v-model="newItem.recipient_country"
                :allowClear="true"
                :filter-option="filterOption"
                @search="searchRecipientCountries"
                @change="changeRecipientCountries"
                :class="{'is-invalid': validateErrors.recipient_country}"
              >
                <a-select-option v-for="country in recipientCountries" :key="country.id" :value="country.id" :label="country.title">
                  {{country.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.recipient_country"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Область получателя <span style="color: #e55353">*</span></label>
              <a-select
                show-search
                placeholder="Выберите область"
                option-filter-prop="children"
                style="width: 100%"
                v-model="newItem.recipient_region"
                :allowClear="true"
                :filter-option="filterOption"
                @search="searchRecipientRegions"
                @change="changeRecipientRegions"
                :class="{'is-invalid': validateErrors.recipient_region}"
              >
                <a-select-option v-for="region in recipientRegions" :key="region.id" :value="region.id" :label="region.title">
                  {{region.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.recipient_region"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Район получателя <span style="color: #e55353">*</span></label>
              <a-select
                show-search
                placeholder="Выберите район"
                option-filter-prop="children"
                style="width: 100%"
                v-model="newItem.recipient_city"
                :allowClear="true"
                :filter-option="filterOption"
                @search="searchRecipientCities"
                @change="changeRecipientCities"
                :class="{'is-invalid': validateErrors.recipient_city}"
              >
                <a-select-option v-for="city in recipientCities" :key="city.id" :value="city.id" :label="city.title">
                  {{city.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.recipient_city"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Адрес получателя <span style="color: #e55353">*</span></label>
              <template>
                <a-input v-model="newItem.recipient_address" placeholder="Адрес получателя" :class="{'is-invalid': validateErrors.recipient_address}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_address"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Тип здания <span style="color: #e55353">*</span></label>
              <a-select
                  placeholder="Тип здания"
                  style="width: 100%"
                  v-model="newItem.recipient_type_of_building"
                  optionLabelProp="label"
                  optionFilterProp="label"
                  :default-value="'residential'"
                  :allowClear="true"
                  :class="{'is-invalid': validateErrors.recipient_type_of_building}"
                >
                <a-select-option v-for="type in typeOfAddress" :key="type.value" :value="type.value" :label="type.title">
                  {{type.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.recipient_type_of_building"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Ближайший ориентир</label>
              <template>
                <a-input placeholder="Ближайший ориентир" v-model="newItem.recipient_reference_point" :class="{'is-invalid': validateErrors.recipient_reference_point}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_reference_point"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Главный телефонный номер <span style="color: #e55353">*</span></label>
              <template>
                <a-auto-complete
                  v-model="newItem.recipient_phone"
                  :data-source="phoneSourceRecipientAddress"
                  style="width: 100%"
                  placeholder="Главный телефонный номер"
                  @select="onSelectRecipientAddressByPhone"
                  @search="onSearchRecipientAddressByPhone"
                  :class="{'is-invalid': validateErrors.recipient_phone}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_phone"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Альтернативный тел.номер - 1</label>
              <template>
                <a-input placeholder="Альтернативный тел.номер - 1" v-model="newItem.recipient_phone_1" :class="{'is-invalid': validateErrors.recipient_phone_1}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_phone_1"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Альтернативный тел.номер - 2</label>
              <template>
                <a-input placeholder="Альтернативный тел.номер - 2" v-model="newItem.recipient_phone_2" :class="{'is-invalid': validateErrors.recipient_phone_2}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_phone_2"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Электронная почта получателя</label>
              <template>
                <a-input placeholder="Электронная почта получателя" v-model="newItem.recipient_email" :class="{'is-invalid': validateErrors.recipient_email}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_email"> {{value}} </div>
              </template>
            </div>
            <button class="btn btn-info w-100" @click="addToAddressBookRecipient('recipient')">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                Добавить в адресную книгу
              </div>
            </button>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row mb-2">
          <div class="col-lg-4">
            <label for="">Ширина (см) <span style="color: #e55353">*</span></label>
            <template>
              <a-input placeholder="Ширина (см)" v-model="newItem.width" :class="{'is-invalid': validateErrors.width}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.width"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-4">
            <label for="">Длина (см) <span style="color: #e55353">*</span></label>
            <template>
              <a-input placeholder="Длина (см)" v-model="newItem.length" :class="{'is-invalid': validateErrors.length}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.length"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-4">
            <label for="">Высота (см) <span style="color: #e55353">*</span></label>
            <template>
              <a-input placeholder="Высота (см)" v-model="newItem.height" min="1" :class="{'is-invalid': validateErrors.height}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.height"> {{value}} </div>
            </template>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <label for="">Ед.измерения <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Ед.измерения"
                style="width: 100%"
                v-model="newItem.unit_id"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.unit_id}"
              >
              <a-select-option v-for="unit in units" :key="unit.id" :value="unit.id" :label="unit.title">
                {{unit.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.unit_id"> {{value}} </div>
          </div>
          <div class="col-lg-4">
            <label for="">Вес (кг) <span style="color: #e55353">*</span></label>
            <template>
              <a-input placeholder="Вес (кг)" v-model="newItem.weight" :class="{'is-invalid': validateErrors.weight}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.weight"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-4">
            <label for="">Объемный вес (кг) <span style="color: #e55353">*</span></label>
            <template>
              <a-input placeholder="Объемный вес (кг)" disabled v-model="newItem.volume_weight" :class="{'is-invalid': validateErrors.volume_weight}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.volume_weight"> {{value}} </div>
            </template>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row">
          <div class="col-lg-3">
            <label for="">Что за груз <span style="color: #e55353">*</span></label>
            <template>
              <a-select :allowClear="true" style="width: 100%" :token-separators="[',']" v-model="newItem.title" :class="{'is-invalid': validateErrors.title}" @change="changeOrderTitle">
                <a-select-option v-for="item in typeOfCargo" :key="item.title">{{item.title}}</a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
              <!-- <div v-for="(value, index) in newItem.cargo">
                <div style="color: red; font-size: 12px" v-for="value in validateErrors['cargo.' + index]"> {{value}} </div>
              </div> -->
            </template>
          </div>
          <div class="col-lg-3">
            <label for="">Хрупкое? <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Хрупкое?"
                style="width: 100%"
                v-model="newItem.is_fragile"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.is_fragile}"
              >
              <a-select-option v-for="item in fragile" :key="item.value" :value="item.value" :label="item.title">
                {{item.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.is_fragile"> {{value}} </div>
          </div>
          <div class="col-lg-3">
            <label for="">Стоимость вещей</label>
            <template>
              <a-input v-model="newItem.cost_of_things" placeholder="Стоимость вещей" :class="{'is-invalid': validateErrors.cost_of_things}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.cost_of_things"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-3">
            <label for="">Количество частей</label>
            <template>
              <a-input v-model="newItem.count_of_parts" placeholder="Количество частей" :class="{'is-invalid': validateErrors.count_of_parts}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.count_of_parts"> {{value}} </div>
            </template>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row mb-2">
          <div class="col-lg-3">
            <label for="">Тип услуги <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Тип услуги"
                style="width: 100%"
                v-model="newItem.type_service_id"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.type_service_id}"
                @change="changeServiceOrder"
              >
              <a-select-option v-for="item in typeOfService" :key="item.id" :value="item.id" :label="item.title">
                {{item.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.type_service_id"> {{value}} </div>
          </div>
          <div class="col-lg-3">
            <label for="">Тариф <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Тариф"
                style="width: 100%"
                v-model="newItem.tariff_id"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                @change="changeTariff"
                :class="{'is-invalid': validateErrors.tariff_id}"
              >
              <a-select-option v-for="tariff in tariffs" :key="tariff.id" :value="tariff.id" :label="tariff.title">
                {{tariff.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.tariff_id"> {{value}} </div>
          </div>
          <div class="col-lg-2">
            <label for="">Цена тарифа  UZS<span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Цена тарифа"
                style="width: 100%"
                v-model="newItem.tariff_price"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.tariff_price}"
                @change="changeTariffPrice"
              >
              <a-select-option v-for="price in tariffPrice" :key="price.price" :value="price.price" :label="price.price">
                {{price.price}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.tariff_price"> {{value}} </div>
          </div>
          <div class="col-lg-2">
            <label for="">НДС? <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="НДС"
                style="width: 100%"
                v-model="newItem.vat"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.vat}"
                @change="changeTariffPrice"
              >
              <a-select-option v-for="item in vat" :key="item.value" :value="item.value" :label="item.title">
                {{item.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.vat"> {{value}} </div>
          </div>
          <div class="col-lg-2">
            <label for="">НДС <span style="color: #e55353" v-if="newItem.vat == 'vat_15'">15%</span> <span style="color: #e55353" v-else-if="newItem.vat == 'vat_12'">12%</span> <span style="color: #e55353" v-else-if="newItem.vat == 'vat_0'">0%</span> <span style="color: #e55353" v-else>Без НДС</span></label>
            <template>
              <a-input placeholder="НДС" v-model="newItem.cost_of_service_vat" disabled :class="{'is-invalid': validateErrors.cost_of_service_vat}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.cost_of_service_vat"> {{value}} </div>
            </template>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-lg-2">
            <label for="">Промокод </label>
            <template>
              <a-input v-model="newItem.code" placeholder="Промокод" :class="{'is-invalid': validateErrors.code}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.code"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-2">
            <label for="">Проверка</label>
            <span class="btn btn-primary" @click="checkPromoCode" style="width: 100%">Проверить</span>
          </div>
          <div class="col-lg-2">
            <label for="">Скидка <span v-if="this.promocode.type=='free_order'">Бесплатный заказ</span> <span v-else-if="this.promocode.type=='percentage_discount'">{{this.promocode.value}} %</span> <span v-else-if="this.promocode.type=='absolute_discount'">{{this.promocode.value}} uzs</span> </label>
            <template>
              <a-input disabled v-model="newItem.discount" placeholder="Скидка" :class="{'is-invalid': validateErrors.discount}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.discount"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-3">
            <label for="">Стоимость услуги <span style="color: #e55353">*</span></label>
            <template>
              <a-input disabled v-model="newItem.cost_of_service" placeholder="Стоимость услуги (без НДС)" :class="{'is-invalid': validateErrors.cost_of_service}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.cost_of_service"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-3">
            <label for="">Стоимость услуги (с НДС) <span style="color: #e55353">*</span></label>
            <template>
              <a-input disabled v-model="newItem.cost_of_service_with_vat" placeholder="Стоимость услуги (с НДС)" :class="{'is-invalid': validateErrors.cost_of_service_with_vat}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.cost_of_service_with_vat"> {{value}} </div>
            </template>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-lg-2">
            <label for="">Телеграм Чат ID </label>
            <template>
              <a-input v-model="newItem.telegram_chat_id" placeholder="Промокод" :class="{'is-invalid': validateErrors.telegram_chat_id}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.telegram_chat_id"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-2">
            <label for="">Проверка</label>
            <span class="btn btn-primary" @click="getCashBack" style="width: 100%">Проверить</span>
          </div>
          <div class="col-lg-2">
            <label for="">Баланс </label>
            <template>
              <a-input disabled v-model="newItem.cashback_balans" placeholder="Баланс" :class="{'is-invalid': validateErrors.cashback_balans}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.cashback_balans"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-3">
            <label for="">Разрешенная сумма <span style="color: #e55353">*</span></label>
            <template>
              <a-input disabled v-model="newItem.allowed_cashback_balans" placeholder="Разрешенная сумма" :class="{'is-invalid': validateErrors.allowed_cashback_balans}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.allowed_cashback_balans"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-3">
            <label for="">Использовать <span style="color: #e55353">*</span></label>
            <template>
              <a-input v-model="newItem.cashback" placeholder="Использовать" :class="{'is-invalid': validateErrors.cashback}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.cashback"> {{value}} </div>
            </template>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row mb-2">
          <div class="col-lg-4">
            <label for="">Способ оплаты <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Способ оплаты"
                style="width: 100%"
                v-model="newItem.payment_method"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.payment_method}"
              >
              <a-select-option v-for="method in paymentMethod" :key="method.value" :value="method.value" :label="method.title">
                {{method.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.payment_method"> {{value}} </div>
          </div>
          <div class="col-lg-4">
            <label for="">Доставку оплачивает</label>
            <a-select
                placeholder="Доставку оплачивает"
                style="width: 100%"
                v-model="newItem.who_pays"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.who_pays}"
              >
              <a-select-option v-for="person in payer" :key="person.value" :value="person.value" :label="person.title">
                {{person.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.who_pays"> {{value}} </div>
          </div>
          <div class="col-lg-4">
            <label for="">Если получатель не доступен</label>
            <a-select
                placeholder="Если получатель не доступен"
                style="width: 100%"
                v-model="newItem.recipient_is_not_available"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.recipient_is_not_available}"
              >
              <a-select-option v-for="item in ifRecipientNotAvialable" :key="item.value" :value="item.value" :label="item.title">
                {{item.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.recipient_is_not_available"> {{value}} </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row mb-2">
          <div class="col-lg-4">
            <label for="">Поставщик <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Поставщик"
                style="width: 100%"
                v-model="newItem.provider_id"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.provider_id}"
              >
              <a-select-option v-for="provider in providers" :key="provider.id" :value="provider.id" :label="provider.client_name">
                {{provider.client_name}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.provider_id"> {{value}} </div>
          </div>
          <div class="col-lg-4">
            <label for="">Цена поставщика</label>
            <a-input v-model="newItem.provider_price" placeholder="Цена поставщика" :class="{'is-invalid': validateErrors.provider_price}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.provider_price"> {{value}} </div>
          </div>
          <div class="col-lg-4">
            <label for="">Номер поставщика</label>
            <a-input v-model="newItem.provider_phone" placeholder="Номер поставщика" :class="{'is-invalid': validateErrors.provider_phone}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.provider_phone"> {{value}} </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <label for="">Номер cse</label>
            <a-input v-model="newItem.cse_phone" placeholder="Номер cse" :class="{'is-invalid': validateErrors.cse_phone}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.cse_phone"> {{value}} </div>
          </div>
          <div class="col-lg-6">
            <label for="">Трекинг код</label>
            <a-input v-model="newItem.tracking_code" placeholder="Трекинг код" :class="{'is-invalid': validateErrors.tracking_code}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.tracking_code"> {{value}} </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">

        <div class="row mb-2">
          <div class="col-lg-3">
            <label for="">Статус оплаты услуги</label>
            <a-select
                placeholder="Статус оплаты услуги"
                style="width: 100%"
                v-model="newItem.status_of_payment"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.status_of_payment}"
              >
              <a-select-option v-for="status in codStatus" :key="status.value" :value="status.value" :label="status.title">
                {{status.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.status_of_payment"> {{value}} </div>
          </div>
          <div class="col-lg-3">
            <label for="">Наложенный платеж</label>
            <template>
              <a-input v-model="newItem.cod" placeholder="Наложенный платеж" :class="{'is-invalid': validateErrors.cod}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.cod"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-3">
            <label for="">Статус нал.платежа</label>
            <a-select
                placeholder="Статус нал.платежа"
                style="width: 100%"
                v-model="newItem.cod_status"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.cod_status}"
              >
              <a-select-option v-for="status in codStatus" :key="status.value" :value="status.value" :label="status.title">
                {{status.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.cod_status"> {{value}} </div>
          </div>
        </div>

        <div>
          <label for="">Заметки</label>
          <template>
            <a-textarea v-model="newItem.notes" placeholder="Заметки" :class="{'is-invalid': validateErrors.notes}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.notes"> {{value}} </div>
          </template>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <template>
          <div class="clearfix">
            <a-upload
              :action="$store.state.urlBackend + '/api/employee/orders/image'"
              :headers='{"Authorization": `Bearer ` + access_token}'
              list-type="picture-card"
              :file-list="fileList"
              @preview="handlePreview"
              @change="handleChange"
              :remove="deleteImage"
            >
              <div v-if="fileList.length < 8">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  Загрузить
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" class="img-preview">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </template>
      </div>

      <a-modal v-model="visibleSenderMap" title="Геолокация отправителя" class="map__modal" @ok="function(){visibleSenderMap = !visibleSenderMap}">
        <gmap-map
          :center="senderCenter"
          :zoom="10"
          style="width:100%;  height: 450px;"
          @click="addSenderMarker"
          >
          <gmap-marker
            :position="senderLocation"
            @click="senderCenter=senderLocation"
          ></gmap-marker>
        </gmap-map>
        <div class="lat-long-block">
          <template>
            <a-input placeholder="Долгота и широта" v-model="newItem.sender_point"
            />
          </template>
        </div>
      </a-modal>

      <a-modal v-model="visibleRecipientMap" title="Геолокация получателя" class="map__modal" @ok="function(){visibleRecipientMap = !visibleRecipientMap}">
        <gmap-map
          :center="recipientCenter"
          :zoom="10"
          style="width:100%;  height: 450px;"
          @click="addRecipientMarker"
          >
          <gmap-marker
            :position="recipientLocation"
            @click="recipientCenter=recipientLocation"
          ></gmap-marker>
        </gmap-map>
        <div class="lat-long-block">
          <template>
            <a-input placeholder="Долгота и широта" v-model="newItem.recipient_point"
            />
          </template>
        </div>
      </a-modal>

      <a-modal v-model="visibleSenderAddresses" title="Адресная книга" @ok="function(){visibleSenderAddresses = !visibleSenderAddresses}">
        <div class="mb-3">
          <label for="">Выберите из адресной книги <span style="color: #e55353">*</span></label>
          <a-select
            show-search
            placeholder="Выберите адреса"
            option-filter-prop="children"
            style="width: 100%"
            :allowClear="true"
            v-model="senderA"
            :filter-option="filterOption"
            @search="searchSenderAddresses"
            @change="changeSenderAddresses"
          >
            <a-select-option v-for="address in addressesForSender" :key="address.id" :value="address.id" :label="address.name">
              {{address.name}}
            </a-select-option>
          </a-select>
        </div>
      </a-modal>

      <a-modal v-model="visibleRecipientAddresses" title="Адресная книга" @ok="function(){visibleRecipientAddresses = !visibleRecipientAddresses}">
        <div class="mb-3">
          <label for="">Выберите из адресной книги <span style="color: #e55353">*</span></label>
          <a-select
            show-search
            placeholder="Выберите адреса"
            option-filter-prop="children"
            style="width: 100%"
            :allowClear="true"
            v-model="recipientA"
            :filter-option="filterOption"
            @search="searchRecipientAddresses"
            @change="changeRecipientAddresses"
          >
            <a-select-option v-for="address in addressesForRecipient" :key="address.id" :value="address.id" :label="address.name">
              {{address.name}}
            </a-select-option>
          </a-select>
        </div>
      </a-modal>
      <div class="d-flex mt-3">
        <button type="submit" class="ml-auto btn btn-primary" @click="storeItem">Добавить</button>
      </div>
      <div class="d-flex mt-3">
        <button type="submit" class="ml-auto btn btn-primary" @click="storeItemWithoutClear" v-if="me.roles[0].name == 'admin' || me.roles[0].name == 'moderator'">Добавить без очищения </button>
      </div>
    </a-modal>

    <!-- Edit Modal -->
    <a-modal v-model="visibleEditModal" title="Изменить заказ"  footer="" class="order-create">

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row">
          <div class="col-lg-3">
            <label for="">Клиент <span style="color: #e55353">*</span></label>
            <a-select
                show-search
                placeholder="Выберите клиента"
                style="width: 100%"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                v-model="oldItem.client_id"
                :class="{'is-invalid': validateErrors.client_id}"
              >
              <a-select-option v-for="client in clients" :key="client.id" :value="client.id" :label="client.name">
                {{client.name}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.client_id"> {{value}} </div>
          </div>
          <div class="col-lg-3">
            <label for="">От офиса</label>

            <a-select
                :disabled="me.roles[0].name === 'operation'"
                placeholder="Выберите офиса"
                style="width: 100%"
                optionLabelProp="label"
                optionFilterProp="label"
                @change="selectBranchOffice"
                :allowClear="true"
                v-model="oldItem.branchoffice_from"
                :class="{'is-invalid': validateErrors.branchoffice_from}"
              >
              <a-select-option v-for="branch in branches" :key="branch.id" :value="branch.id" :label="branch.title">
                <span v-if="branch.country_id!=192" style="color: green">{{branch.title}} (Международный)</span> <span v-else>{{branch.title}}</span>
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.branchoffice_from"> {{value}} </div>
          </div>
          <div class="col-lg-3">
            <label for="">К офису</label>
            <a-select
                placeholder="Выберите офиса"
                style="width: 100%"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                v-model="oldItem.branchoffice_to"
                :class="{'is-invalid': validateErrors.branchoffice_to}"
              >
              <a-select-option v-for="branch in branches" :key="branch.id" :value="branch.id" :label="branch.title">
                <span v-if="branch.country_id!=192" style="color: green">{{branch.title}} (Международный)</span> <span v-else>{{branch.title}}</span>
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.branchoffice_to"> {{value}} </div>
          </div>
          <div class="col-lg-3">
            <label for="">Водитель</label>
            <a-select
                show-search
                placeholder="Выберите водителя"
                style="width: 100%"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                v-model="oldItem.driver_id"
                :class="{'is-invalid': validateErrors.driver_id}"
              >
              <a-select-option v-for="driver in drivers" :key="driver.id" :value="driver.id" :label="driver.name">
                {{driver.name}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.driver_id"> {{value}} </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row">
          <div class="col-lg-6">
            <div class="row">
             <div class="col-lg-12 mt-4">
               <a-radio-group v-model="oldItem.service_id" @change="changeServiceTypeOrderInEdit">
                 <a-radio :value="1">
                   Обычный
                 </a-radio>
                 <a-radio :value="2">
                   Отмененный
                 </a-radio>
                 <a-radio :value="3">
                   Бумеранг
                 </a-radio>
               </a-radio-group>
             </div>
           </div>
          </div>
          <div class="col-lg-6">
            <label for="">Статус</label>
            <a-select
                placeholder="Выберите статуса"
                style="width: 100%"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                v-model="oldItem.status_id"
                :class="{'is-invalid': validateErrors.status_id}"
              >
              <a-select-option v-for="status in statuses" v-if="status.parent_id == oldItem.service_id" :key="status.id" :value="status.id" :label="(status.parent.title)+ ' ' +status.title">
                <span v-if="status.parent.id == 1" style="color: blue">({{status.parent.title}})</span>
                <span v-else-if="status.parent.id == 2" style="color: red">({{status.parent.title}})</span>
                <span v-else="status.parent.id" style="color: green">({{status.parent.title}})</span>
                {{status.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.status_id"> {{value}} </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <label for="">Ссылочный идентификатор</label>
        <template>
          <a-input placeholder="Ссылочный идентификатор" v-model="oldItem.link_id" :class="{'is-invalid': validateErrors.link_id}"
          />
          <div class="invalid-feedback" v-for="value in validateErrors.link_id"> {{value}} </div>
        </template>
      </div>

      <div class="col-lg-12 mb-2">
        <div class="row justify-content-between">
          <div class="block-shadow" style="width: 49%">
            <div class="mb-3 d-flex justify-content-between">
              <button class="btn btn-info" @click="function(){visibleSenderAddresses = !visibleSenderAddresses}">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
                  </svg>
                </div>
                <!-- Адресная книга -->
              </button>
              <button class="btn btn-info"  @click="function(){visibleSenderMap = !visibleSenderMap}">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-map" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"/>
                    <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
                  </svg>
                </div>
                 <!-- Карта -->
              </button>
            </div>
            <div class="mb-3">
              <label for="">Имя отправителя <span style="color: #e55353">*</span></label>
              <template>
                <a-input placeholder="Имя отправителя" v-model="oldItem.sender_name" :class="{'is-invalid': validateErrors.sender_name}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_name"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Страна отправителя <span style="color: #e55353">*</span></label>
              <a-select
                show-search
                placeholder="Выберите страну"
                option-filter-prop="children"
                style="width: 100%"
                v-model="oldItem.sender_country"
                :allowClear="true"
                :filter-option="filterOption"
                @search="searchSenderCountries"
                @change="changeSenderCountries"
                :class="{'is-invalid': validateErrors.sender_country}"
              >
                <a-select-option v-for="country in senderCountries" :key="country.id" :value="country.id" :label="country.title">
                  {{country.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.sender_country"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Область отправителя <span style="color: #e55353">*</span></label>
              <a-select
                show-search
                placeholder="Выберите область"
                option-filter-prop="children"
                style="width: 100%"
                v-model="oldItem.sender_region"
                :allowClear="true"
                :filter-option="filterOption"
                @search="searchSenderRegions"
                @change="changeSenderRegions"
                :class="{'is-invalid': validateErrors.sender_region}"
              >
                <a-select-option v-for="region in senderRegions" :key="region.id" :value="region.id" :label="region.title">
                  {{region.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.sender_region"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Район отправителя <span style="color: #e55353">*</span></label>
              <a-select
                show-search
                placeholder="Выберите район"
                option-filter-prop="children"
                style="width: 100%"
                v-model="oldItem.sender_city"
                :allowClear="true"
                :filter-option="filterOption"
                @search="searchSenderCities"
                @change="changeSenderCities"
                :class="{'is-invalid': validateErrors.sender_city}"
              >
                <a-select-option v-for="city in senderCities" :key="city.id" :value="city.id" :label="city.title">
                  {{city.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.sender_city"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Адрес отправителя <span style="color: #e55353">*</span></label>
              <template>
                <a-input v-model="oldItem.sender_address" placeholder="Адрес отправителя" :class="{'is-invalid': validateErrors.sender_address}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_address"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Тип здания <span style="color: #e55353">*</span></label>
              <a-select
                  placeholder="Тип здания"
                  style="width: 100%"
                  v-model="oldItem.sender_type_of_building"
                  optionLabelProp="label"
                  optionFilterProp="label"
                  :default-value="'residential'"
                  :allowClear="true"
                  :class="{'is-invalid': validateErrors.sender_type_of_building}"
                >
                <a-select-option v-for="type in typeOfAddress" :key="type.value" :value="type.value" :label="type.title">
                  {{type.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.sender_type_of_building"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Ближайший ориентир</label>
              <template>
                <a-input placeholder="Ближайший ориентир" v-model="oldItem.sender_reference_point" :class="{'is-invalid': validateErrors.sender_reference_point}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_reference_point"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Главный телефонный номер <span style="color: #e55353">*</span></label>
              <template>
                <a-auto-complete
                  v-model="oldItem.sender_phone"
                  :data-source="phoneSourceSenderAddress"
                  style="width: 100%"
                  placeholder="Главный телефонный номер"
                  @select="onSelectSenderAddressByPhone"
                  @search="onSearchSenderAddressByPhone"
                  :class="{'is-invalid': validateErrors.sender_phone}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_phone"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Альтернативный тел.номер - 1</label>
              <template>
                <a-input placeholder="Альтернативный тел.номер - 1" v-model="oldItem.sender_phone_1" :class="{'is-invalid': validateErrors.sender_phone_1}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_phone_1"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Альтернативный тел.номер - 2</label>
              <template>
                <a-input placeholder="Альтернативный тел.номер - 2" v-model="oldItem.sender_phone_2" :class="{'is-invalid': validateErrors.sender_phone_2}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_phone_2"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Электронная почта отправителя</label>
              <template>
                <a-input placeholder="Электронная почта отправителя" v-model="oldItem.sender_email" :class="{'is-invalid': validateErrors.sender_email}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.sender_email"> {{value}} </div>
              </template>
            </div>
            <button class="btn btn-info w-100" @click="addToAddressBookSender('sender')">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                Добавить в адресную книгу
              </div>
            </button>
          </div>

          <div class="block-shadow" style="width: 49%">
            <div class="mb-3 d-flex justify-content-between">
              <button class="btn btn-info" @click="function(){visibleRecipientAddresses = ! visibleRecipientAddresses}">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
                  </svg>
                </div>
                <!-- Адресная книга -->
              </button>
              <button class="btn btn-info"  @click="function(){visibleRecipientMap = !visibleRecipientMap}">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-map" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"/>
                    <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
                  </svg>
                </div>
                 <!-- Карта -->
              </button>
            </div>
            <div class="mb-3">
              <label for="">Имя получателя <span style="color: #e55353">*</span></label>
              <template>
                <a-input placeholder="Имя получателя" v-model="oldItem.recipient_name" :class="{'is-invalid': validateErrors.recipient_name}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_name"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Страна  получателя <span style="color: #e55353">*</span></label>
              <a-select
                show-search
                placeholder="Выберите страну"
                option-filter-prop="children"
                style="width: 100%"
                v-model="oldItem.recipient_country"
                :allowClear="true"
                :filter-option="filterOption"
                @search="searchRecipientCountries"
                @change="changeRecipientCountries"
                :class="{'is-invalid': validateErrors.recipient_country}"
              >
                <a-select-option v-for="country in recipientCountries" :key="country.id" :value="country.id" :label="country.title">
                  {{country.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.recipient_country"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Область получателя <span style="color: #e55353">*</span></label>
              <a-select
                show-search
                placeholder="Выберите область"
                option-filter-prop="children"
                style="width: 100%"
                v-model="oldItem.recipient_region"
                :allowClear="true"
                :filter-option="filterOption"
                @search="searchRecipientRegions"
                @change="changeRecipientRegions"
                :class="{'is-invalid': validateErrors.recipient_region}"
              >
                <a-select-option v-for="region in recipientRegions" :key="region.id" :value="region.id" :label="region.title">
                  {{region.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.recipient_region"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Район получателя <span style="color: #e55353">*</span></label>
              <a-select
                show-search
                placeholder="Выберите район"
                option-filter-prop="children"
                style="width: 100%"
                v-model="oldItem.recipient_city"
                :allowClear="true"
                :filter-option="filterOption"
                @search="searchRecipientCities"
                @change="changeRecipientCities"
                :class="{'is-invalid': validateErrors.recipient_city}"
              >
                <a-select-option v-for="city in recipientCities" :key="city.id" :value="city.id" :label="city.title">
                  {{city.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.recipient_city"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Адрес получателя <span style="color: #e55353">*</span></label>
              <template>
                <a-input v-model="oldItem.recipient_address" placeholder="Адрес получателя" :class="{'is-invalid': validateErrors.recipient_address}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_address"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Тип здания <span style="color: #e55353">*</span></label>
              <a-select
                  placeholder="Тип здания"
                  style="width: 100%"
                  v-model="oldItem.recipient_type_of_building"
                  optionLabelProp="label"
                  optionFilterProp="label"
                  :default-value="'residential'"
                  :allowClear="true"
                  :class="{'is-invalid': validateErrors.recipient_type_of_building}"
                >
                <a-select-option v-for="type in typeOfAddress" :key="type.value" :value="type.value" :label="type.title">
                  {{type.title}}
                </a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.recipient_type_of_building"> {{value}} </div>
            </div>
            <div class="mb-3">
              <label for="">Ближайший ориентир</label>
              <template>
                <a-input placeholder="Ближайший ориентир" v-model="oldItem.recipient_reference_point" :class="{'is-invalid': validateErrors.recipient_reference_point}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_reference_point"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Главный телефонный номер <span style="color: #e55353">*</span></label>
              <template>
                <a-auto-complete
                  v-model="oldItem.recipient_phone"
                  :data-source="phoneSourceRecipientAddress"
                  style="width: 100%"
                  placeholder="Главный телефонный номер"
                  @select="onSelectRecipientAddressByPhone"
                  @search="onSearchRecipientAddressByPhone"
                  :class="{'is-invalid': validateErrors.recipient_phone}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_phone"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Альтернативный тел.номер - 1</label>
              <template>
                <a-input placeholder="Альтернативный тел.номер - 1" v-model="oldItem.recipient_phone_1" :class="{'is-invalid': validateErrors.recipient_phone_1}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_phone_1"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Альтернативный тел.номер - 2</label>
              <template>
                <a-input placeholder="Альтернативный тел.номер - 2" v-model="oldItem.recipient_phone_2" :class="{'is-invalid': validateErrors.recipient_phone_2}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_phone_2"> {{value}} </div>
              </template>
            </div>
            <div class="mb-3">
              <label for="">Электронная почта получателя</label>
              <template>
                <a-input placeholder="Электронная почта получателя" v-model="oldItem.recipient_email" :class="{'is-invalid': validateErrors.recipient_email}"
                />
                <div class="invalid-feedback" v-for="value in validateErrors.recipient_email"> {{value}} </div>
              </template>
            </div>
            <button class="btn btn-info w-100" @click="addToAddressBookRecipient('recipient')">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                Добавить в адресную книгу
              </div>
            </button>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row mb-2">
          <div class="col-lg-4">
            <label for="">Ширина (см) <span style="color: #e55353">*</span></label>
            <template>
              <a-input placeholder="Ширина (см)" v-model="oldItem.width" :class="{'is-invalid': validateErrors.width}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.width"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-4">
            <label for="">Длина (см) <span style="color: #e55353">*</span></label>
            <template>
              <a-input placeholder="Длина (см)" v-model="oldItem.length" :class="{'is-invalid': validateErrors.length}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.length"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-4">
            <label for="">Высота (см) <span style="color: #e55353">*</span></label>
            <template>
              <a-input placeholder="Высота (см)" v-model="oldItem.height" min="1" :class="{'is-invalid': validateErrors.height}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.height"> {{value}} </div>
            </template>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <label for="">Ед.измерения <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Ед.измерения"
                style="width: 100%"
                v-model="oldItem.unit_id"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.unit_id}"
              >
              <a-select-option v-for="unit in units" :key="unit.id" :value="unit.id" :label="unit.title">
                {{unit.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.unit_id"> {{value}} </div>
          </div>
          <div class="col-lg-4">
            <label for="">Вес (кг) <span style="color: #e55353">*</span></label>
            <template>
              <a-input placeholder="Вес (кг)" v-model="oldItem.weight" :class="{'is-invalid': validateErrors.weight}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.weight"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-4">
            <label for="">Объемный вес (кг) <span style="color: #e55353">*</span></label>
            <template>
              <a-input placeholder="Объемный вес (кг)" disabled v-model="oldItem.volume_weight" :class="{'is-invalid': validateErrors.volume_weight}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.volume_weight"> {{value}} </div>
            </template>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row">
          <div class="col-lg-3">
            <label for="">Что за груз <span style="color: #e55353">*</span></label>
            <template>
              <a-select :allowClear="true" style="width: 100%" :token-separators="[',']" v-model="oldItem.title" :class="{'is-invalid': validateErrors.title}" @change="changeOrderTitleInEdit">
                <a-select-option v-for="item in typeOfCargo" :key="item.title">{{item.title}}</a-select-option>
              </a-select>
              <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
              <!-- <div v-for="(value, index) in newItem.cargo">
                <div style="color: red; font-size: 12px" v-for="value in validateErrors['cargo.' + index]"> {{value}} </div>
              </div> -->
            </template>
          </div>
          <div class="col-lg-3">
            <label for="">Хрупкое? <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Хрупкое?"
                style="width: 100%"
                v-model="oldItem.is_fragile"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.is_fragile}"
              >
              <a-select-option v-for="item in fragile" :key="item.value" :value="item.value" :label="item.title">
                {{item.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.is_fragile"> {{value}} </div>
          </div>
          <div class="col-lg-3">
            <label for="">Стоимость вещей</label>
            <template>
              <a-input v-model="oldItem.cost_of_things" placeholder="Стоимость вещей" :class="{'is-invalid': validateErrors.cost_of_things}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.cost_of_things"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-3">
            <label for="">Количество частей</label>
            <template>
              <a-input v-model="oldItem.count_of_parts" placeholder="Количество частей" :class="{'is-invalid': validateErrors.count_of_parts}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.count_of_parts"> {{value}} </div>
            </template>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row mb-2">
          <div class="col-lg-3">
            <label for="">Тип услуги <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Тип услуги"
                style="width: 100%"
                v-model="oldItem.type_service_id"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.type_service_id}"
                @change="changeServiceOrderInEdit"
              >
              <a-select-option v-for="item in typeOfService" :key="item.id" :value="item.id" :label="item.title">
                {{item.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.type_service_id"> {{value}} </div>
          </div>
          <div class="col-lg-3">
            <label for="">Тариф <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Тариф"
                style="width: 100%"
                v-model="oldItem.tariff_id"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                @change="changeTariffInEdit"
                :class="{'is-invalid': validateErrors.tariff_id}"
              >
              <a-select-option v-for="tariff in tariffs" :key="tariff.id" :value="tariff.id" :label="tariff.title">
                {{tariff.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.tariff_id"> {{value}} </div>
          </div>
          <div class="col-lg-2">
            <label for="">Цена тарифа  UZS<span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Цена тарифа"
                style="width: 100%"
                v-model="oldItem.tariff_price"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.tariff_price}"
                @change="changeTariffPriceInEdit"
              >
              <a-select-option v-for="price in tariffPrice" :key="price.price" :value="price.price" :label="price.price">
                {{price.price}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.tariff_price"> {{value}} </div>
          </div>
          <div class="col-lg-2">
            <label for="">НДС? <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="НДС"
                style="width: 100%"
                v-model="oldItem.vat"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.vat}"
                @change="changeTariffPriceInEdit"
              >
              <a-select-option v-for="item in vat" :key="item.value" :value="item.value" :label="item.title">
                {{item.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.vat"> {{value}} </div>
          </div>
          <div class="col-lg-2">
            <label for="">НДС <span style="color: #e55353" v-if="oldItem.vat == 'vat_15'">15%</span> <span style="color: #e55353" v-else-if="oldItem.vat == 'vat_12'">12%</span> <span style="color: #e55353" v-else-if="oldItem.vat == 'vat_0'">0%</span> <span style="color: #e55353" v-else>Без НДС</span></label>
            <template>
              <a-input placeholder="НДС" v-model="oldItem.cost_of_service_vat" disabled :class="{'is-invalid': validateErrors.cost_of_service_vat}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.cost_of_service_vat"> {{value}} </div>
            </template>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-lg-2">
            <label for="">Промокод </label>
            <template>
              <a-input v-model="oldItem.code" placeholder="Промокод" :class="{'is-invalid': validateErrors.code}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.code"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-2">
            <label for="">Проверка</label>
            <span class="btn btn-primary" @click="checkPromoCodeEditFunction" style="width: 100%">Проверить</span>
          </div>
          <div class="col-lg-2">
            <label for="">Скидка <span v-if="this.promocode.type=='free_order'">Бесплатный заказ</span> <span v-else-if="this.promocode.type=='percentage_discount'">{{this.promocode.value}} %</span> <span v-else-if="this.promocode.type=='absolute_discount'">{{this.promocode.value}} uzs</span> </label>
            <template>
              <a-input disabled v-model="oldItem.discount" placeholder="Скидка" :class="{'is-invalid': validateErrors.discount}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.discount"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-3">
            <label for="">Стоимость услуги <span style="color: #e55353">*</span></label>
            <template>
              <a-input disabled v-model="oldItem.cost_of_service" placeholder="Стоимость услуги" :class="{'is-invalid': validateErrors.cost_of_service}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.cost_of_service"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-3">
            <label for="">Стоимость услуги (с НДС) <span style="color: #e55353">*</span></label>
            <template>
              <a-input disabled v-model="oldItem.cost_of_service_with_vat" placeholder="Стоимость услуги (с НДС)" :class="{'is-invalid': validateErrors.cost_of_service_with_vat}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.cost_of_service_with_vat"> {{value}} </div>
            </template>
          </div>
        </div>
      </div>

      
      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row mb-2">
          <div class="col-lg-4">
            <label for="">Способ оплаты <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Способ оплаты"
                style="width: 100%"
                v-model="oldItem.payment_method"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.payment_method}"
              >
              <a-select-option v-for="method in paymentMethod" :key="method.value" :value="method.value" :label="method.title">
                {{method.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.payment_method"> {{value}} </div>
          </div>
          <div class="col-lg-4">
            <label for="">Доставку оплачивает</label>
            <a-select
                placeholder="Доставку оплачивает"
                style="width: 100%"
                v-model="oldItem.who_pays"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.who_pays}"
              >
              <a-select-option v-for="person in payer" :key="person.vvalue" :value="person.value" :label="person.title">
                {{person.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.who_pays"> {{value}} </div>
          </div>
          <div class="col-lg-4">
            <label for="">Если получатель не доступен</label>
            <a-select
                placeholder="Если получатель не доступен"
                style="width: 100%"
                v-model="oldItem.recipient_is_not_available"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.recipient_is_not_available}"
              >
              <a-select-option v-for="item in ifRecipientNotAvialable" :key="item.value" :value="item.value" :label="item.title">
                {{item.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.recipient_is_not_available"> {{value}} </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row mb-2">
          <div class="col-lg-4">
            <label for="">Поставщик <span style="color: #e55353">*</span></label>
            <a-select
                placeholder="Поставщик"
                style="width: 100%"
                v-model="oldItem.provider_id"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.provider_id}"
              >
              <a-select-option v-for="provider in providers" :key="provider.id" :value="provider.id" :label="provider.client_name">
                {{provider.client_name}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.provider_id"> {{value}} </div>
          </div>
          <div class="col-lg-4">
            <label for="">Цена поставщика</label>
            <a-input v-model="oldItem.provider_price" placeholder="Цена поставщика" :class="{'is-invalid': validateErrors.provider_price}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.provider_price"> {{value}} </div>
          </div>
          <div class="col-lg-4">
            <label for="">Номер поставщика</label>
            <a-input v-model="oldItem.provider_phone" placeholder="Номер поставщика" :class="{'is-invalid': validateErrors.provider_phone}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.provider_phone"> {{value}} </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <label for="">Номер CSE</label>
            <a-input v-model="oldItem.cse_phone" placeholder="Номер CSE" :class="{'is-invalid': validateErrors.cse_phone}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.cse_phone"> {{value}} </div>
          </div>
          <div class="col-lg-6">
            <label for="">Трекинг код</label>
            <a-input v-model="oldItem.tracking_code" placeholder="Трекинг код" :class="{'is-invalid': validateErrors.tracking_code}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.tracking_code"> {{value}} </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-2 block-shadow">
        <div class="row mb-2">
          <div class="col-lg-3">
            <label for="">Статус оплаты услуги</label>
            <a-select
                placeholder="Статус оплаты услуги"
                style="width: 100%"
                v-model="oldItem.status_of_payment"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.status_of_payment}"
              >
              <a-select-option v-for="status in codStatus" :key="status.value" :value="status.value" :label="status.title">
                {{status.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.status_of_payment"> {{value}} </div>
          </div>
          <div class="col-lg-3">
            <label for="">Наложенный платеж</label>
            <template>
              <a-input v-model="oldItem.cod" placeholder="Наложенный платеж" :class="{'is-invalid': validateErrors.cod}"
              />
              <div class="invalid-feedback" v-for="value in validateErrors.cod"> {{value}} </div>
            </template>
          </div>
          <div class="col-lg-3">
            <label for="">Статус нал.платежа</label>
            <a-select
                placeholder="Статус нал.платежа"
                style="width: 100%"
                v-model="oldItem.cod_status"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                :class="{'is-invalid': validateErrors.cod_status}"
              >
              <a-select-option v-for="status in codStatus" :key="status.value" :value="status.value" :label="status.title">
                {{status.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.cod_status"> {{value}} </div>
          </div>
        </div>
        <div>
          <label for="">Заметки</label>
          <template>
            <a-textarea v-model="oldItem.notes" placeholder="Заметки" :class="{'is-invalid': validateErrors.notes}"
            />
            <div class="invalid-feedback" v-for="value in validateErrors.notes"> {{value}} </div>
          </template>
        </div>
      </div>

      <!-- <div class="col-lg-12 mb-2 block-shadow">
        <template>
          <div class="clearfix">
            <a-upload
              :action="$store.state.urlBackend + '/api/employee/orders/image'"
              :headers='{"Authorization": `Bearer ` + access_token}'
              list-type="picture-card"
              :file-list="fileList"
              @preview="handlePreview"
              @change="handleChange"
              :remove="deleteImage"
            >
              <div v-if="fileList.length < 8">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  Загрузить
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" class="img-preview">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </template>
      </div> -->

      <!-- Карта -->
      <a-modal v-model="visibleSenderMap" title="Геолокация отправителя" class="map__modal" @ok="function(){visibleSenderMap = !visibleSenderMap}">
        <gmap-map
          :center="senderCenter"
          :zoom="10"
          style="width:100%;  height: 450px;"
          @click="addSenderMarker"
          >
          <gmap-marker
            :position="senderLocation"
            @click="senderCenter=senderLocation"
          ></gmap-marker>
        </gmap-map>
        <div class="lat-long-block">
          <template>
            <a-input placeholder="Долгота и широта" v-model="oldItem.sender_point"
            />
          </template>
        </div>
      </a-modal>

      <!-- Карта -->
      <a-modal v-model="visibleRecipientMap" title="Геолокация получателя" class="map__modal" @ok="function(){visibleRecipientMap = !visibleRecipientMap}">
        <gmap-map
          :center="recipientCenter"
          :zoom="10"
          style="width:100%;  height: 450px;"
          @click="addRecipientMarker"
          >
          <gmap-marker
            :position="recipientLocation"
            @click="recipientCenter=recipientLocation"
          ></gmap-marker>
        </gmap-map>
        <div class="lat-long-block">
          <template>
            <a-input placeholder="Долгота и широта" v-model="oldItem.recipient_point"
            />
          </template>
        </div>
      </a-modal>

      <!-- Адресная книга -->
      <a-modal v-model="visibleSenderAddresses" title="Адресная книга" @ok="function(){visibleSenderAddresses = !visibleSenderAddresses}">
        <div class="mb-3">
          <label for="">Выберите из адресной книги <span style="color: #e55353">*</span></label>
          <a-select
            show-search
            placeholder="Выберите адреса"
            option-filter-prop="children"
            style="width: 100%"
            :allowClear="true"
            v-model="senderA"
            :filter-option="filterOption"
            @search="searchSenderAddresses"
            @change="changeSenderAddresses"
          >
            <a-select-option v-for="address in addressesForSender" :key="address.id" :value="address.id" :label="address.name">
              {{address.name}}
            </a-select-option>
          </a-select>
        </div>
      </a-modal>

      <!-- Адресная книга -->
      <a-modal v-model="visibleRecipientAddresses" title="Адресная книга" @ok="function(){visibleRecipientAddresses = !visibleRecipientAddresses}">
        <div class="mb-3">
          <label for="">Выберите из адресной книги <span style="color: #e55353">*</span></label>
          <a-select
            show-search
            placeholder="Выберите адреса"
            option-filter-prop="children"
            style="width: 100%"
            :allowClear="true"
            v-model="recipientA"
            :filter-option="filterOption"
            @search="searchRecipientAddresses"
            @change="changeRecipientAddresses"
          >
            <a-select-option v-for="address in addressesForRecipient" :key="address.id" :value="address.id" :label="address.name">
              {{address.name}}
            </a-select-option>
          </a-select>
        </div>
      </a-modal>
      <div class="d-flex mt-3">
        <button type="submit" class="ml-auto btn btn-primary" @click="updateItem">Изменить</button>
      </div>
    </a-modal>

    <!-- Status change -->
    <a-modal v-model="visibleEditStatus" title="Массовое обновление заказов" class="order-status" footer="">
      <div class="mb-3">
        <label for="">ID заказов <span style="color: #e55353">*</span></label>
        <a-select
          mode="tags"
          placeholder="Введите id заказов"
          style="width: 100%"
          v-model="itemStatus.order_id"
          :class="{'is-invalid': validateErrors.order_id}"
        >
          <!-- <a-select-option v-for="i in 25" :key="(i + 9).toString(1) + i">
            {{ (i + 9).toString(36) + i }}
          </a-select-option> -->
        </a-select>
        <div class="invalid-feedback" v-for="value in validateErrors.order_id"> {{value}} </div>
      </div>

      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-6">
            <div class="row">
             <div class="col-lg-12 mt-4">
               <a-radio-group v-model="itemStatus.service_id" @change="" :class="{'is-invalid': validateErrors.service_id}">
                 <a-radio :value="1">
                   Обычный
                 </a-radio>
                 <a-radio :value="2">
                   Отмененный
                 </a-radio>
                 <a-radio :value="3">
                   Бумеранг
                 </a-radio>
               </a-radio-group>
               <div class="invalid-feedback" v-for="value in validateErrors.service_id"> {{value}} </div>
             </div>
           </div>
          </div>
          <div class="col-lg-6">
            <label for="">Статус</label>
            <a-select
                placeholder="Выберите статуса"
                style="width: 100%"
                optionLabelProp="label"
                optionFilterProp="label"
                :allowClear="true"
                v-model="itemStatus.status_id"
                :class="{'is-invalid': validateErrors.status_id}"
              >
              <a-select-option v-for="status in statuses" v-if="status.parent_id == itemStatus.service_id" :key="status.id" :value="status.id" :label="(status.parent.title)+ ' ' +status.title">
                <span v-if="status.parent.id == 1" style="color: blue">({{status.parent.title}})</span>
                <span v-else-if="status.parent.id == 2" style="color: red">({{status.parent.title}})</span>
                <span v-else="status.parent.id" style="color: green">({{status.parent.title}})</span>
                {{status.title}}
              </a-select-option>
            </a-select>
            <div class="invalid-feedback" v-for="value in validateErrors.status_id"> {{value}} </div>
          </div>

        </div>
      </div>
      <!-- //ddddddddddddddddddddd -->
      <!-- Branches -->
      <div class="mb-3">
        <div class="">
          <label for="">Дата доставки</label>
          <a-date-picker  style="width: 100%" @change="onChangeUpdateStatusDate"/>
        </div>
      </div>
      <div class="mb-3">
        <label for="">Офис <span style="color: #e55353">*</span></label>
        <template>
          <a-select
            show-search
            placeholder="Выберите офиса"
            option-filter-prop="children"
            style="width: 100%"
            :filter-option="filterOption"
            v-model="itemStatus.branchoffice_id"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.branchoffice_id}"
          >
            <a-select-option v-for="branch in branches" :value="branch.id">
              <span v-if="branch.country_id!=192" style="color: green">{{branch.title}} (Международный)</span> <span v-else>{{branch.title}}</span>
            </a-select-option>
          </a-select>
        </template>
        <div class="invalid-feedback" v-for="value in validateErrors.branchoffice_id"> {{value}} </div>
      </div>

      <!-- Drivers -->
      <div class="mb-3">
        <label for="">Водитель <span style="color: #e55353">*</span></label>
        <template>
          <a-select
            show-search
            placeholder="Выберите водителя"
            option-filter-prop="children"
            style="width: 100%"
            :filter-option="filterOption"
            v-model="itemStatus.driver_id"
            :allowClear="true"
            :class="{'is-invalid': validateErrors.driver_id}"
          >
            <a-select-option v-for="driver in drivers" :value="driver.id">
              {{driver.name}}
            </a-select-option>
          </a-select>
        </template>
        <div class="invalid-feedback" v-for="value in validateErrors.driver_id"> {{value}} </div>
      </div>

      <div class="mb-3">
        <label for="">Заметка</label>
        <template>
          <a-textarea v-model="itemStatus.reason" placeholder="Заметка" :rows="4" />
        </template>
      </div>

      <div class="d-flex">
        <a class="btn btn-success ml-auto" @click="statusUpdate" href="#">Обновить</a>
      </div>
    </a-modal>

    <!-- Export to excell  -->
    <a-modal v-model="visibleExportToExcell" title="Экспорт на эксель" class="export-to-excell" footer="">
      <div class="col-lg-12">
        <div class="row mb-3">

          <div class="col-lg-3">
            <div>
              <input type="checkbox" id="order_id" v-model="orderDataForExport.order_id">
              <label class="ml-1" for="order_id"> ID заказа</label>
            </div>
            <div>
              <input type="checkbox" id="type" v-model="orderDataForExport.type">
              <label class="ml-1" for="type">Тип</label>
            </div>
            <div>
              <input type="checkbox" id="branchoffice_from" v-model="orderDataForExport.branchoffice_from">
              <label class="ml-1" for="branchoffice_from">От офиса</label>
            </div>
            <div>
              <input type="checkbox" id="branchoffice_to" v-model="orderDataForExport.branchoffice_to">
              <label class="ml-1" for="branchoffice_to">К офису</label>
            </div>
            <div>
              <input type="checkbox" id="creator_id" v-model="orderDataForExport.creator_id">
              <label class="ml-1" for="creator_id">Кто создал</label>
            </div>
            <div>
              <input type="checkbox" id="client_id" v-model="orderDataForExport.client_id">
              <label class="ml-1" for="client_id">Клиент</label>
            </div>
            <div>
              <input type="checkbox" id="driver_id" v-model="orderDataForExport.driver_id">
              <label class="ml-1" for="driver_id">Водитель</label>
            </div>
            <div>
              <input type="checkbox" id="status_id" v-model="orderDataForExport.status_id">
              <label class="ml-1" for="status_id">Статус</label>
            </div>
            <div>
              <input type="checkbox" id="link_id" v-model="orderDataForExport.link_id">
              <label class="ml-1" for="link_id">Ссылочный идентификатор</label>
            </div>
          </div>

          <div class="col-lg-3">
            <div>
              <input type="checkbox" id="sender_name" v-model="orderDataForExport.sender_name">
              <label class="ml-1" for="sender_name">Имя отправителя</label>
            </div>
            <div>
              <input type="checkbox" id="sender_country" v-model="orderDataForExport.sender_country">
              <label class="ml-1" for="sender_country">Страна отправителя</label>
            </div>
            <div>
              <input type="checkbox" id="sender_region" v-model="orderDataForExport.sender_region">
              <label class="ml-1" for="sender_region">Область отправителя</label>
            </div>
            <div>
              <input type="checkbox" id="sender_city" v-model="orderDataForExport.sender_city">
              <label class="ml-1" for="sender_city">Район отправителя</label>
            </div>
            <div>
              <input type="checkbox" id="sender_address" v-model="orderDataForExport.sender_address">
              <label class="ml-1" for="sender_address">Адрес отправителя</label>
            </div>
            <div>
              <input type="checkbox" id="sender_type_of_building" v-model="orderDataForExport.sender_type_of_building">
              <label class="ml-1" for="sender_type_of_building">Тип здания</label>
            </div>
            <div>
              <input type="checkbox" id="sender_reference_point" v-model="orderDataForExport.sender_reference_point">
              <label class="ml-1" for="sender_reference_point">Ближайший ориентир</label>
            </div>
            <div>
              <input type="checkbox" id="sender_phone" v-model="orderDataForExport.sender_phone">
              <label class="ml-1" for="sender_phone">Тел. номер отправителя</label>
            </div>
            <div>
              <input type="checkbox" id="sender_phones" v-model="orderDataForExport.sender_phones">
              <label class="ml-1" for="sender_phones">Дополнительные номера</label>
            </div>
            <div>
              <input type="checkbox" id="sender_email" v-model="orderDataForExport.sender_email">
              <label class="ml-1" for="sender_email">Email отправителя</label>
            </div>
          </div>

          <div class="col-lg-3">
            <div>
              <input type="checkbox" id="recipient_name" v-model="orderDataForExport.recipient_name">
              <label class="ml-1" for="recipient_name">Имя получателя</label>
            </div>
            <div>
              <input type="checkbox" id="recipient_country" v-model="orderDataForExport.recipient_country">
              <label class="ml-1" for="recipient_country">Страна получателя</label>
            </div>
            <div>
              <input type="checkbox" id="recipient_region" v-model="orderDataForExport.recipient_region">
              <label class="ml-1" for="recipient_region">Область получателя</label>
            </div>
            <div>
              <input type="checkbox" id="recipient_city" v-model="orderDataForExport.recipient_city">
              <label class="ml-1" for="recipient_city">Район получателя</label>
            </div>
            <div>
              <input type="checkbox" id="recipient_address" v-model="orderDataForExport.recipient_address">
              <label class="ml-1" for="recipient_address">Адрес получателя</label>
            </div>
            <div>
              <input type="checkbox" id="recipient_type_of_building" v-model="orderDataForExport.recipient_type_of_building">
              <label class="ml-1" for="recipient_type_of_building">Тип здания</label>
            </div>
            <div>
              <input type="checkbox" id="recipient_reference_point" v-model="orderDataForExport.recipient_reference_point">
              <label class="ml-1" for="recipient_reference_point">Ближайший ориентир</label>
            </div>
            <div>
              <input type="checkbox" id="recipient_phone" v-model="orderDataForExport.recipient_phone">
              <label class="ml-1" for="recipient_phone">Тел. номер получателя</label>
            </div>
            <div>
              <input type="checkbox" id="recipient_phones" v-model="orderDataForExport.recipient_phones">
              <label class="ml-1" for="recipient_phones">Дополнительные номера</label>
            </div>
            <div>
              <input type="checkbox" id="recipient_email" v-model="orderDataForExport.recipient_email">
              <label class="ml-1" for="recipient_email">Email получателя</label>
            </div>
          </div>

          <div class="col-lg-3">
            <div>
              <input type="checkbox" id="width" v-model="orderDataForExport.width">
              <label class="ml-1" for="width">Ширина</label>
            </div>
            <div>
              <input type="checkbox" id="length" v-model="orderDataForExport.length">
              <label class="ml-1" for="length">Длина</label>
            </div>
            <div>
              <input type="checkbox" id="height" v-model="orderDataForExport.height">
              <label class="ml-1" for="height">Высота</label>
            </div>
            <div>
              <input type="checkbox" id="unit_id" v-model="orderDataForExport.unit_id">
              <label class="ml-1" for="unit_id">ед. измерения</label>
            </div>
            <div>
              <input type="checkbox" id="pay_weight" v-model="orderDataForExport.pay_weight">
              <label class="ml-1" for="pay_weight">Оплачиваемый вес</label>
            </div>
          </div>

        </div>
        <hr>
        <div class="row mt-3">

          <div class="col-lg-3">
            <div>
              <input type="checkbox" id="tariff_id" v-model="orderDataForExport.tariff_id">
              <label class="ml-1" for="tariff_id">Тариф</label>
            </div>
            <div>
              <input type="checkbox" id="tariff_price" v-model="orderDataForExport.tariff_price">
              <label class="ml-1" for="tariff_price">Цена по тарифу</label>
            </div>
            <div>
              <input type="checkbox" id="is_fragile" v-model="orderDataForExport.is_fragile">
              <label class="ml-1" for="is_fragile">Хрупкое или нет?</label>
            </div>
          </div>

          <div class="col-lg-3">
            <div>
              <input type="checkbox" id="who_pays" v-model="orderDataForExport.who_pays">
              <label class="ml-1" for="who_pays">Кто оплачивает</label>
            </div>
            <div>
              <input type="checkbox" id="payment_method" v-model="orderDataForExport.payment_method">
              <label class="ml-1" for="payment_method">Тип оплаты</label>
            </div>
            <div>
              <input type="checkbox" id="recipient_is_not_available" v-model="orderDataForExport.recipient_is_not_available">
              <label class="ml-1" for="recipient_is_not_available">Если получатель не доступен?</label>
            </div>
          </div>

          <div class="col-lg-3">
            <div>
              <input type="checkbox" id="cod" v-model="orderDataForExport.cod">
              <label class="ml-1" for="cod">Наложенный платеж</label>
            </div>
            <div>
              <input type="checkbox" id="cod_status" v-model="orderDataForExport.cod_status">
              <label class="ml-1" for="cod_status">Статус наложенного платежа</label>
            </div>
            <div>
              <input type="checkbox" id="discount" v-model="orderDataForExport.discount">
              <label class="ml-1" for="discount">Скидка</label>
            </div>
            <div>
              <input type="checkbox" id="cost_of_service" v-model="orderDataForExport.cost_of_service">
              <label class="ml-1" for="cost_of_service">Цена услуги</label>
            </div>
            <div>
              <input type="checkbox" id="status_of_payment" v-model="orderDataForExport.status_of_payment">
              <label class="ml-1" for="status_of_payment">Статус оплаты услуги</label>
            </div>
            <hr>
            <div>
              <input type="checkbox" id="vat" v-model="orderDataForExport.vat">
              <label class="ml-1" for="vat">НДС?</label>
            </div>
            <div>
              <input type="checkbox" id="cost_of_service_vat" v-model="orderDataForExport.cost_of_service_vat">
              <label class="ml-1" for="cost_of_service_vat">Цена НДС</label>
            </div>
            <div>
              <input type="checkbox" id="cost_of_service_with_vat" v-model="orderDataForExport.cost_of_service_with_vat">
              <label class="ml-1" for="cost_of_service_with_vat">Цена услуги (с НДС)</label>
            </div>
          </div>

          <div class="col-lg-3">
            <div>
              <input type="checkbox" id="cost_of_things" v-model="orderDataForExport.cost_of_things">
              <label class="ml-1" for="cost_of_things">Стоимость вещей</label>
            </div>
            <div>
              <input type="checkbox" id="count_of_parts" v-model="orderDataForExport.count_of_parts">
              <label class="ml-1" for="count_of_parts">Количество частей</label>
            </div>
            <div>
              <input type="checkbox" id="notes" v-model="orderDataForExport.notes">
              <label class="ml-1" for="notes">Заметки</label>
            </div>
            <hr>
            <div>
              <input type="checkbox" id="created_at" v-model="orderDataForExport.created_at">
              <label class="ml-1" for="created_at">Время создания</label>
            </div>
            <div>
              <input type="checkbox" id="updated_at" v-model="orderDataForExport.updated_at">
              <label class="ml-1" for="updated_at">Время изменения</label>
            </div>
          </div>

        </div>
      </div>
      <div class="d-flex">
        <a class="btn btn-info" @click="selectAllForExport" href="#">Выбрать все</a>
        <a class="btn btn-success ml-auto" @click="exportToExcell" href="#">Экспортировать</a>
      </div>
    </a-modal>

    <!-- FIlter Orders -->
    <a-modal v-model="visibleFilterOrders" title="Фильтрация заказов" class="filter-orders" footer="">
      <div class="row mb-3">
        <div class="col-lg-4">
          <label for="">Клиент</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите клиента"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.client.id"

              >
              <a-select-option v-for="client in clients" :key="client.id" :label="client.name">
                {{ client.name }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.client.condition == 'include'" @click="dataFilter.filter.client.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.client.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="">От офиса</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите офиса"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.branchoffice_from.id"

              >
              <a-select-option v-for="branch in branches" :key="branch.id" :label="branch.title">
                {{ branch.title }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.branchoffice_from.condition == 'include'" @click="dataFilter.filter.branchoffice_from.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.branchoffice_from.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="">К офису</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите офиса"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.branchoffice_to.id"

              >
              <a-select-option v-for="branch in branches" :key="branch.id" :label="branch.title">
                {{ branch.title }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.branchoffice_to.condition == 'include'" @click="dataFilter.filter.branchoffice_to.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.branchoffice_to.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-6">
          <label for="">Сотрудник</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите сотрудника"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.manager.id"

              >
              <a-select-option v-for="manager in managers" :key="manager.id" :label="manager.name">
                {{ manager.name }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.manager.condition == 'include'" @click="dataFilter.filter.manager.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.manager.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <label for="">Водитель</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите водителя"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.driver.id"

              >
              <a-select-option v-for="driver in allDrivers" :key="driver.id" :label="driver.name">
                {{ driver.name }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.driver.condition == 'include'" @click="dataFilter.filter.driver.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.driver.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-6">
          <label for="">Тип статуса</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите статуса"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.status_parent.id"

              >
              <a-select-option v-for="status in statusParents" :key="status.value" :label="status.title" v-if="status.value > 0">
                <span>{{ status.title }}</span>
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.status_parent.condition == 'include'" @click="dataFilter.filter.status_parent.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.status_parent.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <label for="">Статус</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите статуса"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.status.id"

              >
              <a-select-option v-for="status in allStatuses" :key="status.id" :label="'(' + status.parent.title + ') ' + status.title">
                <span :style="'color: ' + status.parent.color"> ({{status.parent.title}}) </span> <span>{{ status.title }}</span>
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.status.condition == 'include'" @click="dataFilter.filter.status.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.status.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4">
          <label for="">Страна отправителя</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите страну"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.sender_country.id"
              @search="searchAllCountries"
              >
              <a-select-option v-for="country in allCountries" :key="country.id" :label="country.title">
                  {{ country.title }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.sender_country.condition == 'include'" @click="dataFilter.filter.sender_country.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.sender_country.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="">Область отправителя</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите область"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.sender_region.id"
              @search="searchAllRegions"

              >
              <a-select-option v-for="country in allRegions" :key="country.id" :label="country.title">
                  {{ country.title }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.sender_region.condition == 'include'" @click="dataFilter.filter.sender_region.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.sender_region.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="">Район отправителя</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите района"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.sender_city.id"
              @search="searchAllCities"
              >
              <a-select-option v-for="country in allCities" :key="country.id" :label="country.title">
                  {{ country.title }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.sender_city.condition == 'include'" @click="dataFilter.filter.sender_city.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.sender_city.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4">
          <label for="">Страна получателя</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите страну"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.recipient_country.id"
              @search="searchAllCountries"

              >
              <a-select-option v-for="country in allCountries" :key="country.id" :label="country.title">
                  {{ country.title }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.recipient_country.condition == 'include'" @click="dataFilter.filter.recipient_country.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.recipient_country.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="">Область получателя</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите область"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.recipient_region.id"
              @search="searchAllRegions"

              >
              <a-select-option v-for="country in allRegions" :key="country.id" :label="country.title">
                  {{ country.title }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.recipient_region.condition == 'include'" @click="dataFilter.filter.recipient_region.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.recipient_region.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="">Район получателя</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите района"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.recipient_city.id"
              @search="searchAllCities"
              >
              <a-select-option v-for="country in allCities" :key="country.id" :label="country.title">
                  {{ country.title }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.recipient_city.condition == 'include'" @click="dataFilter.filter.recipient_city.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.recipient_city.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-6">
          <label for="">Тариф</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите тарифа"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.tariff.id"

              >
              <a-select-option v-for="tariff in allTariffs" :key="tariff.id" :label="tariff.title">
                <span>{{ tariff.title }}</span>
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.tariff.condition == 'include'" @click="dataFilter.filter.tariff.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.tariff.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <label for="">НДС</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите варианта"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.vat.value"

              >
              <a-select-option v-for="v in vat" :key="v.value" :label="v.title">
                <span>{{ v.title }}</span>
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.vat.condition == 'include'" @click="dataFilter.filter.vat.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.vat.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <label for="">Хрупкое?</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите варианта"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.fragile.value"

              >
              <a-select-option v-for="frag in fragile" :key="frag.value" :label="frag.title">
                <span>{{ frag.title }}</span>
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.fragile.condition == 'include'" @click="dataFilter.filter.fragile.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.fragile.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4">
          <label for="">Способ оплаты</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите варианта"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.payment_method.value"

              >
              <a-select-option v-for="method in paymentMethod" :key="method.value" :label="method.title">
                  {{ method.title }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.payment_method.condition == 'include'" @click="dataFilter.filter.payment_method.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.payment_method.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="">Кто оплачивает</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите варианта"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.who_pays.value"

              >
              <a-select-option v-for="pay in payer" :key="pay.value" :label="pay.title">
                  {{ pay.title }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.who_pays.condition == 'include'" @click="dataFilter.filter.who_pays.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.who_pays.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <label for="">Статус оплаты</label>
          <div class="d-flex align-items-center">
            <a-select
              mode="multiple"
              placeholder="Выберите варианта"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              v-model="dataFilter.filter.status_of_payment.value"
              >
              <a-select-option v-for="status in codStatus" :key="status.value" :label="status.title">
                  {{ status.title }}
              </a-select-option>
            </a-select>
            <div class="ml-1" style="cursor: pointer" v-if="dataFilter.filter.status_of_payment.condition == 'include'" @click="dataFilter.filter.status_of_payment.condition = 'exclude'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path fill="#41C81B" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
              </svg>
            </div>
            <div class="ml-1" style="cursor: pointer" v-else @click="dataFilter.filter.status_of_payment.condition = 'include'">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path fill="#C82B33" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row mb-3">
        <div class="col-lg-3">
          <label for="">Оплачиваемый вес от</label>
          <a-input  type="number" placeholder="Оплачиваемый вес от" v-model="dataFilter.filter.weight.from" />
        </div>
        <div class="col-lg-3">
          <label for="">Оплачиваемый вес до</label>
          <a-input  type="number" placeholder="Оплачиваемый вес до" v-model="dataFilter.filter.weight.to" />
        </div>
        <div class="col-lg-3">
          <label for="">Цена услуги от</label>
          <a-input  type="number" placeholder="Оплачиваемый вес от" v-model="dataFilter.filter.cost_of_service.from" />
        </div>
        <div class="col-lg-3">
          <label for="">Цена услуги до</label>
          <a-input  type="number" placeholder="Оплачиваемый вес до" v-model="dataFilter.filter.cost_of_service.to" />
        </div>
      </div>
      <hr>
      <div class="row mb-3">
        <div class="col-lg-4">
          <label for="">Со скидкой</label>
          <a-select
            placeholder="Выберите варианта"
            style="width: 100%"
            optionLabelProp="label"
            optionFilterProp="label"
            v-model="dataFilter.filter.discount.value"
            >
            <a-select-option v-for="option in withDiscount" :key="option.value" :label="option.title">
                {{ option.title }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-lg-4">
          <label for="">Скидка от</label>
          <a-input  type="number" placeholder="Скидка от" v-model="dataFilter.filter.discount.from" />
        </div>
        <div class="col-lg-4">
          <label for="">Скидка до</label>
          <a-input  type="number" placeholder="Скидка до"  v-model="dataFilter.filter.discount.to"/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4">
          <label for="">С наложенным платежом</label>
          <a-select
            placeholder="Выберите варианта"
            style="width: 100%"
            optionLabelProp="label"
            optionFilterProp="label"
            v-model="dataFilter.filter.cod.value"
            >
            <a-select-option v-for="option in withCod" :key="option.value" :label="option.title">
                {{ option.title }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-lg-4">
          <label for="">Наложенный платеж от</label>
          <a-input  type="number" placeholder="Наложенный платеж от" v-model="dataFilter.filter.cod.from" />
        </div>
        <div class="col-lg-4">
          <label for="">Наложенный платеж до</label>
          <a-input  type="number" placeholder="Наложенный платеж до"  v-model="dataFilter.filter.cod.to"/>
        </div>
      </div>
      <hr>
      <div class="row mb-3">
        <div class="col-lg-3">
          <label for="">Время создания от</label>
          <a-date-picker  style="width: 100%" @change="onChangeFilterCreatedAtFrom"/>
        </div>
        <div class="col-lg-3">
          <label for="">Время создания до</label>
          <a-date-picker  style="width: 100%" @change="onChangeFilterCreatedAtTo"/>
        </div>
        <div class="col-lg-3">
          <label for="">Время изменения от</label>
          <a-date-picker  style="width: 100%" @change="onChangeFilterUpdatedAtFrom"/>
        </div>
        <div class="col-lg-3">
          <label for="">Время изменения до</label>
          <a-date-picker  style="width: 100%" @change="onChangeFilterUpdatedAtTo"/>
        </div>
      </div>
      <div class="d-flex">
        <a class="btn btn-success ml-auto" href="#" @click="applyFullFilter">Фильтровать</a>
      </div>
    </a-modal>

    <!-- Фото -->
    <a-modal v-model="visibleImage" title="Фото профиля" class="photo-view" footer="" style="max-width: 1200px">
              <img class="img-fluid" :src="$store.state.urlBackend + '/' + image" alt="" >
    </a-modal>
  </div>
</template>

<script>
  import VueQrcode from 'vue-qrcode'
  import DirectionsRenderer from "./components/DirectionsRenderer";
  import axios from 'axios'
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  const data = [];
  var notify = new Audio('notification.mp3');
  import moment, { Moment } from 'moment';
  import { defineComponent, ref } from 'vue';
  const dateFormat = 'DD-MM-YYYY';
  export default {
    components: {DirectionsRenderer, VueQrcode},
    data() {
      return {
        promocode: {
          client_id: '',
          code: '',
          title: '',
          description: '',
          min_price: '',
          max_price: '',
          type: '',
          value: '',
          status: 'active',
          type_of_service: '',
          date_from: '',
          date_to: '',
          count: '',
          tariffs_id: [],
          tariffs: [],
        },
        promocodeOld: {
          client_id: '',
          code: '',
          title: '',
          description: '',
          min_price: '',
          max_price: '',
          type: '',
          value: '',
          status: 'active',
          type_of_service: '',
          date_from: '',
          date_to: '',
          count: '',
          tariffs_id: [],
          tariffs: [],
        },
        dataFilter: {
          type: 'international',
          filterType: 'single',
          statusParents: null,
          status_id: [],
          branchoffice_id: [],
          active: 'all',
          page: 1,
          searchBy: 'id',
          id: [],
          search: '',
          whose: '',

          fullFilter: false,
          filter: {
            client: {
              condition: "include",
              id: [],
            },
            branchoffice_from: {
              condition: "include",
              id: [],
            },
            branchoffice_to: {
              condition: "include",
              id: [],
            },
            manager: {
              condition: "include",
              id: [],
            },
            driver: {
              condition: "include",
              id: [],
            },
            status_parent: {
              condition: "include",
              id: [],
            },
            status: {
              condition: "include",
              id: [],
            },
            sender_country: {
              condition: "include",
              id: [],
            },
            sender_region: {
              condition: "include",
              id: [],
            },
            sender_city: {
              condition: "include",
              id: [],
            },
            recipient_country: {
              condition: "include",
              id: [],
            },
            recipient_region: {
              condition: "include",
              id: [],
            },
            recipient_city: {
              condition: "include",
              id: [],
            },
            tariff: {
              condition: "include",
              id: [],
            },
            fragile: {
              condition: "include",
              value: [],
            },
            vat: {
              condition: "include",
              value: [],
            },
            payment_method: {
              condition: "include",
              value: [],
            },
            who_pays: {
              condition: "include",
              value: [],
            },
            status_of_payment: {
              condition: "include",
              value: [],
            },
            weight: {
              from: '',
              to: ''
            },
            cost_of_service: {
              from: '',
              to: ''
            },
            discount: {
              value: 'all',
              from: '',
              to: ''
            },
            cod: {
              value: 'all',
              from: '',
              to: ''
            },
            created_at: {
              from: ref<Moment>(moment('12-12-2000', dateFormat)),
              to: ref<Moment>(moment('12-12-2000', dateFormat)),
            },
            updated_at: {
              from: ref<Moment>(moment('12-12-2000', dateFormat)),
              to: ref<Moment>(moment('12-12-2000', dateFormat)),
            }
          }
        },
        count: {
          all: 0,
          at_the_sender: 0,
          need_to_get: 0,
          at_the_driver: 0,
          at_the_office: 0,
          need_to_delivery: 0,
          delivered: 0,

          need_to_get_1: 0,
          at_the_driver_1: 0,
          at_the_office_1: 0,
          need_to_delivery_1: 0,

          at_the_driver_2: 0,
          at_the_office_2: 0,
          need_to_delivery_2: 0,

        },

        data,
        item: {},
        oldItem: {
          type: 'international',
          creator_id: '',

          client_id: '',
          branchoffice_from: JSON.parse(localStorage.me).branchoffice_id,
          branchoffice_to: '',
          driver_id: '',
          status_id: '',
          service_id: 1,
          link_id: '',

          type_service_id: '',
          who_create: 'employee',

          sender_name: '',
          sender_country: '',
          sender_region: '',
          sender_city: '',
          sender_address: '',
          sender_type_of_building: 'commercial',
          sender_reference_point: '',
          sender_phone: '',
          sender_phone_1: '',
          sender_phone_2: '',
          sender_email: '',
          sender_point: '',

          recipient_name: '',
          recipient_country: '',
          recipient_region: '',
          recipient_city: '',
          recipient_address: '',
          recipient_type_of_building: 'commercial',
          recipient_reference_point: '',
          recipient_phone: '',
          recipient_phone_1: '',
          recipient_phone_2: '',
          recipient_email: '',
          recipient_point: '',

          width: 1,
          length: 1,
          height: 1,
          unit_id: 1,
          weight: 0,
          volume_weight: 0.0002,

          tariff_id: '',
          tariff_price:'',
          vat: 'vat_12',
          is_fragile: '',

          payment_method: '',
          who_pays: '',
          recipient_is_not_available: '',
          
          provider_id: null,
          provider_price: null,
          provider_phone: null,
          cse_phone: null,
          tracking_code: null,

          cod: '',
          cod_status: '',
          code: '',
          discount: '',
          cost_of_service: '',
          cost_of_service_vat: '',
          cost_of_service_with_vat: '',
          status_of_payment: '',
          cost_of_things: '',
          count_of_parts: '',
          notes: '',
          title: '',
          cargo: [],

          images: [],
        },
        newItem: {
          type: 'international',
          creator_id: '',

          client_id: '',
          branchoffice_from: JSON.parse(localStorage.me).branchoffice_id,
          branchoffice_to: '',
          driver_id: '',
          status_id: '',
          service_id: 1,
          link_id: '',

          type_service_id: '',
          who_create: 'employee',

          sender_name: '',
          sender_country: '',
          sender_region: '',
          sender_city: '',
          sender_address: '',
          sender_type_of_building: 'commercial',
          sender_reference_point: '',
          sender_phone: '',
          sender_phone_1: '',
          sender_phone_2: '',
          sender_email: '',
          sender_point: '',

          recipient_name: '',
          recipient_country: '',
          recipient_region: '',
          recipient_city: '',
          recipient_address: '',
          recipient_type_of_building: 'commercial',
          recipient_reference_point: '',
          recipient_phone: '',
          recipient_phone_1: '',
          recipient_phone_2: '',
          recipient_email: '',
          recipient_point: '',

          width: 1,
          length: 1,
          height: 1,
          unit_id: 1,
          weight: 0,
          volume_weight: 0.0002,

          tariff_id: '',
          tariff_price:'',
          vat: 'vat_12',
          is_fragile: '',

          payment_method: '',
          who_pays: '',
          recipient_is_not_available: '',
          
          provider_id: null,
          provider_price: null,
          provider_phone: null,
          cse_phone: null,
          tracking_code: null,

          cod: '',
          cod_status: '',
          code: '',
          discount: '',
          cost_of_service: '',
          cost_of_service_with_vat:'',
          cost_of_service_vat:'',

          telegram_chat_id: '',
          cashback_balans: '',
          allowed_cashback_balans: '',
          cashback: '',

          status_of_payment: '',
          cost_of_things: '',
          count_of_parts: '',
          notes: '',
          title: '',
          cargo: [],

          images: [],
        },
        itemStatus: {
          order_id: [],
          service_id: '',
          status_id: '',
          branchoffice_id: '',
          driver_id: '',
          delivery_date: ref<Moment>(moment('12-12-2000', dateFormat)),
        },
        orderIdForExport: [],
        selectedAll: false,
        selectedAllForExport: false,
        orderDataForExport: {
          id: [],
          order_id: 1,
          type: 1,
          creator_id: '',

          client_id: '',
          branchoffice_from: 1,
          branchoffice_to: 1,
          driver_id: '',
          status_id: '',
          link_id: '',

          sender_name: 1,
          sender_country: '',
          sender_region: '',
          sender_city: '',
          sender_address: '',
          sender_type_of_building: '',
          sender_reference_point: '',
          sender_phone: 1,
          sender_phones: '',
          sender_email: '',

          recipient_name: 1,
          recipient_country: '',
          recipient_region: '',
          recipient_city: '',
          recipient_address: '',
          recipient_type_of_building: '',
          recipient_reference_point: '',
          recipient_phone: 1,
          recipient_phones: '',
          recipient_email: '',

          width: '',
          length: '',
          height: '',
          unit_id: '',
          pay_weight: '',

          tariff_id: '',
          tariff_price:'',
          is_fragile: '',

          payment_method: '',
          who_pays: '',
          recipient_is_not_available: '',

          cod: '',
          cod_status: '',
          discount: '',
          cost_of_service: 1,
          status_of_payment: '',
          cost_of_things: '',
          count_of_parts: '',
          notes: '',

          vat: '',
          cost_of_service_vat: '',
          cost_of_service_with_vat: '',

          created_at: '',
          updated_at: ''
        },
        newAddressBook: {
          user_id: null,
          name: '',
          country_id: null,
          region_id: null,
          city_id: null,
          address: '',
          type_of_building: '',
          reference_point: '',
          phone: '',
          phone_1: '',
          phone_2: '',
          email: '',
          points: '',
        },
        oldAddressBook: {
          user_id: null,
          name: '',
          country_id: null,
          region_id: null,
          city_id: null,
          address: '',
          type_of_building: '',
          reference_point: '',
          phone: '',
          phone_1: '',
          phone_2: '',
          email: '',
          points: '',
        },

        senderA: '',
        recipientA: '',
        clients: [],
        branches: [],
        oldDrivers: [],
        drivers: [],
        allDrivers: [],
        managers: [],
        statuses: [],
        allStatuses: [],
        filterForStatuses: 1,
        typeOfServices: [],
        senderCountries: [{id: 192, title: 'Узбекистан'}],
        senderRegions: [],
        senderCities: [],
        typeOfAddress: [{title: "Жилое", value: 'residential'},{title: "Коммерческое", value: 'commercial'}],
        recipientCountries: [{id: 192, title: 'Узбекистан'}],
        recipientRegions: [],
        recipientCities: [],
        fragile: [{title: 'Да', value: 'yes'},{title: 'Нет', value: 'no'}],
        vat: [{title: 'НДС 12%', value: 'vat_12'},{title: 'НДС 0%', value: 'vat_0'},{title: 'без НДС', value: 'without_vat'}],
        paymentMethod: [{title: 'Наличные', value: 'cash'},{title: 'Пластик карта', value: 'wallet'},{title: "Перечисление", value: "credit_balance"},{title: "Payme", value: "payme"},{title: "Uzum Bank", value: "uzumbank"}],
        payer: [{title: 'Отправитель', value: 'sender'},{title: 'Получатель', value: 'recipient'}],
        ifRecipientNotAvialable: [{title: 'Не доставлять', value: 'dont_deliver'},{title: 'Оставить у двери', value: 'leave_at_the_door'},{title: 'Оставьте с консьержом', value: 'leave_with_the_concierge'}],
        codStatus: [{title: 'Оплачено', value: 'paid'},{title: 'Не оплачено', value: 'not_paid'}],
        units: [],
        providers: [],
        tariffs: [],
        allTariffs: [],
        tariffPrice: [],
        statusParents: [{title: "Все",value: null},{title: "Обычный",value: 1},{title: "Отмененный",value: 2},{title: "Бумеранг",value: 3},],
        withCod: [{title: "Все", value: "all"},{title: "С наложенным платежом", value: "with"},{title: "Без наложенного платежа", value: "without"}],
        withDiscount: [{title: "Все", value: "all"},{title: "Со скидкой", value: "with"},{title: "Без скидки", value: "without"}],
        selectedType: {},

        typeService: 'usual',
        orderCargo: {},
        typeOfCargo: [],
        typeOfServiceAll: [],
        typeOfService: [],

        addressesForSender: [],
        addressesForRecipient: [],
        phoneSourceSenderAddress: [],
        dataSourceSenderAddress: [],
        phoneSourceRecipientAddress: [],
        dataSourceRecipientAddress: [],

        allCountries: [],
        allRegions: [],
        allCities: [],

        ind: -10,
        visible: false,
        visibleShowModal: false,
        visibleEditModal: false,
        visibleDeleteModal: false,
        visibleCreateModal: false,
        visibleEditStatus: false,
        visibleExportToExcell: false,
        visibleFilterOrders: false,
        load: true,
        visibleSenderAddresses: false,
        visibleRecipientAddresses: false,

        visibleMap: false,
        visibleSenderMap: false,
        visibleRecipientMap: false,

        validateErrors: [],

        dataFilterSender: {
          country: '',
          region: ''
        },
        dataFilterRecipient: {
          country: '',
          region: ''
        },

        senderCenter: {
            lat: 41.31087153905742,
            lng: 69.27891510052102
        },
        recipientCenter: {
            lat: 41.31087153905742,
            lng: 69.27891510052102
        },
        senderLocation: {
          lat: '',
          lng: ''
        },
        recipientLocation: {
          lat: '',
          lng: ''
        },

        dataForMapSender: {
          country: '',
          region: '',
          city: '',
          street: '',
          building: '',
        },
        dataForMapRecipient: {
          country: '',
          region: '',
          city: '',
          street: '',
          building: '',
        },

        previewVisibleOrder: false,
        previewVisible: false,
        access_token: localStorage.token,
        previewImageOrder: '',
        previewImage: '',
        fileList: [],
        fileListOrder: [],
        fileListSignature: [],
        confirmListOrder: [],

        image: '',
        visibleImage: false,

        currentPage: 1,
        totalResults: 0,
        scrolled: false,
        old_height: 0,
        last_page: 1,
        index: '',
        info: 'header',


        snappedPoints: [
          {
            lat: -35.278004899930188,
            lng: 149.129531998742
          },
          {
            lat: -36.27816655026718,
            lng: 150.12609531690737
          },
        ],
        routingPath: [
          {
            lat: -35.278004899930188,
            lng: 149.129531998742
          },
          {
            lat: -36.27816655026718,
            lng: 150.12609531690737
          },
        ],
        driving: 'WALKING',
        notify,
      }
    },

    sockets: {
      connect() {
        this.isConnected = true;
        console.log('Connected !')
      },
      disconnect() {
        this.isConnected = false;
        console.log('Disconnected !')
      },
      orderUpdate(value){
        let notification = 0;
        if(this.item){
          if(this.item.id == value.id){
            this.item = value;
          }
        }
        for(let i=0; i<this.data.length; i++){
          if(this.data[i].id == value.id){
            this.data[i] = value;
          }
        }
        if(this.me.roles[0].name == 'admin'){
          notification = 1;
        }else if(this.me.roles[0].name == 'operation'){
          if(this.me.branchoffice_id == value.branchoffice_from || this.me.branchoffice_id == value.branchoffice_to || this.me.branchoffice_id == value.branchoffice_id){
            notification = 1;
          }
        }
        this.$forceUpdate();
        if(notification>0){
          notify.play();
          this.$notification.open({
            message: 'Уведомление',
            description:
              'Заказ изменен!  ID: ' + value.id,
            duration: 5,
            style: 'color: blue; border-radius: 4px; border-left: 7px solid #318CE7'
          });
        }
      },
      orderCreate(value){
        let notification = 0;
        if(this.me.id != value.creator_id){
          if(this.me.roles[0].name == 'admin'){
            let arrayData = [];
            arrayData[0] = value;
            this.data = arrayData.concat(this.data);
            notification = 1;
          }else if(this.me.roles[0].name == 'operation'){
            if(this.me.branchoffice_id == value.branchoffice_from || this.me.branchoffice_id == value.branchoffice_to || this.me.branchoffice_id == value.branchoffice_id){
              let arrayData = [];
              arrayData[0] = value;
              this.data = arrayData.concat(this.data);
              notification = 1;
            }
          }
        }

        this.$forceUpdate();
        if(notification>0){
          notify.play();
          this.$notification.open({
            message: 'Уведомление',
            description:
              'Новый заказ!  ID: ' + value.id,
            duration: 5,
            style: 'color: green; border-radius: 4px; border-left: 7px solid #2EB85C'
          });
        }

      }
    },

    mounted(){


      // axios.get('/user').then((response) => {
      //   this.me = response.data;
      // })

      this.getCount();
      this.getOrders();
      this.getClients();
      this.getBranches();
      this.getDrivers();
      this.getStatuses();
      this.getTypeOfService();
      this.getUnits();
      this.getAllTariffs();
      this.getProviders();
      this.getAddresses();
      this.filterTariffs();
      this.getAllDrivers();
      this.getManagers();

      this.getTypesOfCargo();
      this.getServiceTypes();

      window.onscroll = () => {
          let height = document.getElementById('ordersList').clientHeight;
          if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
            if(this.old_height!=height){
              this.old_height = height;
              this.dataFilter.page = Math.floor(this.data.length/this.$store.state.paginateCount) + 1;
              if(this.dataFilter.page <= this.last_page){
                if(this.data.length < this.totalResults){
                  this.addOrdersWithPaginate();
                }
              }
            }
          }
      };

    },
    methods: {
      changeServiceTypeOrder(val){
        console.log(this.newItem.service_id);
        if(this.newItem.service_id == 1 || this.newItem.service_id == 2){
          this.typeService = 'usual';
        }else{
          this.typeService = 'boomerang';
        }
        this.newItem.type_service_id = '';
        this.filterService();
        this.filterTariffs();
      },
      changeOrderTitle(val){
        this.orderCargo = this.typeOfCargo.find((el) => el.title === val);
        this.newItem.tariff_id = '';
        this.newItem.tariff_price = '';
        this.filterTariffs();
      },
      changeServiceOrder(val){
        this.selectedType = this.typeOfServiceAll.find((el) => el.id === val);

        this.newItem.tariff_id = '';
        this.newItem.tariff_price = '';
        this.filterTariffs();
      },

      changeServiceTypeOrderInEdit(val){
        console.log(this.oldItem.service_id);
        if(this.oldItem.service_id == 1 || this.oldItem.service_id == 2){
          this.typeService = 'usual';
        }else{
          this.typeService = 'boomerang';
        }
        this.oldItem.type_service_id = '';
        this.filterServiceInEdit();
        this.filterTariffsInEdit();
      },
      changeOrderTitleInEdit(val){
        this.orderCargo = this.typeOfCargo.find((el) => el.title === val);
        this.filterTariffsInEdit();
      },
      changeServiceOrderInEdit(val){
        this.selectedType = this.typeOfServiceAll.find((el) => el.id === val);

        this.oldItem.tariff_id = '';
        this.oldItem.tariff_price = '';
        this.filterTariffsInEdit();
      },


      getServiceTypes(){
        axios.get('/employee/type-of-service/get').then(response => {
          if(response.status == 200){
            this.typeOfServiceAll = response.data;
            this.filterService();
          }else{
            this.$message.error(
              'Тип услуги не найдено',
              3
            );
          }
        }).catch(error => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }else{
            this.$message.error(
              'Тип услуги не найдено',
              3
            );
          }
        }).finally(() => {
          this.load = false;
        });
      },
      getTypesOfCargo(){
        axios.get('/employee/type-of-cargo/get').then(response => {
          if(response.status == 200){
            this.typeOfCargo = response.data
          }else{
            this.$message.error(
              'Тип груза не найдены',
              3
            );
          }
        }).catch(error => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }else{
            this.$message.error(
              'Тип груза не найдены',
              3
            );
          }
        }).finally(() => {

        });
      },

      filterService(){
        this.typeOfService = this.typeOfServiceAll.filter((el) => el.type === this.newItem.type);
        this.typeOfService = this.typeOfService.filter((el) => el.service_type === this.typeService || el.service_type === 'both');
      },
      filterServiceInEdit(){
        this.typeOfService = this.typeOfServiceAll.filter((el) => el.type === this.oldItem.type);
        this.typeOfService = this.typeOfService.filter((el) => el.service_type === this.typeService || el.service_type === 'both');
      },

      selectAllForExport(){
          if(this.selectedAllForExport){
            this.orderDataForExport.order_id = 0,
            this.orderDataForExport.type = 0,
            this.orderDataForExport.creator_id = 0,
            this.orderDataForExport.client_id = 0,
            this.orderDataForExport.branchoffice_from = 0,
            this.orderDataForExport.branchoffice_to = 0,
            this.orderDataForExport.driver_id = 0,
            this.orderDataForExport.status_id = 0,
            this.orderDataForExport.link_id = 0,
            this.orderDataForExport.sender_name = 0,
            this.orderDataForExport.sender_country = 0,
            this.orderDataForExport.sender_region = 0,
            this.orderDataForExport.sender_city = 0,
            this.orderDataForExport.sender_address = 0,
            this.orderDataForExport.sender_type_of_building = 0,
            this.orderDataForExport.sender_reference_point = 0,
            this.orderDataForExport.sender_phone = 0,
            this.orderDataForExport.sender_phones = 0,
            this.orderDataForExport.sender_email = 0,
            this.orderDataForExport.recipient_name = 0,
            this.orderDataForExport.recipient_country = 0,
            this.orderDataForExport.recipient_region = 0,
            this.orderDataForExport.recipient_city = 0,
            this.orderDataForExport.recipient_address = 0,
            this.orderDataForExport.recipient_type_of_building = 0,
            this.orderDataForExport.recipient_reference_point = 0,
            this.orderDataForExport.recipient_phone = 0,
            this.orderDataForExport.recipient_phones = 0,
            this.orderDataForExport.recipient_email = 0,
            this.orderDataForExport.width = 0,
            this.orderDataForExport.length = 0,
            this.orderDataForExport.height = 0,
            this.orderDataForExport.unit_id = 0,
            this.orderDataForExport.pay_weight = 0,
            this.orderDataForExport.tariff_id = 0,
            this.orderDataForExport.tariff_price = 0,
            this.orderDataForExport.is_fragile = 0,
            this.orderDataForExport.payment_method = 0,
            this.orderDataForExport.who_pays = 0,
            this.orderDataForExport.recipient_is_not_available = 0,
            this.orderDataForExport.cod = 0,
            this.orderDataForExport.cod_status = 0,
            this.orderDataForExport.discount = 0,
            this.orderDataForExport.cost_of_service = 0,
            this.orderDataForExport.status_of_payment = 0,
            this.orderDataForExport.cost_of_things = 0,
            this.orderDataForExport.count_of_parts = 0,
            this.orderDataForExport.notes = 0,
            this.orderDataForExport.vat = 0,
            this.orderDataForExport.cost_of_service_vat = 0,
            this.orderDataForExport.cost_of_service_with_vat = 0,
            this.orderDataForExport.created_at = 0,
            this.orderDataForExport.updated_at = 0,
            this.selectedAllForExport = false;
        }else{
            this.orderDataForExport.order_id = 1,
            this.orderDataForExport.type = 1,
            this.orderDataForExport.creator_id = 1,
            this.orderDataForExport.client_id = 1,
            this.orderDataForExport.branchoffice_from = 1,
            this.orderDataForExport.branchoffice_to = 1,
            this.orderDataForExport.driver_id = 1,
            this.orderDataForExport.status_id = 1,
            this.orderDataForExport.link_id = 1,
            this.orderDataForExport.sender_name = 1,
            this.orderDataForExport.sender_country = 1,
            this.orderDataForExport.sender_region = 1,
            this.orderDataForExport.sender_city = 1,
            this.orderDataForExport.sender_address = 1,
            this.orderDataForExport.sender_type_of_building = 1,
            this.orderDataForExport.sender_reference_point = 1,
            this.orderDataForExport.sender_phone = 1,
            this.orderDataForExport.sender_phones = 1,
            this.orderDataForExport.sender_email = 1,
            this.orderDataForExport.recipient_name = 1,
            this.orderDataForExport.recipient_country = 1,
            this.orderDataForExport.recipient_region = 1,
            this.orderDataForExport.recipient_city = 1,
            this.orderDataForExport.recipient_address = 1,
            this.orderDataForExport.recipient_type_of_building = 1,
            this.orderDataForExport.recipient_reference_point = 1,
            this.orderDataForExport.recipient_phone = 1,
            this.orderDataForExport.recipient_phones = 1,
            this.orderDataForExport.recipient_email = 1,
            this.orderDataForExport.width = 1,
            this.orderDataForExport.length = 1,
            this.orderDataForExport.height = 1,
            this.orderDataForExport.unit_id = 1,
            this.orderDataForExport.pay_weight = 1,
            this.orderDataForExport.tariff_id = 1,
            this.orderDataForExport.tariff_price = 1,
            this.orderDataForExport.is_fragile = 1,
            this.orderDataForExport.payment_method = 1,
            this.orderDataForExport.who_pays = 1,
            this.orderDataForExport.recipient_is_not_available = 1,
            this.orderDataForExport.cod = 1,
            this.orderDataForExport.cod_status = 1,
            this.orderDataForExport.discount = 1,
            this.orderDataForExport.cost_of_service = 1,
            this.orderDataForExport.status_of_payment = 1,
            this.orderDataForExport.cost_of_things = 1,
            this.orderDataForExport.count_of_parts = 1,
            this.orderDataForExport.notes = 1,
            this.orderDataForExport.vat = 1,
            this.orderDataForExport.cost_of_service_vat = 1,
            this.orderDataForExport.cost_of_service_with_vat = 1,
            this.orderDataForExport.created_at = 1,
            this.orderDataForExport.updated_at = 1,
            this.selectedAllForExport = true;
          }


      },

      searchAllCountries(value){
        axios.get('/employee/countries/all/search', {params: {query: value}}).then((response) => {
          if(response.status == 200){
            this.allCountries = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Нет соответствующих стран',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              "Ошибка сервера",
              3
            );
          }
        });
      },
      searchAllRegions(value){
        axios.get('/employee/regions/all/search', {params: {query: value}}).then((response) => {
          if(response.status == 200){
            this.allRegions = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Нет соответствующих областей',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              "Ошибка сервера",
              3
            );
          }
        });
      },
      searchAllCities(value){
        axios.get('/employee/cities/all/search', {params: {query: value}}).then((response) => {
          if(response.status == 200){
            this.allCities = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Нет соответствующего района',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              "Ошибка сервера",
              3
            );
          }
        });
      },

      onChangeUpdateStatusDate(date, dateString) {
        this.itemStatus.delivery_date =  dateString;
      },
      onChangeFilterCreatedAtFrom(date, dateString) {
        this.dataFilter.filter.created_at.from =  dateString;
      },
      onChangeFilterCreatedAtTo(date, dateString) {
        this.dataFilter.filter.created_at.to =  dateString;
      },
      onChangeFilterUpdatedAtFrom(date, dateString) {
        this.dataFilter.filter.updated_at.from =  dateString;
      },
      onChangeFilterUpdatedAtTo(date, dateString) {
        this.dataFilter.filter.updated_at.to =  dateString;
      },
      showImage(value){
        this.image = value;
        this.visibleImage = true;
      },

      changeTariffPrice(){
        if(this.promocode.code){
          if(this.promocode.type == 'absolute_discount'){
            this.newItem.discount = this.promocode.value;
            this.newItem.cost_of_service = this.newItem.tariff_price - this.newItem.discount;
          }else if(this.promocode.type == 'percentage_discount'){
            this.newItem.discount = (this.promocode.value/100) * this.newItem.tariff_price;
            this.newItem.cost_of_service = this.newItem.tariff_price - this.newItem.discount;
          }else if(this.promocode.type == 'free_order'){
            this.newItem.discount  = this.newItem.tariff_price;
            this.newItem.cost_of_service = this.newItem.tariff_price - this.newItem.discount;
          }
          if(this.newItem.vat == 'vat_15'){
            this.newItem.cost_of_service_with_vat = Math.round(this.newItem.cost_of_service*1.15);
            this.newItem.cost_of_service_vat = Math.round(this.newItem.cost_of_service*0.15);
          }else if(this.newItem.vat == 'vat_12'){
            this.newItem.cost_of_service_with_vat = Math.round(this.newItem.cost_of_service*1.12);
            this.newItem.cost_of_service_vat = Math.round(this.newItem.cost_of_service*0.12);
          }else{
            this.newItem.cost_of_service_with_vat = this.newItem.cost_of_service;
            this.newItem.cost_of_service_vat = this.newItem.cost_of_service*0;
          }
        }else{
          this.newItem.cost_of_service = this.newItem.tariff_price;
          if(this.newItem.vat == 'vat_15'){
            this.newItem.cost_of_service_with_vat = Math.round(this.newItem.cost_of_service*1.15);
            this.newItem.cost_of_service_vat = Math.round(this.newItem.cost_of_service*0.15);
          }else if(this.newItem.vat == 'vat_12'){
            this.newItem.cost_of_service_with_vat = Math.round(this.newItem.cost_of_service*1.12);
            this.newItem.cost_of_service_vat = Math.round(this.newItem.cost_of_service*0.12);
          }else{
            this.newItem.cost_of_service_with_vat = this.newItem.cost_of_service;
            this.newItem.cost_of_service_vat = this.newItem.cost_of_service*0;
          }
        }
      },
      changeTariffPriceInEdit(){
        if(this.promocode.code){
          if(this.promocode.type == 'absolute_discount'){
            this.oldItem.discount = this.promocode.value;
            this.oldItem.cost_of_service = this.oldItem.tariff_price - this.oldItem.discount;
          }else if(this.promocode.type == 'percentage_discount'){
            this.oldItem.discount = (this.promocode.value/100) * this.oldItem.tariff_price;
            this.oldItem.cost_of_service = this.oldItem.tariff_price - this.oldItem.discount;
          }else if(this.promocode.type == 'free_order'){
            this.oldItem.discount  = this.oldItem.tariff_price;
            this.oldItem.cost_of_service = this.oldItem.tariff_price - this.oldItem.discount;
          }
          if(this.newItem.vat == 'vat_15'){
            this.oldItem.cost_of_service_with_vat = Math.round(this.oldItem.cost_of_service*1.15);
            this.oldItem.cost_of_service_vat = Math.round(this.oldItem.cost_of_service*0.15);
          }else if(this.newItem.vat == 'vat_12'){
            this.oldItem.cost_of_service_with_vat = Math.round(this.oldItem.cost_of_service*1.12);
            this.oldItem.cost_of_service_vat = Math.round(this.oldItem.cost_of_service*0.12);
          }else{
            this.oldItem.cost_of_service_with_vat = this.oldItem.cost_of_service;
            this.oldItem.cost_of_service_vat = this.oldItem.cost_of_service*0;
          }
        }else{
          this.oldItem.cost_of_service = this.oldItem.tariff_price;
          if(this.oldItem.vat == 'vat_15'){
            this.oldItem.cost_of_service_with_vat = Math.round(this.oldItem.cost_of_service*1.15);
            this.oldItem.cost_of_service_vat = Math.round(this.oldItem.cost_of_service*0.15);
          }else if(this.oldItem.vat == 'vat_12'){
            this.oldItem.cost_of_service_with_vat = Math.round(this.oldItem.cost_of_service*1.12);
            this.oldItem.cost_of_service_vat = Math.round(this.oldItem.cost_of_service*0.12);
          }else{
            this.oldItem.cost_of_service_with_vat = this.oldItem.cost_of_service;
            this.oldItem.cost_of_service_vat = this.oldItem.cost_of_service*0;
          }
        }
      },
      checkPromoCode(){
        this.load = true;
        axios.post('/employee/orders/check-promocode', this.newItem).then((response) => {
          if(response.status == 200){
            this.promocode = response.data;
            this.validateErrors = [];

            if(this.promocode.type == 'absolute_discount'){
              this.newItem.discount = this.promocode.value;
              this.newItem.cost_of_service = this.newItem.tariff_price - this.newItem.discount;
            }else if(this.promocode.type == 'percentage_discount'){
              this.newItem.discount = (this.promocode.value/100) * this.newItem.tariff_price;
              this.newItem.cost_of_service = this.newItem.tariff_price - this.newItem.discount;
            }else if(this.promocode.type == 'free_order'){
              this.newItem.discount  = this.newItem.tariff_price;
              this.newItem.cost_of_service = this.newItem.tariff_price - this.newItem.discount;
            }
            if(this.newItem.vat == 'vat_15'){
              this.newItem.cost_of_service_with_vat = Math.round(this.newItem.cost_of_service*1.15);
              this.newItem.cost_of_service_vat = Math.round(this.newItem.cost_of_service*0.15);
            }else if(this.newItem.vat == 'vat_12'){
              this.newItem.cost_of_service_with_vat = Math.round(this.newItem.cost_of_service*1.12);
              this.newItem.cost_of_service_vat = Math.round(this.newItem.cost_of_service*0.12);
            }else{
              this.newItem.cost_of_service_with_vat = this.newItem.cost_of_service;
              this.newItem.cost_of_service_vat = this.newItem.cost_of_service*0;
            }
          }
        }).catch((error) => {
          this.promocode = this.promocodeOld;
          this.newItem.discount = '';
          this.newItem.cost_of_service = this.newItem.tariff_price;
          if(error.response.status == 422){
            this.validateErrors = error.response.data.errors;
            this.$message.error(
              'Проверьте правильность данных',
              3);
          }else if(error.response.status == 400){
              this.$message.error(
                error.response.data.message,
                3);
                this.validateErrors = {code: ["Не действительный промокод"]};
          }else{
            this.$message.error(
              'Ошибка сервера',
              3);
          }
          this.changeTariffPrice();
        }).finally(() => {this.load = false});
      },
      checkPromoCodeEditFunction(){
        this.load = true;
        axios.post('/employee/orders/check-promocode', this.oldItem).then((response) => {
          if(response.status == 200){
            this.promocode = response.data;
            this.validateErrors = [];

            if(this.promocode.type == 'absolute_discount'){
              this.oldItem.discount = this.promocode.value;
              this.oldItem.cost_of_service = this.oldItem.tariff_price - this.oldItem.discount;
            }else if(this.promocode.type == 'percentage_discount'){
              this.oldItem.discount = (this.promocode.value/100) * this.oldItem.tariff_price;
              this.oldItem.cost_of_service = this.oldItem.tariff_price - this.oldItem.discount;
            }else if(this.promocode.type == 'free_order'){
              this.oldItem.discount  = this.oldItem.tariff_price;
              this.oldItem.cost_of_service = this.oldItem.tariff_price - this.oldItem.discount;
            }
            if(this.oldItem.vat == 'vat_15'){
              this.oldItem.cost_of_service_with_vat = Math.round(this.oldItem.cost_of_service*1.15);
              this.oldItem.cost_of_service_vat = Math.round(this.oldItem.cost_of_service*0.15);
            }else if(this.oldItem.vat == 'vat_12'){
              this.oldItem.cost_of_service_with_vat = Math.round(this.oldItem.cost_of_service*1.12);
              this.oldItem.cost_of_service_vat = Math.round(this.oldItem.cost_of_service*0.12);
            }else{
              this.oldItem.cost_of_service_with_vat = this.oldItem.cost_of_service;
              this.oldItem.cost_of_service_vat = this.oldItem.cost_of_service*0;
            }
          }
        }).catch((error) => {
          this.promocode = this.promocodeOld;
          this.oldItem.discount = '';
          this.oldItem.cost_of_service = this.oldItem.tariff_price;
          if(error.response.status == 422){
            this.validateErrors = error.response.data.errors;
            this.$message.error(
              'Проверьте правильность данных',
              3);
          }else if(error.response.status == 400){
              this.$message.error(
                error.response.data.message,
                3);
                this.validateErrors = {code: ["Не действительный промокод"]};
          }else{
            this.$message.error(
              'Ошибка сервера',
              3);
          }
          if(this.oldItem.vat == 'vat_15'){
            this.oldItem.cost_of_service_with_vat = Math.round(this.oldItem.cost_of_service*1.15);
            this.oldItem.cost_of_service_vat = Math.round(this.oldItem.cost_of_service*0.15);
          }else if(this.oldItem.vat == 'vat_12'){
            this.oldItem.cost_of_service_with_vat = Math.round(this.oldItem.cost_of_service*1.12);
            this.oldItem.cost_of_service_vat = Math.round(this.oldItem.cost_of_service*0.12);
          }else{
            this.oldItem.cost_of_service_with_vat = this.oldItem.cost_of_service;
            this.oldItem.cost_of_service_vat = this.oldItem.cost_of_service*0;
          }
        }).finally(() => {this.load = false});
      },

      getCashBack(){
        this.load = true;
        axios.get('/employee/orders/get-cashback/'+this.newItem.telegram_chat_id).then((response) => {
          if(response.status == 200){
            console.log(response.data);
            this.newItem.cashback_balans = response.data.cashback;
            if(this.newItem.cost_of_service_with_vat/2 > this.newItem.cashback_balans){
              this.newItem.allowed_cashback_balans = this.newItem.cashback_balans;
            }else{
              this.newItem.allowed_cashback_balans = this.newItem.cost_of_service_with_vat/2;
            }
          }
        }).catch((error) => {
          if(error.response.status == 404){
            this.$message.error(
              "Аккаунт не найден!",
              3
            );
          }else{
            this.$message.error(
              "Ошибка сервера!",
              3
            );
          }
        }).finally(() => {this.load = false})
      },

      getInvoice(value){
        axios.post('/employee/orders/get/invoices', {order_id: [value]}).then((response) => {
          if(response.status == 200){
            window.open(response.data.url, '_blank');
          }
        }).catch((error) => {
          if(error.response.status == 422){
            this.$message.error(
              'Проверьте правильность ID заказов',
              3);
          }else{
            this.$message.error(
              'Ошибка сервера',
              3);
          }
        });
      },
      getInvoices(){
        axios.post('/employee/orders/get/invoices', {order_id: this.orderIdForExport}).then((response) => {
          if(response.status == 200){
            window.open(response.data.url, '_blank');
          }
        }).catch((error) => {
          if(error.response.status == 422){
            this.$message.error(
              'Проверьте правильность ID заказов',
              3);
          }else{
            this.$message.error(
              'Ошибка сервера',
              3);
          }
        });
      },
      getManifests(){
        axios.post('/employee/orders/get/manifest', {order_id: this.orderIdForExport}).then((response) => {
          if(response.status == 200){
            window.open(response.data.url, '_blank');
          }
        }).catch((error) => {
          if(error.response.status == 422){
            this.$message.error(
              'Проверьте правильность ID заказов',
              3);
          }else{
            this.$message.error(
              'Ошибка сервера',
              3);
          }
        });
      },

      getProviders(){
        axios.get('/employee/providers').then((response) => {
          if(response.status == 200){
            this.providers = response.data.data;
          }else if(response.status == 204){
            this.$message.error(
              "Поставщиков не найдено",
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              "Ошибка сервера",
              3
            );
          }
        })
      },

      getAddresses(){
        axios.get('/employee/clients/addresses').then((response) => {
          if(response.status == 200){
            this.addressesForSender = response.data;
            this.addressesForRecipient = response.data;
          }else if(response.status == 204){
            this.$message.error(
              "Адресов не найдено",
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              "Ошибка сервера",
              3
            );
          }
        })
      },
      searchSenderAddresses(value){
        if(value.length > 2){
          axios.get('/employee/clients/addresses/search', {params: {query: value}}).then((response) => {
            if(response.status == 200){
              this.addressesForSender = response.data;
            }
          }).catch((error) => {
            if(error.response.status == 404){
              this.$message.error(
                "Нет соответствующих адресов",
                3
              );
            }else if(error.response.status == 500){
              this.$message.error(
                "Ошибка сервера",
                3
              );
            }
          });
        }
      },
      changeSenderAddresses(value){
        if(value){
          this.$message.success(
            "Успешно применено",
            3
          );
          axios.post('/employee/clients/addresses/updated-used/'+value).then((response) => {}).catch((error) => {});

          var result = this.addressesForSender.find(address => address.id === value);

          this.senderCountries[0] = result.country;
          this.senderRegions[0] = result.region;
          this.senderCities[0] = result.city;

          this.newItem.sender_name = result.name;
          this.newItem.sender_country = result.country_id;
          this.newItem.sender_region = result.region_id;
          this.newItem.sender_city = result.city_id;
          this.newItem.sender_address = result.address;
          this.newItem.sender_type_of_building = result.type_of_building;
          this.newItem.sender_reference_point = result.reference_point;
          this.newItem.sender_phone = result.phone;
          this.newItem.sender_phone_1 = result.phone_1;
          this.newItem.sender_phone_2 = result.phone_2;
          this.newItem.sender_email = result.email;
          this.newItem.sender_point = result.points;
        }
      },
      searchRecipientAddresses(value){
        if(value.length > 2){
          axios.get('/employee/clients/addresses/search', {params: {query: value}}).then((response) => {
            if(response.status == 200){
              this.addressesForRecipient = response.data;
            }
          }).catch((error) => {
            if(error.response.status == 404){
              this.$message.error(
                "Нет соответствующих адресов",
                3
              );
            }else if(error.response.status == 500){
              this.$message.error(
                "Ошибка сервера",
                3
              );
            }
          });
        }
      },
      changeRecipientAddresses(value){
        if(value){
          this.$message.success(
            "Успешно применено",
            3
          );
          axios.post('/employee/clients/addresses/updated-used/'+value).then((response) => {}).catch((error) => {});

          var result = this.addressesForRecipient.find(address => address.id === value);


          this.recipientCountries[0] = result.country;
          this.recipientRegions[0] = result.region;
          this.recipientCities[0] = result.city;

          this.newItem.recipient_name = result.name;
          this.newItem.recipient_country = result.country_id;
          this.newItem.recipient_region = result.region_id;
          this.newItem.recipient_city = result.city_id;
          this.newItem.recipient_address = result.address;
          this.newItem.recipient_type_of_building = result.type_of_building;
          this.newItem.recipient_reference_point = result.reference_point;
          this.newItem.recipient_phone = result.phone;
          this.newItem.recipient_phone_1 = result.phone_1;
          this.newItem.recipient_phone_2 = result.phone_2;
          this.newItem.recipient_email = result.email;
          this.newItem.recipient_point = result.points;
        }
      },
      addToAddressBookSender(value){
        this.load = true;
        this.newAddressBook = this.oldAddressBook;

        this.newAddressBook.user_id = this.newItem.client_id;
        this.newAddressBook.name = this.newItem.sender_name;
        this.newAddressBook.country_id = this.newItem.sender_country;
        this.newAddressBook.region_id = this.newItem.sender_region;
        this.newAddressBook.city_id = this.newItem.sender_city;
        this.newAddressBook.address = this.newItem.sender_address;
        this.newAddressBook.type_of_building = this.newItem.sender_type_of_building;
        this.newAddressBook.reference_point = this.newItem.sender_reference_point;
        this.newAddressBook.phone = this.newItem.sender_phone;
        this.newAddressBook.phone_1 = this.newItem.sender_phone_1;
        this.newAddressBook.phone_2 = this.newItem.sender_phone_2;
        this.newAddressBook.email = this.newItem.sender_email;
        this.newAddressBook.points = this.newItem.sender_point;

        axios.post('/clients/addresses', this.newAddressBook).then((response) => {
          if(response.status == 201){
            this.$message.success(
              "Успешно добавлено",
              3
            );
            this.validateErrors = [];
          }
        }).catch((error) => {
          if(error.response.status == 422){
            this.$message.error(
              error.response.data.message,
              3
            );

            this.validateErrors.client_id = error.response.data.errors.user_id;
            this.validateErrors.sender_name = error.response.data.errors.name;
            this.validateErrors.sender_country = error.response.data.errors.country_id;
            this.validateErrors.sender_region = error.response.data.errors.region_id;
            this.validateErrors.sender_city = error.response.data.errors.city_id;
            this.validateErrors.sender_address = error.response.data.errors.address;
            this.validateErrors.sender_type_of_building = error.response.data.errors.type_of_building;
            this.validateErrors.sender_reference_point = error.response.data.errors.reference_point;
            this.validateErrors.sender_phone = error.response.data.errors.phone;
            this.validateErrors.sender_phone_1 = error.response.data.errors.phone_1;
            this.validateErrors.sender_phone_2 = error.response.data.errors.phone_2;
            this.validateErrors.sender_email = error.response.data.errors.email;
            this.validateErrors.sender_point = error.response.data.errors.points;

          }else if(error.response.status == 500){
            this.$message.error(
              "Ошибка сервера",
              3
            );
          }
        }).finally(() => {this.load = false;})
      },
      addToAddressBookRecipient(value){
          this.load = true;
          this.newAddressBook = this.oldAddressBook;

          this.newAddressBook.user_id = this.newItem.client_id;
          this.newAddressBook.name = this.newItem.recipient_name;
          this.newAddressBook.country_id = this.newItem.recipient_country;
          this.newAddressBook.region_id = this.newItem.recipient_region;
          this.newAddressBook.city_id = this.newItem.recipient_city;
          this.newAddressBook.address = this.newItem.recipient_address;
          this.newAddressBook.type_of_building = this.newItem.recipient_type_of_building;
          this.newAddressBook.reference_point = this.newItem.recipient_reference_point;
          this.newAddressBook.phone = this.newItem.recipient_phone;
          this.newAddressBook.phone_1 = this.newItem.recipient_phone_1;
          this.newAddressBook.phone_2 = this.newItem.recipient_phone_2;
          this.newAddressBook.email = this.newItem.recipient_email;
          this.newAddressBook.points = this.newItem.recipient_point;

          axios.post('/clients/addresses', this.newAddressBook).then((response) => {
            if(response.status == 201){
              this.$message.success(
                "Успешно добавлено",
                3
              );
              this.validateErrors = [];
            }
          }).catch((error) => {
            if(error.response.status == 422){
              this.$message.error(
                error.response.data.message,
                3
              );

              this.validateErrors.client_id = error.response.data.errors.user_id;
              this.validateErrors.recipient_name = error.response.data.errors.name;
              this.validateErrors.recipient_country = error.response.data.errors.country_id;
              this.validateErrors.recipient_region = error.response.data.errors.region_id;
              this.validateErrors.recipient_city = error.response.data.errors.city_id;
              this.validateErrors.recipient_address = error.response.data.errors.address;
              this.validateErrors.recipient_type_of_building = error.response.data.errors.type_of_building;
              this.validateErrors.recipient_reference_point = error.response.data.errors.reference_point;
              this.validateErrors.recipient_phone = error.response.data.errors.phone;
              this.validateErrors.recipient_phone_1 = error.response.data.errors.phone_1;
              this.validateErrors.recipient_phone_2 = error.response.data.errors.phone_2;
              this.validateErrors.recipient_email = error.response.data.errors.email;
              this.validateErrors.recipient_point = error.response.data.errors.points;

            }else if(error.response.status == 500){
              this.$message.error(
                "Ошибка сервера",
                3
              );
            }
        }).finally(() => {this.load = false;});
      },
      onSelectSenderAddressByPhone(value){
        let arrayData = value.split(' | ');
        let phoneId = arrayData[arrayData.length - 1];
        let result = this.dataSourceSenderAddress.find((el) => el.id==phoneId);

        if(result){
          this.$message.success(
            "Успешно применено",
            3
          );
          axios.post('/employee/clients/addresses/updated-used/'+result.id).then((response) => {}).catch((error) => {});

          this.senderCountries[this.senderRegions.length] = result.country;
          this.senderRegions[this.senderRegions.length] = result.region;
          this.senderCities[this.senderCities.length] = result.city;

          this.newItem.sender_name = result.name;
          this.newItem.sender_country = result.country_id;
          this.newItem.sender_region = result.region_id;
          this.newItem.sender_city = result.city_id;
          this.newItem.sender_address = result.address;
          this.newItem.sender_type_of_building = result.type_of_building;
          this.newItem.sender_reference_point = result.reference_point;
          this.newItem.sender_phone = result.phone;
          this.newItem.sender_phone_1 = result.phone_1;
          this.newItem.sender_phone_2 = result.phone_2;
          this.newItem.sender_email = result.email;
          this.newItem.sender_point = result.points;
        }

      },
      onSearchSenderAddressByPhone(value){
        if(value.length > 2){
          axios.get('/employee/clients/addresses/search-by-phone', {params: {query: value}}).then((response) => {
            if(response.status == 200){
              this.phoneSourceSenderAddress = [];
              this.dataSourceSenderAddress = response.data;
              for(let i=0; i<this.dataSourceSenderAddress.length; i++){
                this.phoneSourceSenderAddress.push(this.dataSourceSenderAddress[i].phone + ' | ' + this.dataSourceSenderAddress[i].name + ' | ' + this.dataSourceSenderAddress[i].address + ' | ' + this.dataSourceSenderAddress[i].id);
              }

            }
          }).catch((error) => {
            if(error.response.status == 500){
              this.$message.error(
                "Ошибка сервера",
                3
              );
            }
          });
        }
      },
      onSelectRecipientAddressByPhone(value){
        let arrayData = value.split(' | ');
        let phoneId = arrayData[arrayData.length - 1];
        let result = this.dataSourceRecipientAddress.find((el) => el.id==phoneId);

        if(result){
          this.$message.success(
            "Успешно применено",
            3
          );
          axios.post('/employee/clients/addresses/updated-used/'+result.id).then((response) => {}).catch((error) => {});

          this.recipientCountries[this.recipientRegions.length] = result.country;
          this.recipientRegions[this.recipientRegions.length] = result.region;
          this.recipientCities[this.recipientCities.length] = result.city;

          this.newItem.recipient_name = result.name;
          this.newItem.recipient_country = result.country_id;
          this.newItem.recipient_region = result.region_id;
          this.newItem.recipient_city = result.city_id;
          this.newItem.recipient_address = result.address;
          this.newItem.recipient_type_of_building = result.type_of_building;
          this.newItem.recipient_reference_point = result.reference_point;
          this.newItem.recipient_phone = result.phone;
          this.newItem.recipient_phone_1 = result.phone_1;
          this.newItem.recipient_phone_2 = result.phone_2;
          this.newItem.recipient_email = result.email;
          this.newItem.recipient_point = result.points;
        }

      },
      onSearchRecipientAddressByPhone(value){
        if(value.length > 2){
          axios.get('/employee/clients/addresses/search-by-phone', {params: {query: value}}).then((response) => {
            if(response.status == 200){
              this.phoneSourceRecipientAddress = [];
              this.dataSourceRecipientAddress = response.data;
              for(let i=0; i<this.dataSourceRecipientAddress.length; i++){
                this.phoneSourceRecipientAddress.push(this.dataSourceRecipientAddress[i].phone + ' | ' + this.dataSourceRecipientAddress[i].name + ' | ' + this.dataSourceRecipientAddress[i].address + ' | ' + this.dataSourceRecipientAddress[i].id);
              }

            }
          }).catch((error) => {
            if(error.response.status == 500){
              this.$message.error(
                "Ошибка сервера",
                3
              );
            }
          });
        }
      },

      storeItem(){
        this.load = true;
        axios.post('/employee/orders', this.newItem).then((response) => {
          let arrayData = [];
          let oldItemos = {
            type: 'international',
            creator_id: '',

            client_id: '',
            branchoffice_from: JSON.parse(localStorage.me).branchoffice_id,
            branchoffice_to: '',
            driver_id: '',
            status_id: '',
            service_id: 1,
            link_id: '',

            type_service_id: '',
            who_create: 'employee',

            sender_name: '',
            sender_country: '',
            sender_region: '',
            sender_city: '',
            sender_address: '',
            sender_type_of_building: 'commercial',
            sender_reference_point: '',
            sender_phone: '',
            sender_phone_1: '',
            sender_phone_2: '',
            sender_email: '',
            sender_point: '',

            recipient_name: '',
            recipient_country: '',
            recipient_region: '',
            recipient_city: '',
            recipient_address: '',
            recipient_type_of_building: 'commercial',
            recipient_reference_point: '',
            recipient_phone: '',
            recipient_phone_1: '',
            recipient_phone_2: '',
            recipient_email: '',
            recipient_point: '',

            width: 1,
            length: 1,
            height: 1,
            unit_id: 1,
            weight: 0,
            volume_weight: 0.0002,

            tariff_id: '',
            tariff_price:'',
            vat: 'vat_12',
            is_fragile: '',

            payment_method: '',
            who_pays: '',
            recipient_is_not_available: '',

            cod: '',
            cod_status: '',
            code: '',
            discount: '',
            cost_of_service: '',
            cost_of_service_with_vat:'',
            cost_of_service_vat:'',
            status_of_payment: '',
            cost_of_things: '',
            count_of_parts: '',
            notes: '',
            title: '',
            cargo: [],

            images: [],
          };
          if(response.status == 201){
            this.visibleCreateModal = false;
            this.validateErrors = [];
            arrayData[0] = response.data;
            this.data = arrayData.concat(this.data);
            this.newItem = oldItemos;
            this.typeService = 'usual';
            this.filterService();
            this.senderA = '';
            this.recipientA = '';
            this.$message.success(
              'Успешно создано',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 422){
            this.$message.error(
              'Проверьте правильность данных',
              3
            );
            this.validateErrors = error.response.data.errors;
          }else if(error.response.status == 500){
            this.$message.error(
              error.response.data.message,
              3
            );
          }else if(error.response.status == 400){
            this.$message.error(
              error.response.data.message,
              3);
              this.validateErrors = {code: ["Не действительный промокод"]};
          }else if(error.response.status == 403){
            this.$message.error(
              error.response.data.message,
              3);
              this.validateErrors = {cashback: ["Не действительный кешбек"]};
          }
        }).finally(() => {
          this.load = false;
        });
      },
      storeItemWithoutClear(){
        this.load = true;
        axios.post('/employee/orders', this.newItem).then((response) => {
          let arrayData = [];
          if(response.status == 201){
            this.visibleCreateModal = false;
            this.validateErrors = [];
            arrayData[0] = response.data;
            this.data = arrayData.concat(this.data);

            this.newItem.recipient_name = '',
            this.newItem.recipient_country = '',
            this.newItem.recipient_region = '',
            this.newItem.recipient_city = '',
            this.newItem.recipient_address = '',
            this.newItem.recipient_type_of_building = 'commercial',
            this.newItem.recipient_reference_point = '',
            this.newItem.recipient_phone = '',
            this.newItem.recipient_phone_1 = '',
            this.newItem.recipient_phone_2 = '',
            this.newItem.recipient_email = '',
            this.newItem.recipient_point = '',

            this.senderA = '';
            this.recipientA = '';
            this.$message.success(
              'Успешно создано',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 422){
            this.$message.error(
              'Проверьте правильность данных',
              3
            );
            this.validateErrors = error.response.data.errors;
          }else if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }else if(error.response.status == 400){
            this.$message.error(
              error.response.data.message,
              3);
              this.validateErrors = {code: ["Не действительный промокод"]};
          }
        }).finally(() => {
          this.load = false;
        });
      },
      updateItem(){
        this.load = true;
        axios.put('/employee/orders/' + this.oldItem.id, this.oldItem).then((response) => {
          let arrayData = [];
          if(response.status == 200){
            this.visibleEditModal = false;
            this.validateErrors = [];
            this.data[this.ind] =  response.data;
            this.item =  response.data;

            this.senderA = '';
            this.recipientA = '';
            this.$message.success(
              'Успешно сохранено',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 422){
            this.$message.error(
              'Проверьте правильность данных',
              3
            );
            this.validateErrors = error.response.data.errors;
          }else if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }else if(error.response.status == 400){
            this.$message.error(
              error.response.data.message,
              3);
              this.validateErrors = {code: ["Не действительный промокод"]};
          }else if(error.response.status == 404){
            this.$message.error(
              error.response.data.error,
              3);
          }
        }).finally(() => {
          this.load = false;
        });
      },
      destroyItem(index, item){

      },
      statusUpdate(){
        this.load = true;
        this.validateErrors = [];
        axios.post('/employee/orders/status/update', this.itemStatus).then((response) => {
          if(response.status == 200){
            this.$message.success(
              response.data.success,
              3
            );
            this.visibleEditStatus = false;

            this.itemStatus.order_id = [];
            this.itemStatus.service_id = '';
            this.itemStatus.status_id = '';
            this.itemStatus.branchoffice_id = '';
            this.itemStatus.driver_id = '';
            this.itemStatus.delivery_date = ref<Moment>(moment('12-12-2000', dateFormat));
          }
        }).catch((error) => {
          if(error.response.status == 422){
            this.validateErrors = error.response.data.errors;
          }
          this.$message.error(
            error.response.data.message,
            3);
        }).finally(() => {this.load = false;});
      },

      applyFilter(){
        this.load = true;
        this.dataFilter.page = 1;
        this.old_height = 0;
        this.getOrders();
        this.getCount();
      },
      applyFullFilter(){
        this.dataFilter.fullFilter = true;
        this.load = true;
        this.dataFilter.page = 1;
        this.old_height = 0;
        this.getOrders();
        this.getCount();

      },
      getCount(){
        axios.get('/employee/orders/get/count', {params: this.dataFilter}).then((response) => {
          if(response.status == 200){
            this.count = response.data;
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }else{
            console.log(error);
          }
        })
      },

      exportToExcell(){
        let dataForExport = {
          dataFilter: this.dataFilter,
          orderDataForExport: this.orderDataForExport
        };
        this.load = true;
        this.orderDataForExport.id = this.orderIdForExport;
        axios.post('/employee/orders/export-to-excell', dataForExport, {headers: {'Access-Control-Allow-Origin': '*','Content-Disposition': 'form-data',}}).then((response) => {
          if(response.status == 200){
            var fileURL = window.URL.createObjectURL(new Blob(["\ufeff", response.data]));
            var fileLink = document.createElement('a');
            var date = new Date();
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'Report_' + date +'.csv');
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        }).catch((error) => {
          if(error.response.status == 422){
            this.$message.error(
              'Пожалуйста выберите ID заказов',
              3
            );
          }
        }).finally(() => {this.load = false});
      },

      selectAll() {
        let selected = [];
        if(!this.selectedAll){
          this.data.forEach((item) => {
            selected.push(item.id);
          });
        }

        this.orderIdForExport = selected;
      },
      selectSingle(){
        if(this.orderIdForExport.length === this.data.length){
          this.selectedAll = true;
        }else{
          this.selectedAll = false;
        }
      },

      changeStatus(value){
        this.item = value;
        this.validateErrors = [];
        this.visibleEditStatus = true;
        this.itemStatus.service_id = value.service_id;
        this.itemStatus.order_id = [value.id];
        this.itemStatus.driver_id = value.driver_id;
        this.itemStatus.branchoffice_id = value.branchoffice_id;
        window.setTimeout(()=>{this.itemStatus.status_id = value.status_id;}, 200);
      },
      createItem(){
        this.visibleCreateModal = true;
      },
      showItem(value, index){
        this.ind = index;
        this.fileListOrder = [];
        this.confirmListOrder = [];
        this.item = value;
        if(this.item.images){
          if(this.item.images.length){
            let myFileList = [];
            for(let i=0; i<this.item.images.length; i++){
              let leyt = {
                uid: '',
                name: '',
                status: '',
                url: ''
              };
              leyt.uid = this.item.images[i].id;
              leyt.name = 'image.png';
              leyt.status = 'done';
              leyt.url = this.$store.state.urlBackend + '/' + this.item.images[i].file;
              myFileList.push(leyt);
            }
            this.fileListOrder = myFileList;
          }
        }
        if(this.item.confirm_delivery_images){
          if(this.item.confirm_delivery_images.length){
            let myFileList = [];
            for(let i=0; i<this.item.confirm_delivery_images.length; i++){
              let leyt = {
                uid: '',
                name: '',
                status: '',
                url: ''
              };
              leyt.uid = this.item.confirm_delivery_images[i].id;
              leyt.name = 'image.png';
              leyt.status = 'done';
              leyt.url = this.$store.state.urlBackend + '/' + this.item.confirm_delivery_images[i].file;
              myFileList.push(leyt);
            }
            this.confirmListOrder = myFileList;
          }
        }

        this.visibleShowModal = true;
      },
      editItem(){
        this.oldItem.id = this.item.id;
        this.oldItem.type = this.item.type;
        this.oldItem.creator_id = this.item.creator_id;
        this.oldItem.client_id = this.item.client_id;
        this.oldItem.branchoffice_from = this.item.branchoffice_from;
        this.oldItem.branchoffice_id = this.item.branchoffice_id;
        this.oldItem.branchoffice_to = this.item.branchoffice_to;
        this.oldItem.driver_id = this.item.driver_id;
        this.oldItem.status_id = this.item.status_id;
        this.oldItem.service_id = this.item.service_id;
        this.oldItem.type_service_id = this.item.type_service_id;
        this.oldItem.link_id = this.item.link_id;
        this.oldItem.sender_name = this.item.sender_name;
        this.oldItem.sender_country = this.item.sender_country ? this.item.sender_country.id : '';
        this.oldItem.sender_region = this.item.sender_region ? this.item.sender_region.id : '';
        this.oldItem.sender_city = this.item.sender_city ? this.item.sender_city.id : '';
        this.oldItem.sender_address = this.item.sender_address;
        this.oldItem.sender_type_of_building = this.item.sender_type_of_building;
        this.oldItem.sender_reference_point = this.item.sender_reference_point;
        this.oldItem.sender_phone = this.item.sender_phone;
        this.oldItem.sender_phone_1 = this.item.sender_phone_1;
        this.oldItem.sender_phone_2 = this.item.sender_phone_2;
        this.oldItem.sender_email = this.item.sender_email;
        this.oldItem.sender_point = this.item.sender_point;
        this.oldItem.recipient_name = this.item.recipient_name;
        this.oldItem.recipient_country = this.item.recipient_country ? this.item.recipient_country.id : '';
        this.oldItem.recipient_region = this.item.recipient_region ? this.item.recipient_region.id : '';
        this.oldItem.recipient_city = this.item.recipient_city ? this.item.recipient_city.id : '';
        this.oldItem.recipient_address = this.item.recipient_address;
        this.oldItem.recipient_type_of_building = this.item.recipient_type_of_building;
        this.oldItem.recipient_reference_point = this.item.recipient_reference_point;
        this.oldItem.recipient_phone = this.item.recipient_phone;
        this.oldItem.recipient_phone_1 = this.item.recipient_phone_1;
        this.oldItem.recipient_phone_2 = this.item.recipient_phone_2;
        this.oldItem.recipient_email = this.item.recipient_email;
        this.oldItem.recipient_point = this.item.recipient_point;
        this.oldItem.width = this.item.width;
        this.oldItem.length = this.item.length;
        this.oldItem.height = this.item.height;
        this.oldItem.unit_id = this.item.unit_id;
        this.oldItem.weight = this.item.weight;
        this.oldItem.volume_weight = this.item.volume_weight;
        this.oldItem.tariff_id = this.item.tariff_id;
        this.oldItem.tariff_price = this.item.tariff_price;
        this.oldItem.vat = this.item.vat;
        this.oldItem.is_fragile = this.item.is_fragile;
        this.oldItem.payment_method = this.item.payment_method;
        this.oldItem.who_pays = this.item.who_pays;
        this.oldItem.recipient_is_not_available = this.item.recipient_is_not_available;
        this.oldItem.cod = this.item.cod;
        this.oldItem.cod_status = this.item.cod_status;
        this.oldItem.code = this.item.code;
        this.oldItem.discount = this.item.discount;
        this.oldItem.provider_id = this.item.provider_id;
        this.oldItem.provider_price = this.item.provider_price;
        this.oldItem.provider_phone = this.item.provider_phone;
        this.oldItem.cse_phone = this.item.cse_phone;
        this.oldItem.tracking_code = this.item.tracking_code;
        this.oldItem.cost_of_service = this.item.cost_of_service;
        this.oldItem.cost_of_service_vat = this.item.cost_of_service_vat;
        this.oldItem.cost_of_service_with_vat = this.item.cost_of_service_with_vat;
        this.oldItem.status_of_payment = this.item.status_of_payment;
        this.oldItem.cost_of_things = this.item.cost_of_things;
        this.oldItem.count_of_parts = this.item.count_of_parts;
        this.oldItem.notes = this.item.notes;
        this.oldItem.cargo = this.item.cargo;
        this.oldItem.title = this.item.title;


        if(this.oldItem.service_id == 1 || this.oldItem.service_id == 2){
          this.typeService = 'usual';
        }else{
          this.typeService = 'boomerang';
        }
        this.changeOrderTitleInEdit(this.oldItem.title);
        this.filterServiceInEdit();

        this.senderCountries[0] = this.item.sender_country;
        this.senderRegions[0] = this.item.sender_region;
        this.senderCities[0] = this.item.sender_city;

        this.recipientCountries[0] = this.item.recipient_country;
        this.recipientRegions[0] = this.item.recipient_region;
        this.recipientCities[0] = this.item.recipient_city;

        this.visibleEditModal = true;
        console.log(this.item.tariff_price);
        console.log(this.item.vat);
      },
      deleteItem(index, item){

      },

      selectBranchOffice(value){
        let array = [];
        for(let i=0; i<this.oldDrivers.length; i++){
          if(this.oldDrivers[i].branchoffice_id == value){
            array.push(this.oldDrivers[i]);
          }
        }
        this.drivers = array;
      },

      searchSenderCountries(value){
        axios.get('/employee/countries/search', {params: {query: value}}).then((response) =>  {
          if(response.status == 200){
            this.senderCountries = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Нет соответствующих стран',
              3
            );
          }
        }).catch((error)  =>  {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }
        }).finally(() => {

        });
      },
      changeSenderCountries(value){
        axios.get('/employee/regions-with-cities/'+value).then((response) => {
          if(response.status == 200){
            this.senderRegions = response.data.regions;
            this.senderCities = response.data.cities;
            this.dataFilterSender.country = value;
            this.dataForMapSender.country = response.data.country.title;
            if(response.data.country.title){
              axios.get('/employee/get/google/map-lat-long', {params: this.dataForMapSender}).then((response) =>  {
                if(response.status == 200){
                  this.senderLocation.lat = response.data.results[0].geometry.location.lat;
                  this.senderLocation.lng = response.data.results[0].geometry.location.lng;

                  this.senderCenter.lat = response.data.results[0].geometry.location.lat;
                  this.senderCenter.lng = response.data.results[0].geometry.location.lng;

                }
              }).catch((error)  =>  {

              })
            }
          }
        }).catch((error) => {

        });
      },
      searchSenderRegions(value){
        axios.get('/employee/regions/search', {params: {query: value, country: this.dataFilterSender.country}}).then((response) => {
          if(response.status == 200){
            this.senderRegions = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Нет соответствующих областей',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }
        })
      },
      changeSenderRegions(value){
        axios.get('/employee/cities-by-region/' + value).then((response) => {
          if(response.status == 200){
            this.senderCities = response.data.cities;
            this.dataFilterSender.region = value;
            this.dataForMapSender.region = response.data.region.title;
            if(response.data.region){
              axios.get('/employee/get/google/map-lat-long', {params: this.dataForMapSender}).then((response) =>  {
                if(response.status == 200){
                  this.senderLocation.lat = response.data.results[0].geometry.location.lat;
                  this.senderLocation.lng = response.data.results[0].geometry.location.lng;

                  this.senderCenter.lat = response.data.results[0].geometry.location.lat;
                  this.senderCenter.lng = response.data.results[0].geometry.location.lng;
                }
              }).catch((error)  =>  {

              })
            }
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }
        });
      },
      searchSenderCities(value){
        axios.get('/employee/cities/search', {params: {query: value, country: this.dataFilterSender.country, region: this.dataFilterSender.region}}).then((response) => {
          if(response.status == 200){
            this.senderCities = response.data;

          }else if(response.status == 204){
            this.$message.error(
              'Нет соответствующих районов',
              3
            );
          }
        }).catch((error)  =>  {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            )
          }
        })
      },
      changeSenderCities(value){
        for(let i=0; i<this.senderCities.length; i++){
          if(this.senderCities[i].id == value){
            this.dataForMapSender.city = this.senderCities[i].title;
          }
        }
        if(this.dataForMapSender.city){
          axios.get('/employee/get/google/map-lat-long', {params: this.dataForMapSender}).then((response) =>  {
            if(response.status == 200){
              this.senderLocation.lat = response.data.results[0].geometry.location.lat;
              this.senderLocation.lng = response.data.results[0].geometry.location.lng;

              this.senderCenter.lat = response.data.results[0].geometry.location.lat;
              this.senderCenter.lng = response.data.results[0].geometry.location.lng;
            }
          }).catch((error)  =>  {

          })
        }
      },

      searchRecipientCountries(value){
        axios.get('/employee/countries/search', {params: {query: value}}).then((response) =>  {
          if(response.status == 200){
            this.recipientCountries = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Нет соответствующих стран',
              3
            );
          }
        }).catch((error)  =>  {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }
        }).finally(() => {

        });
      },
      changeRecipientCountries(value){
        axios.get('/employee/regions-with-cities/'+value).then((response) => {
          if(response.status == 200){
            this.recipientRegions = response.data.regions;
            this.recipientCities = response.data.cities;
            this.dataFilterRecipient.country = value;
            this.dataForMapRecipient.country = response.data.country.title;
            if(response.data.country.title){
              axios.get('/employee/get/google/map-lat-long', {params: this.dataForMapRecipient}).then((response) =>  {
                if(response.status == 200){
                  this.recipientLocation.lat = response.data.results[0].geometry.location.lat;
                  this.recipientLocation.lng = response.data.results[0].geometry.location.lng;

                  this.recipientCenter.lat = response.data.results[0].geometry.location.lat;
                  this.recipientCenter.lng = response.data.results[0].geometry.location.lng;

                }
              }).catch((error)  =>  {

              })
            }
          }
        }).catch((error) => {

        });
      },
      searchRecipientRegions(value){
        axios.get('/employee/regions/search', {params: {query: value, country: this.dataFilterRecipient.country}}).then((response) => {
          if(response.status == 200){
            this.recipientRegions = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Нет соответствующих областей',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }
        })
      },
      changeRecipientRegions(value){
        axios.get('/employee/cities-by-region/' + value).then((response) => {
          if(response.status == 200){
            this.recipientCities = response.data.cities;
            this.dataFilterRecipient.region = value;
            this.dataForMapRecipient.region = response.data.region.title;
            if(response.data.region){
              axios.get('/employee/get/google/map-lat-long', {params: this.dataForMapRecipient}).then((response) =>  {
                if(response.status == 200){
                  this.recipientLocation.lat = response.data.results[0].geometry.location.lat;
                  this.recipientLocation.lng = response.data.results[0].geometry.location.lng;

                  this.recipientCenter.lat = response.data.results[0].geometry.location.lat;
                  this.recipientCenter.lng = response.data.results[0].geometry.location.lng;
                }
              }).catch((error)  =>  {

              })
            }
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }
        });
      },
      searchRecipientCities(value){
        axios.get('/employee/cities/search', {params: {query: value, country: this.dataFilterRecipient.country, region: this.dataFilterRecipient.region}}).then((response) => {
          if(response.status == 200){
            this.recipientCities = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Нет соответствующих районов',
              3
            );
          }
        }).catch((error)  =>  {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            )
          }
        })
      },
      changeRecipientCities(value){
        for(let i=0; i<this.recipientCities.length; i++){
          if(this.recipientCities[i].id == value){
            this.dataForMapRecipient.city = this.recipientCities[i].title;
          }
        }
        if(this.dataForMapRecipient.city){
          axios.get('/employee/get/google/map-lat-long', {params: this.dataForMapRecipient}).then((response) =>  {
            if(response.status == 200){
              this.recipientLocation.lat = response.data.results[0].geometry.location.lat;
              this.recipientLocation.lng = response.data.results[0].geometry.location.lng;

              this.recipientCenter.lat = response.data.results[0].geometry.location.lat;
              this.recipientCenter.lng = response.data.results[0].geometry.location.lng;
            }
          }).catch((error)  =>  {

          })
        }
      },

      getAllDrivers(){
        axios.get('/employee/get/users/drivers').then((response) => {
          if(response.status == 200){
            this.allDrivers = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Водителей не найдено',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }else if(error.response.status == 400){
            this.$message.error(
              error.response.data.error,
              3
            );
          }
        }).finally(() => {

        });
      },
      getManagers(){
        axios.get('/employee/get/users/managers').then((response) => {
          if(response.status == 200){
            this.managers = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Офис менеджеры не найдены',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }else if(error.response.status == 400){
            this.$message.error(
              error.response.data.error,
              3
            );
          }
        }).finally(() => {

        });
      },

      getClients(){
        axios.get('/employee/clients').then(response => {
          if(response.status == 200){
            this.clients = response.data
          }else{
            this.$message.error(
              'Клиенты не найдено',
              3
            );
          }
        }).catch(error => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }else{
            this.$message.error(
              'Клиенты не найдено',
              3
            );
          }
        }).finally(() => {
          this.load = false;
        });
      },
      getBranches(){
        axios.get('/employee/branches').then(response => {
          if(response.status == 200){
            this.branches = response.data
          }else{
            this.$message.error(
              'Филиалов не найдено',
              3
            );
          }
        }).catch(error => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }else{
            this.$message.error(
              'Филиалов не найдено',
              3
            );
          }
        });
      },
      getDrivers(){
        axios.get('/employee/users/driver').then((response) => {
          if(response.status == 200){
            this.drivers = response.data;
            this.oldDrivers = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Водителей не найдено',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }else if(error.response.status == 400){
            this.$message.error(
              error.response.data.error,
              3
            );
          }
        }).finally(() => {

        });
      },
      getStatuses(){
        axios.get('/employee/statuses/get').then((response) => {
          if(response.status == 200){
            this.statuses = response.data;
            this.allStatuses = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Статусов не найдено',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }
        }).finally(() => {

        });
      },
      getTypeOfService(){
        axios.get('/employee/type-of-service/get').then((response) => {
          if(response.status == 200){
            this.typeOfServices = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Типы услуг не найдено',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }
        }).finally(() => {

        });
      },
      getUnits(){
        axios.get('/typeofcargo/get/units').then(response => {
          if(response.status == 200){
            this.units = response.data;
          }else if(response.status == 204){
            this.$message.error(
                  'Единица измерения не найдены',
                  5,
            );
          }
        }).catch(error => {
          this.$message.error(
                'Единица измерения не найдены',
                5,
          );
        });
      },
      getAllTariffs(){
        axios.get('/employee/tariffs/all').then((response) => {
          if(response.status == 200){
          this.tariffs = response.data.filter((el) => el.type==="international");
          this.allTariffs = response.data.filter((el) => el.type==="international");
          }else if(response.status == 204){
            this.$message.error(
              'Тарифы не найдено',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }
        })
      },
      getOrders(){
        axios.get('/employee/orders', {params: this.dataFilter}).then((response) => {
          if(response.status == 200){
            this.data = response.data.data;
            this.totalResults = response.data.total;
            this.last_page = response.data.last_page;
            if(this.visibleFilterOrders){
              this.visibleFilterOrders = false;
              this.$message.success(
                'Успешно применено',
                3
              );
            }
          }else if(response.status == 204){
            this.data = [];
            this.$message.error(
              'Местных заказов не найдено',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }
        }).finally(() => {
          this.load = false
        });
      },
      addOrdersWithPaginate(){
        this.load = true;
        axios.get('/employee/orders', {params: this.dataFilter}).then((response) => {
          if(response.status == 200){
            this.data = this.data.concat(response.data.data);
            this.totalResults = response.data.total;
            this.last_page = response.data.last_page;
          }else if(response.status == 204){
            this.data = [];
            this.totalResults = 0;
            this.last_page = 0;
            this.$message.error(
              'Местных заказов не найдено',
              3
            );
          }
        }).catch((error) => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }
        }).finally(() => {
          this.load = false
        });
      },

      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },

      changeTariff(){
        let actualWeight = 0;
        if(this.newItem.weight > this.newItem.volume_weight){
          actualWeight = this.newItem.weight;
        }else{
          actualWeight = this.newItem.volume_weight;
        }
        let tariff = this.tariffs.find((el) => el.id===this.newItem.tariff_id);
        if(tariff.price_type == 'linear'){
          console.log('linear');
          let prices = [];
          this.tariffPrice = tariff.prices;
          for(let i=0; i<this.tariffPrice.length; i++){
            if(this.selectedType.fuel_surcharge){
              console.log(this.selectedType.fuel_surcharge);
              let persent = Number(1+(this.selectedType.fuel_surcharge/100));
              this.tariffPrice[i].price = Math.ceil(persent*this.tariffPrice[i].basic_price);
            }else{
              this.tariffPrice[i].price = this.tariffPrice[i].basic_price;
            }
          }
          if(Number(tariff.prices[0].included_weight) < actualWeight){
            if(this.selectedType.fuel_surcharge){
              console.log(this.selectedType.fuel_surcharge);
              let persent = Number(1+(this.selectedType.fuel_surcharge/100));
              this.tariffPrice[0].price = Math.ceil(persent*(Number(tariff.prices[0].basic_price) + (Math.ceil((actualWeight - Number(tariff.prices[0].included_weight))/Number(tariff.prices[0].weight))) * Number(tariff.prices[0].price_per_weight)));
            }else{
              this.tariffPrice[0].price = Number(tariff.prices[0].basic_price) + (Math.ceil((actualWeight - Number(tariff.prices[0].included_weight))/Number(tariff.prices[0].weight))) * Number(tariff.prices[0].price_per_weight);
            }
          }
        }else if(tariff.price_type == 'unlinear'){
          console.log('unlinear');
          let prices = [];
          this.tariffPrice = tariff.unlinear_price;
          this.tariffPrice = tariff.unlinear_price.filter((el) => Number(el.weight_from) <= actualWeight && Number(el.weight_to) >= actualWeight && Number(el.price_per_weight) == 0);
          for(let i=0; i<this.tariffPrice.length; i++){
            if(this.selectedType.fuel_surcharge){
              console.log(this.selectedType.fuel_surcharge);
              let persent = Number(1+(this.selectedType.fuel_surcharge/100));
              this.tariffPrice[i].price = Math.ceil(persent*this.tariffPrice[i].basic_price);
            }else{
              this.tariffPrice[i].price = this.tariffPrice[i].basic_price;
            }
          }
          prices = tariff.unlinear_price.filter((el) => Number(el.weight_from) <= actualWeight && Number(el.weight_to) >= actualWeight && Number(el.price_per_weight) == 0);
          if(!prices.length){
            this.tariffPrice = tariff.unlinear_price.filter((el) => Number(el.weight_from) <= actualWeight && Number(el.weight_to) >= actualWeight && Number(el.weight) > 0);
            prices = tariff.unlinear_price.filter((el) => Number(el.weight_from) <= actualWeight && Number(el.weight_to) >= actualWeight && Number(el.weight) > 0);
            for(let i=0; i<prices.length; i++){
              console
              if(this.selectedType.fuel_surcharge){
                console.log(this.selectedType.fuel_surcharge);
                let persent = Number(1+(this.selectedType.fuel_surcharge/100));
                this.tariffPrice[i].price = Math.ceil(persent*(Number(prices[i].basic_price) + (Math.ceil((actualWeight - Number(prices[i].weight_from))/Number(prices[i].weight))) * Number(prices[i].price_per_weight)));
              }else{
                console.log('net');
                this.tariffPrice[i].price = Number(prices[i].basic_price) + (Math.ceil((actualWeight - Number(prices[i].weight_from))/Number(prices[i].weight))) * Number(prices[i].price_per_weight);
              }
            }
          }
          this.tariffPrice;
        }
        console.log(this.tariffPrice);
        this.newItem.tariff_price = '';
        this.newItem.code = '';
        this.newItem.discount = '';
        this.newItem.cost_of_service = '';
        this.newItem.cost_of_service_with_vat = '';
        this.newItem.cost_of_service_vat = '';
        this.promocode = this.promocodeOld;
      },

      filterTariffs(){
        this.tariffs = this.allTariffs;
        if(this.orderCargo){
          this.tariffs = this.tariffs.filter((el) => el.type_of_cargo_id === this.orderCargo.id || el.type_of_cargo_id === null);
        }
        if(this.newItem.type_service_id){
          this.tariffs = this.tariffs.filter((el) => el.type_service_id === this.newItem.type_service_id);
        }
        if(this.newItem.client_id){
          let client = this.clients.find((el) => el.id===this.newItem.client_id)
          this.tariffs = this.tariffs.filter((el) => el.client_type === client.type || el.client_type === null);
        }
        if(this.newItem.branchoffice_from){
          this.tariffs = this.tariffs.filter((el) => el.branchoffice_id===this.newItem.branchoffice_from || el.branchoffice_id===null);
        }

        let tariffsOnFilter = [];
        for(let i=0; i<this.tariffs.length; i++){
          if(this.tariffs[i].rules.length){
            if(this.parseTariff(this.tariffs[i].rules[0], this.tariffs[i].rules[0].bool_and_or)){
              tariffsOnFilter.push(this.tariffs[i]);
            }
          }else{
            tariffsOnFilter.push(this.tariffs[i]);
          }
        }
        this.tariffs = tariffsOnFilter;
      },
      parseTariff(tariff, boolAndOr){
        if(tariff.conditions.length){
          let arr = [];
          for(let i=0; i<tariff.conditions.length; i++){
            if(tariff.conditions[i].country_from && this.newItem.sender_country){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].country_from == this.newItem.sender_country){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].country_from != this.newItem.sender_country){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].country_to && this.newItem.recipient_country){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].country_to == this.newItem.recipient_country){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].country_to != this.newItem.recipient_country){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].region_from && this.newItem.sender_region){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].region_from == this.newItem.sender_region){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].region_from != this.newItem.sender_region){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].region_to && this.newItem.recipient_region){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].region_to == this.newItem.recipient_region){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].region_to != this.newItem.recipient_region){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].city_from && this.newItem.sender_city){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].city_from == this.newItem.sender_city){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].city_from != this.newItem.sender_city){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].city_to && this.newItem.recipient_city){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].city_to == this.newItem.recipient_city){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].city_to != this.newItem.recipient_city){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].branchoffice_from && this.newItem.branchoffice_from){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].branchoffice_from == this.newItem.branchoffice_from){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].branchoffice_from != this.newItem.branchoffice_from){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].branchoffice_to && this.newItem.branchoffice_to){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].branchoffice_to == this.newItem.branchoffice_to){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].branchoffice_to != this.newItem.branchoffice_to){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].client && this.newItem.client_id){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].client == this.newItem.client_id){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].client != this.newItem.client_id){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else{
              arr[i] = true;
            }
          }
          if(!arr.length){arr[0]=true;}
          let boolValue = arr[0];
          if(boolAndOr==='or'){
            if(arr.length>1){
              for(let i=1; i<arr.length; i++){
                boolValue += arr[i];
              }
            }
          }else if(boolAndOr==='and'){
            if(arr.length>1){
              for(let i=1; i<arr.length; i++){
                boolValue *= arr[i];
              }
            }
          }
          return boolValue;
        }else{
          if(tariff.rules.length){
            let law = [];
            for(let i=0; i<tariff.rules.length; i++){
               law[i] = this.parseTariff(tariff.rules[i], tariff.rules[i].bool_and_or);
            }
            if(!law.length){law[0]=true}
            let boolValue = law[0];
            if(boolAndOr==='or'){
              if(law.length>1){
                for(let i=1; i<law.length; i++){
                  boolValue += law[i];
                }
              }
            }else if(boolAndOr==='and'){
              if(law.length>1){
                for(let i=1; i<law.length; i++){
                  boolValue *= law[i];
                }
              }
            }
            return boolValue;
          }
        }
      },

      changeTariffInEdit(){
        let actualWeight = 0;
        if(this.oldItem.weight > this.oldItem.volume_weight){
          actualWeight = this.oldItem.weight;
        }else{
          actualWeight = this.oldItem.volume_weight;
        }
        let tariff = this.tariffs.find((el) => el.id===this.oldItem.tariff_id);
        if(tariff.price_type == 'linear'){
          let prices = [];
          this.tariffPrice = tariff.prices;
          for(let i=0; i<this.tariffPrice.length; i++){
            if(this.selectedType.fuel_surcharge){
              console.log(this.selectedType.fuel_surcharge);
              let persent = Number(1+(this.selectedType.fuel_surcharge/100));
              this.tariffPrice[i].price = Math.ceil(persent*this.tariffPrice[i].basic_price);
            }else{
              this.tariffPrice[i].price = this.tariffPrice[i].basic_price;
            }
          }
          if(Number(tariff.prices[0].included_weight) < actualWeight){
            if(this.selectedType.fuel_surcharge){
              console.log(this.selectedType.fuel_surcharge);
              let persent = Number(1+(this.selectedType.fuel_surcharge/100));
              this.tariffPrice[0].price = Math.ceil(persent*(Number(tariff.prices[0].basic_price) + (Math.ceil((actualWeight - Number(tariff.prices[0].included_weight))/Number(tariff.prices[0].weight))) * Number(tariff.prices[0].price_per_weight)));
            }else{
              this.tariffPrice[0].price = Number(tariff.prices[0].basic_price) + (Math.ceil((actualWeight - Number(tariff.prices[0].included_weight))/Number(tariff.prices[0].weight))) * Number(tariff.prices[0].price_per_weight);
            }
          }
        }else if(tariff.price_type == 'unlinear'){
          let prices = [];
          this.tariffPrice = tariff.unlinear_price;
          this.tariffPrice = tariff.unlinear_price.filter((el) => Number(el.weight_from) <= actualWeight && Number(el.weight_to) >= actualWeight && Number(el.price_per_weight) == 0);
          for(let i=0; i<this.tariffPrice.length; i++){
            if(this.selectedType.fuel_surcharge){
              console.log(this.selectedType.fuel_surcharge);
              let persent = Number(1+(this.selectedType.fuel_surcharge/100));
              this.tariffPrice[i].price = Math.ceil(persent*this.tariffPrice[i].basic_price);
            }else{
              this.tariffPrice[i].price = this.tariffPrice[i].basic_price
            }
          }
          prices = tariff.unlinear_price.filter((el) => Number(el.weight_from) <= actualWeight && Number(el.weight_to) >= actualWeight && Number(el.price_per_weight) == 0);
          if(!prices.length){
            this.tariffPrice = tariff.unlinear_price.filter((el) => Number(el.weight_from) <= actualWeight && Number(el.weight_to) >= actualWeight && Number(el.weight) > 0);
            prices = tariff.unlinear_price.filter((el) => Number(el.weight_from) <= actualWeight && Number(el.weight_to) >= actualWeight && Number(el.weight) > 0);
            for(let i=0; i<prices.length; i++){
              if(this.selectedType.fuel_surcharge){
                console.log(this.selectedType.fuel_surcharge);
                let persent = Number(1+(this.selectedType.fuel_surcharge/100));
                this.tariffPrice[i].price = Math.ceil(persent*(Number(prices[i].basic_price) + (Math.ceil((actualWeight - Number(prices[i].weight_from))/Number(prices[i].weight))) * Number(prices[i].price_per_weight)));
              }else{
                this.tariffPrice[i].price = Number(prices[i].basic_price) + (Math.ceil((actualWeight - Number(prices[i].weight_from))/Number(prices[i].weight))) * Number(prices[i].price_per_weight);
              }

            }
          }
          this.tariffPrice;
        }
        // this.oldItem.tariff_price = '';
        // this.oldItem.cost_of_service_vat = '';
        // this.oldItem.cost_of_service_with_vat = '';
        // this.oldItem.code = '';
        // this.oldItem.discount = '';
        // this.oldItem.cost_of_service = '';
        // this.promocode = this.promocodeOld;
      },

      filterTariffsInEdit(){
        this.tariffs = this.allTariffs;
        if(this.orderCargo){
          this.tariffs = this.tariffs.filter((el) => el.type_of_cargo_id === this.orderCargo.id || el.type_of_cargo_id === null);
        }
        if(this.oldItem.type_service_id){
          this.tariffs = this.tariffs.filter((el) => el.type_service_id === this.oldItem.type_service_id);
        }

        if(this.oldItem.client_id){
          let client = this.clients.find((el) => el.id===this.oldItem.client_id)
          this.tariffs = this.tariffs.filter((el) => el.client_type === client.type || el.client_type === null);
        }
        if(this.oldItem.branchoffice_from){
          this.tariffs = this.tariffs.filter((el) => el.branchoffice_id===this.oldItem.branchoffice_from || el.branchoffice_id===null);
        }

        let tariffsOnFilter = [];
        for(let i=0; i<this.tariffs.length; i++){
          if(this.tariffs[i].rules.length){
            if(this.parseTariffInEdit(this.tariffs[i].rules[0], this.tariffs[i].rules[0].bool_and_or)){
              tariffsOnFilter.push(this.tariffs[i]);
            }
          }else{
            tariffsOnFilter.push(this.tariffs[i]);
          }
        }
        this.tariffs = tariffsOnFilter;
      },
      parseTariffInEdit(tariff, boolAndOr){
        if(tariff.conditions.length){
          let arr = [];
          for(let i=0; i<tariff.conditions.length; i++){
            if(tariff.conditions[i].country_from && this.oldItem.sender_country){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].country_from == this.oldItem.sender_country){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].country_from != this.oldItem.sender_country){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].country_to && this.oldItem.recipient_country){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].country_to == this.oldItem.recipient_country){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].country_to != this.oldItem.recipient_country){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].region_from && this.oldItem.sender_region){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].region_from == this.oldItem.sender_region){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].region_from != this.oldItem.sender_region){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].region_to && this.oldItem.recipient_region){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].region_to == this.oldItem.recipient_region){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].region_to != this.oldItem.recipient_region){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].city_from && this.oldItem.sender_city){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].city_from == this.oldItem.sender_city){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].city_from != this.oldItem.sender_city){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].city_to && this.oldItem.recipient_city){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].city_to == this.oldItem.recipient_city){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].city_to != this.oldItem.recipient_city){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].branchoffice_from && this.oldItem.branchoffice_from){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].branchoffice_from == this.oldItem.branchoffice_from){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].branchoffice_from != this.oldItem.branchoffice_from){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].branchoffice_to && this.oldItem.branchoffice_to){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].branchoffice_to == this.oldItem.branchoffice_to){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].branchoffice_to != this.oldItem.branchoffice_to){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else if(tariff.conditions[i].client && this.oldItem.client_id){
              if(tariff.conditions[i].value == 'equally'){
                if(tariff.conditions[i].client == this.oldItem.client_id){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }else if(tariff.conditions[i].value == 'unequally'){
                if(tariff.conditions[i].client != this.newItem.client_id){
                  arr[i] = true;
                }else{
                  arr[i] = false;
                }
              }
            }else{
              arr[i] = true;
            }
          }
          if(!arr.length){arr[0]=true;}
          let boolValue = arr[0];
          if(boolAndOr==='or'){
            if(arr.length>1){
              for(let i=1; i<arr.length; i++){
                boolValue += arr[i];
              }
            }
          }else if(boolAndOr==='and'){
            if(arr.length>1){
              for(let i=1; i<arr.length; i++){
                boolValue *= arr[i];
              }
            }
          }
          return boolValue;
        }else{
          if(tariff.rules.length){
            let law = [];
            for(let i=0; i<tariff.rules.length; i++){
               law[i] = this.parseTariffInEdit(tariff.rules[i], tariff.rules[i].bool_and_or);
            }
            if(!law.length){law[0]=true}
            let boolValue = law[0];
            if(boolAndOr==='or'){
              if(law.length>1){
                for(let i=1; i<law.length; i++){
                  boolValue += law[i];
                }
              }
            }else if(boolAndOr==='and'){
              if(law.length>1){
                for(let i=1; i<law.length; i++){
                  boolValue *= law[i];
                }
              }
            }
            return boolValue;
          }
        }
      },

      addSenderMarker(event) {
         this.senderLocation.lat = event.latLng.lat();
         this.senderLocation.lng = event.latLng.lng();
      },
      addRecipientMarker(event) {
         this.recipientLocation.lat = event.latLng.lat();
         this.recipientLocation.lng = event.latLng.lng();

      },
      drawingRoad(){
        let sender = this.item.sender_point.split(",");
        let recipient = this.item.recipient_point.split(",");

        this.snappedPoints[0].lat = Number(sender[0]);
        this.snappedPoints[0].lng = Number(sender[1]);
        this.snappedPoints[1].lat = Number(recipient[0]);
        this.snappedPoints[1].lng = Number(recipient[1]);

        this.visibleMap = true;
      },

      handleCancel() {
        this.previewVisible = false;
      },
      handlePreview(file) {
        if (!file.url && !file.preview) {
          file.preview =  getBase64(file.originFileObj);
        }
        this.previewImage = this.$store.state.urlBackend + '/' + file.response.file;
        this.previewVisible = true;
      },
      handleChange({fileList}) {
        let arr = [];
        this.fileList = fileList;
        for(let i=0; i<fileList.length; i++){
          if(fileList[i].response){
            arr.push(fileList[i].response.id)
          }
        }
        this.newItem.images = arr;
      },
      deleteImage(file){
        axios.delete('/employee/orders/image/' + file.response.id).then((response) => {
          if(response.status == 200){
            this.$message.success(
              'Успешно удалено',
              3
            );
          }
        }).catch((error) => {
          console.log(error);
        });
      },

      handleCancelOrder() {
        this.previewVisibleOrder = false;
      },
      handlePreviewOrder(file) {
        if (file.url) {
          this.previewImageOrder = file.url;
          this.previewVisibleOrder = true;
        }else if(!file.url && !file.preview){
          // file.preview =  getBase64(file.originFileObj);
          this.previewImageOrder = this.$store.state.urlBackend + '/' + file.response.file;
          this.previewVisibleOrder = true;
        }
      },
      handleChangeSignature({ fileList }) {
        if(!JSON.parse(localStorage.me).delete_signature){
          this.$message.error(
              'У вас нет доступа',
              3
            );
        }else{
          this.confirmListOrder = fileList;
        }
      },
      removeSignature(file) {
        if(!JSON.parse(localStorage.me).delete_signature){
          this.$message.error(
              'У вас нет доступа',
              3
            );
        }else{
          let id = 0;
          if(file.response){
            id = file.response.id
          }else{
            id = file.uid;
          }
          console.log(id)
          axios.delete('/employee/orders/image-confirm/' + id).then((response) => {
            if(response.status == 200){
              this.$message.success(
                'Успешно удалено',
                3
              );
            }
          }).catch((error) => {
            consoe.log(error);
          });
        }
      },
      handleChangeOrder({fileList}) {
        this.fileListOrder = fileList;
      },
      handleChangeOrderConfirm({fileList}) {
        this.confirmListOrder = fileList;
      },
      deleteImageOrder(file){
        let id = 0;
        if(file.response){
          id = file.response.id
        }else{
          id = file.uid;
        }
        axios.delete('/employee/orders/image/' + id).then((response) => {
          if(response.status == 200){
            this.$message.success(
              'Успешно удалено',
              3
            );
          }
        }).catch((error) => {
          console.log(error);
        });
      },

      clientName(name){
        // let matches = name.match(/\b(\w)/g);
        // let acronym = matches.join('');
        let username = name.split(' ').map(function(item){return item[0]}).join('');
        if(username[1]){
          return username[0]+username[1];
        }else if(username[0]){
          return username[0];
        }
        return 'EE';
      },
      clientColor(name){
        name = name.toUpperCase();
        let char = name.charCodeAt(0);

        let color = '#4CAF50';
        if(char > 64 && char < 77){
          color = '#6EC9CB';
        }else if(char > 76 && char < 91){
          color = '#FAA774';
        }else if(char > 1039 && char < 1051){
          color = '#A695E7';
        }else if(char > 1050 && char < 1060){
          color = '#4CAF50';
        }else if(char > 1059 && char < 1072){
          color = '#E17076';
        }

        return color;
      },
      createdAt(date){
        let d = new Date(date).toLocaleDateString();
        return moment(date).format('DD/MM/YYYY HH:mm');
      },

      expiredAt(date){
        let created_at = new Date(date);
        let now = new Date();
        var timeDiff = Math.abs(now.getTime() - created_at.getTime());
        var diffDays = timeDiff / 1000;
        if(diffDays < 3600){
          return  {"type": "minute", "value": Math.round(diffDays / 60)};
        }else if(diffDays>3600 && diffDays<86400){
          return  {"type": "hour", "value": Math.round(diffDays / 3600)};
        }else{
          return  {"type": "day", "value": Math.round(diffDays / 86400)};
        }
        // return diffDays;
      }

    },
    computed: {
      me () {
        return JSON.parse(localStorage.me)
      },
    },
    watch: {
      "visibleMap": function(value){
        if(value){
          setTimeout(() => {
            this.driving = "DRIVING";
          }, 1000);
        }else{
          this.driving = "WALKING";
        }
      },
      "senderLocation.lng": function(value){
        let point = this.senderLocation.lat + ','+ this.senderLocation.lng;
        this.newItem.sender_point = point;
      },
      "recipientLocation.lng": function(value){
        let point = this.recipientLocation.lat + ','+ this.recipientLocation.lng;
        this.newItem.recipient_point = point;
      },
      "newItem.sender_point": function(value){
        if(value){
          let points = value.split(',');
          this.senderLocation.lat = Number(points[0]);
          this.senderLocation.lng = Number(points[1]);
        }else{
          this.senderLocation.lat = '';
          this.senderLocation.lng = '';
        }

        // this.senderCenter.lat = Number(points[0]);
        // this.senderCenter.lng = Number(points[1]);
      },
      "newItem.recipient_point": function(value){
        if(value){
          let points = value.split(',');
          this.recipientLocation.lat = Number(points[0]);
          this.recipientLocation.lng = Number(points[1]);
        }else{
          this.recipientLocation.lat = '';
          this.recipientLocation.lng = '';

        }

        // this.recipientCenter.lat = Number(points[0]);
        // this.recipientCenter.lng = Number(points[1]);
      },
      "newItem.service_id": function(){
        this.newItem.status_id = '';
      },
      "itemStatus.service_id": function(){
        this.itemStatus.status_id = '';
      },

      "newItem.client_id": function(value){
        this.filterTariffs();
      },
      "newItem.branchoffice_from": function(value){
        this.filterTariffs();
      },
      "newItem.branchoffice_to": function(value){
        this.filterTariffs();
      },
      "newItem.sender_country": function(value){
        this.filterTariffs();
      },
      "newItem.sender_region": function(value){
        this.filterTariffs();
      },
      "newItem.sender_city": function(value){
        this.filterTariffs();
      },
      "newItem.recipient_country": function(value){
        this.filterTariffs();
      },
      "newItem.recipient_region": function(value){
        this.filterTariffs();
      },
      "newItem.recipient_city": function(value){
        this.filterTariffs();
      },

      "newItem.width": function(){
        this.newItem.volume_weight = this.newItem.width*this.newItem.height*this.newItem.length / this.$store.state.volumeWeight;
      },
      "newItem.length": function(){
        this.newItem.volume_weight = this.newItem.width*this.newItem.height*this.newItem.length / this.$store.state.volumeWeight;
      },
      "newItem.height": function(){
        this.newItem.volume_weight = this.newItem.width*this.newItem.height*this.newItem.length / this.$store.state.volumeWeight;
      },
      "newItem.weight": function(){
        this.changeTariff();
      },
      "newItem.volume_weight": function(){
        this.changeTariff();
      },

      "newItem.cost_of_service_with_vat": function(){
        if(this.newItem.cost_of_service_with_vat/2 > this.newItem.cashback_balans){
          this.newItem.allowed_cashback_balans = this.newItem.cashback_balans;
        }else{
          this.newItem.allowed_cashback_balans = this.newItem.cost_of_service_with_vat/2;
        }
      },

      // Old Item
      "oldItem.client_id": function(value){
        this.filterTariffsInEdit();
      },
      "oldItem.branchoffice_from": function(value){
        this.filterTariffsInEdit();
      },
      "oldItem.branchoffice_to": function(value){
        this.filterTariffsInEdit();
      },
      "oldItem.sender_country": function(value){
        this.filterTariffsInEdit();
      },
      "oldItem.sender_region": function(value){
        this.filterTariffsInEdit();
      },
      "oldItem.sender_city": function(value){
        this.filterTariffsInEdit();
      },
      "oldItem.recipient_country": function(value){
        this.filterTariffsInEdit();
      },
      "oldItem.recipient_region": function(value){
        this.filterTariffsInEdit();
      },
      "oldItem.recipient_city": function(value){
        this.filterTariffsInEdit();
      },

      "oldItem.width": function(){
        this.oldItem.volume_weight = this.oldItem.width*this.oldItem.height*this.oldItem.length / this.$store.state.volumeWeight;
      },
      "oldItem.length": function(){
        this.oldItem.volume_weight = this.oldItem.width*this.oldItem.height*this.oldItem.length / this.$store.state.volumeWeight;
      },
      "oldItem.height": function(){
        this.oldItem.volume_weight = this.oldItem.width*this.oldItem.height*this.oldItem.length / this.$store.state.volumeWeight;
      },
      "oldItem.weight": function(){
        this.changeTariffInEdit();
      },
      "oldItem.volume_weight": function(){
        this.changeTariffInEdit();
      },

      "dataFilter.id": function(){
        if(this.dataFilter.id.length){
          this.dataFilter.search = '';
        }
      },
      "dataFilter.search": function(){
        if(this.dataFilter.search){
          this.dataFilter.id = [];
        }
      }

    }
  }
</script>

<style>
  .ant-calendar-picker-container{
    z-index: 2030 !important;
  }
  .table-with-scroll{
      overflow-x: auto;
      max-width: 100%;
      color: #000
  }
  .order-create .ant-modal{
    width: 1100px !important;
    height: 100%;
    top: 10px;
    color: rgba(10, 10, 10, 1);
  }
  .order-create .ant-modal-body{
    background: #efefef;
    padding: 10px 80px;
    height: 100%;
    overflow: auto;
  }
  .order-create .block-shadow{
    padding: 20px 10px 30px 10px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 1px 1px 6px 1px #999;

  }
  .order-status .ant-modal{
    width: 900px !important;
    top: 20px;
  }
  .map__modal .ant-modal{
    width: 1000px !important;
    height: 500px !important;
    top: 10px;
  }
  .lat-long-block{
    position: absolute;
    top: 78px;
    right: 80px;
    background: #fff;
    padding: 10px;
    width: 400px;
  }
  .img-preview .ant-modal{
    width: 900px !important;
  }

  .export-to-excell .ant-modal{
    width: 1100px !important
  }
  .c-main{
    padding: 0;
  }
  .c-main > .container-fluid{
    padding: 0;
  }
  .about-client{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: center;
    white-space: pre;
    color: #000;
  }
  .client-image{
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 5px;
  }
  .client-image img{
    width: 100% !important;
    height: 100% !important;
  }
  .client-name-letter{
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #eee;
    text-transform: uppercase;
    font-size:  16px;
    font-weight: bold;
    background: #4CAF50;
    margin-right: 5px;
  }
  .delivery-address{
    width: 200px;
  }
  .delivery-address-1{
    font-weight: bold;
    font-size: 13px;
  }
  .delivery-address-2{
    font-size: 12px;
  }
  .delivery-address-3{
    font-weight: bold;
    font-size: 13px;
    width: 190px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .client-name-letter.for-tariff{
    background: #efefef;
    border: 1px solid #aeaeae;
  }
  .client-name-letter .tariff{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    background: #2196F3;
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
  .client-name-letter .payment_method {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    background: #3EDC2F;
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
  .status{
    color: #222;
    padding: 4px 18px;
    border-radius: 25px 0;
  }
  .prices{
    white-space: nowrap
  }
  .order-id{
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
  }
  .order-info .ant-modal{
    width: 1300px !important;
    padding: 0;
    top: 10px;
  }
  .order-info .ant-modal-body{
    padding: 0;
  }
  .order-info .ant-modal-close-x{
    display: none;
  }
  .header-order-info{
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
  }
  .icon-of-header{
    width: 40px !important;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fff;
  }
  .bold{
    font-weight: bold;
  }
  .order-details{
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    margin: 5px 5px 5px 5px;
    transition: all .3s ease;
  }
  .order-details.active{
    color: #fff;
  }
  .order-details:hover{
      cursor: pointer;
      color: #fff;
  }
  .box-shadow{
    background: #fff;
    box-shadow: 1px 1px 5px 1px #a9a9a9;
  }
  .order-info-content{
    width: 100%;
  }
  .order-info-content-item{
    width: 49%;
  }


  .order-menu .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
    padding: 10px;
    font-size: 16px;
  }
  .status-for-filter{
    cursor: pointer;
    background: #eee;
    padding: 5px 10px;
    border-radius: 4px;
  }
  .status-for-filter span{
    color: #BD7221;
    font-weight: bold;
  }
  .status-for-filter.active{
    background: #54B1BD;
  }
  /*! CSS Used from: Embedded */
  ::selection{background:#b3d4fc;text-shadow:none;}
  img,svg{vertical-align:middle;}
  @media print{
  *,:after,:before,:first-letter{background:transparent!important;color:#000!important;box-shadow:none!important;}
  a,a:visited{text-decoration:underline;}
  a[href]:after{content:" (" attr(href) ")";}
  img{page-break-inside:avoid;}
  img{max-width:100%!important;}
  }
  a{-webkit-tap-highlight-color:transparent;-webkit-tap-highlight-color:rgba(255, 255, 255, 0);}

  /*! CSS Used from: Embedded */
  .root-0-53{display:flex;box-sizing:border-box;}
  .wrap-false-0-63{flex-wrap:nowrap;}
  .align-center-0-65{align-items:center;}
  .justify-center-0-68{justify-content:center;}
  .justify-space-between-0-70{justify-content:space-between;}
  .flex-true-0-83{flex:1 1 0%;}
  .direction-row-0-90{min-width:0;flex-direction:row;}
  .direction-column-0-92{min-height:0;flex-direction:column;}
  /*! CSS Used from: Embedded */
  .success-0-376{color:#7cb342!important;}
  .danger-0-381{color:#f44336!important;}
  /*! CSS Used from: Embedded */
  .root-0-991{margin:6px!important;}
  .title-0-992{font-size:12px!important;}
  .text-0-993{text-transform:uppercase!important;}
  .textBlock-0-995{padding-top:4px!important;line-height:15px!important;}
  /*! CSS Used from: Embedded */
  .leftAvatar-0-1516{margin-left:0;margin-right:12px;}
  /*! CSS Used from: Embedded */
  .leftAvatar-0-1529{margin-left:0;margin-right:12px;}
  /*! CSS Used from: Embedded */
  .leftAvatar-0-1534{margin-left:0;margin-right:12px;}
  /*! CSS Used from: Embedded */
  .leftAvatar-0-1539{margin-left:0;margin-right:12px;}
  /*! CSS Used from: Embedded */
  .leftAvatar-0-1544{margin-left:0;margin-right:12px;}
  /*! CSS Used from: Embedded */
  .leftAvatar-0-1549{margin-left:0;margin-right:12px;}
  /*! CSS Used from: Embedded */
  .leftAvatar-0-1554{margin-left:0;margin-right:12px;}
  /*! CSS Used from: Embedded */
  .leftAvatar-0-1559{margin-left:0;margin-right:12px;}
  /*! CSS Used from: Embedded */
  .leftAvatar-0-1574{margin-left:0;margin-right:12px;}
  /*! CSS Used from: Embedded */
  .leftAvatar-0-1579{margin-left:0;margin-right:12px;}
  /*! CSS Used from: Embedded */
  .contentCard-0-975{flex:1 1 0%!important;color:#575757!important;display:flex!important; }
  .contentCard-0-975 > div{flex:1 1 0%!important;padding:12px!important;overflow-y:auto!important;}
  .detailsCard-0-976{display:flex!important;flex-direction:row!important;}
  .contentCardText-0-977{flex:1 1 0%!important;display:flex!important;padding-top:5px!important;flex-direction:column!important;}
  .cardTextTitle-0-978{font-size:14px!important;text-transform:uppercase!important;}
  .cardTextName-0-979{font-size:18px!important;font-weight:bold!important;text-transform:uppercase!important;}
  .cardTextPhone-0-980{color:#a7a7a7!important;}
  /*! CSS Used from: Embedded */
  .contentCardText-0-1508{margin-left:12px;margin-right:0;}
  /*! CSS Used from: Embedded */
  .contentCardText-0-1521{margin-left:12px;margin-right:0;}
  /*! CSS Used from: Embedded */
  .container-0-951{flex:1 1 0%!important;display:flex!important;flex-direction:column!important;}
  .row-0-952{flex:1 1 0%!important;display:flex!important;flex-direction:row!important;}
  .row-0-952 > div{flex:1 1 0%!important;display:flex!important;}
  .colMargin-0-953{margin:6px!important;}
  .contentCard-0-954{flex:1 1 0%!important;color:#575757!important;display:flex!important;padding:12px!important;overflow-y:auto!important; flex-direction:column!important;}
  .link-0-955{cursor:pointer!important;}
  .currencySymbol-0-956{color:rgb(255, 255, 255)!important;width:40px!important;height:40px!important;display:inline-flex!important;font-size:20px!important;user-select:none!important;align-items:center!important;border-radius:50%!important;justify-content:center!important;background-color:rgb(233, 30, 99)!important;}
  .detailsTitle-0-957{display:block!important;text-align:center!important;font-weight:bold!important;text-transform:uppercase!important;}
  .paymentPrices-0-960{margin-top:12px!important;text-transform:uppercase!important;}
  .serviceType-0-961{color:#bbc200!important;}
  /*! CSS Used from: Embedded */
  *,*::before,*::after{box-sizing:border-box;}
  a{color:#9c0008;text-decoration:none;}
  a:hover{color:#2f3772;text-decoration:underline;}
  ::-ms-clear{display:none;}

  /*! CSS Used from: Embedded */
  ::selection{background:#b3d4fc;text-shadow:none;}
  @media print{
  *,:after,:before,:first-letter{background:transparent!important;color:#000!important;box-shadow:none!important;}
  }
  /*! CSS Used from: Embedded */
  .muted-0-374{color:#9e9e9e!important;}
  .primary-0-375{color:#9c0008!important;font-weight:500!important;}
  .success-0-376{color:#7cb342!important;}
  .danger-0-381{color:#f44336!important;}
  /*! CSS Used from: Embedded */
  .root-0-1067{display:block!important;}
  .title-0-1068{color:rgba(0, 0, 0, 0.54)!important;font-weight:500!important;}
  .content-0-1069{margin-left:24px!important;}
  /*! CSS Used from: Embedded */
  .container-0-1064{flex:1 1 0%!important;display:flex!important;flex-direction:column!important;}
  .row-0-1065{flex:1 1 0%!important;display:flex!important;flex-direction:row!important;}
  .row-0-1065 > div{flex:1 1 0%!important;margin:6px!important;display:flex!important;}
  /*! CSS Used from: Embedded */
  *,*::before,*::after{box-sizing:border-box;}
  ::-ms-clear{display:none;}
  .filter-orders .ant-modal{
    width: 1200px !important;
  }
  .photo-view .ant-modal{
    width: 600px !important;
  }
  .ant-select-dropdown{
    width: auto !important
  }
</style>
